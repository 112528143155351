<template>
  <div>
    <div v-show="loaded">
      <!-- <portal-target name="loading-screen" /> -->
      <div
        id="main-wrapper"
        data-theme="light"
        data-layout="vertical"
        data-navbarbg="skin6"
        data-sidebartype="full"
        data-sidebar-position="fixed"
        data-header-position="fixed"
        data-boxed-layout="full"
      >
        <top-nav></top-nav>
        <left-nav></left-nav>
        <div class="page-wrapper" id="mainWrapper">
          <!-- <BreadCrumb /> -->
          <slot name="BreadCrumb"></slot>
          <div class="container-fluid">
            <transition name="slide-in" mode="out-in">
              <router-view />
            </transition>
          </div>
        </div>
      </div>
      <portal-target name="modals" />
    </div>
    <div
      v-if="!loaded"
      class=" w-100 d-flex justify-content-center align-items-center flex-column"
      style="min-height:100vh"
    >
      <div class="spinner-border text-primary mb-3" role="status">
        <span class="sr-only">Loading...</span>
      </div>
      <h1>Preparando dados...</h1>
    </div>
  </div>
</template>

<script>
  import { mapGetters, mapActions } from "vuex";

  import BreadCrumb from "@/components/navigation/BreadCrumb";

  import LeftNav from "@/components/navigation/LeftNav";
  import TopNav from "@/components/navigation/TopNav";
  import TicketTable from "@/components/TicketTable";
  import Auth from "@/mixins/Auth.vue";

  export default {
    mixins: [Auth],

    components: {
      LeftNav,
      TopNav,
      TicketTable,
      BreadCrumb,
    },
    data() {
      return {
        loaded: false,
        loggedIn: false,
        fresh: !!localStorage.getItem("fresh"),
      };
    },
    computed: {
      ...mapGetters(["contatos", "selectedContacts"]),
    },
    created() {
      console.log("created");
    },
    watch: {},
    mounted() {
      setTimeout(() => {
        this.load();
      }, 5000);

      $(function() {
        $('[data-toggle="tooltip"]').tooltip();
      });
      // this.load();
      // let x = new Date(localStorage.getItem("tteTTLl"));
      // setInterval(() => {
      //   var hours = Math.abs(new Date(x).getTime() - new Date().getTime()) / 36e5;
      //   let d = new Date(x);
      //   let y = d.setHours(d.getHours() - hours);
      //   console.log("real time", new Date(y));
      // }, 5000);
    },
    methods: {
      ...mapActions([
        "LOAD_APPOINTMENT",
        "LOAD_BOTS",
        "LOAD_INFO",
        "LOAD_CONTACTS",
        "LOAD_USERS",
        "CHECK_USER",
        "LOAD_TICKETS",
        "LOAD_MESSAGES",
        "NEXT_PAGE",
        "NOTICE",
        "LOAD_OPEN_TICKETS",
      ]),
      async callApis() {
        await Promise.all([
          this.LOAD_CONTACTS(),
          this.LOAD_INFO(),
          this.LOAD_BOTS(),

          this.LOAD_USERS(),
          this.LOAD_OPEN_TICKETS(),
          this.NEXT_PAGE(1),
          this.LOAD_MESSAGES(),
          this.loadPage(),
        ]);
      },
      load() {
        this.CHECK_USER().then((r) => {
          if (r.status && r.status === 200) {
            this.callApis();
          }
        });
      },
      loadPage() {
        setTimeout(() => {
          this.loaded = true;
          this.loggedIn = true;
          // this.NOTICE().then(() => {
          //   this.$event.$emit("all_loaded");
          // });
          this.$event.$emit("all_loaded");
          this.$forceUpdate();
        }, 5000);
      },
    },
  };
</script>

<style lang="scss">
  @import url("//unpkg.com/element-ui@2.13.2/lib/theme-chalk/index.css");
  .el-header {
    background-color: #b3c0d1;
    color: #333;
    line-height: 60px;
  }

  .el-aside {
    color: #333;
  }
  .collapsed-wrapper {
    margin-left: unset !important;
  }
</style>
