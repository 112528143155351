<template>
  <div class="page-breadcrumb">
    <div class="row">
      <div class="col-7 align-self-center">
        <h3 class="page-title text-truncate text-dark font-weight-medium mb-1">
          {{ page }}
        </h3>
        <div class="d-flex align-items-center">
          <nav aria-label="breadcrumb">
            <ol class="breadcrumb m-0 p-0">
              <li class="breadcrumb-item">
                <!-- <a href="index.html">Dashboard</a> -->
              </li>
            </ol>
          </nav>
        </div>
      </div>
      <div class="col-5 align-self-center">
        <div class="customize-input float-right">
          <!-- <select
            class="custom-select custom-select-set form-control bg-white border-0 custom-shadow custom-radius"
          >
            <option selected="">Aug 19</option>
            <option value="1">July 19</option>
            <option value="2">Jun 19</option>
          </select> -->
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    name: "BreadCrumb",
    props: {
      page: {
        type: String,
      },
      hideOn: {
        type: Boolean,
        default: false,
      },
    },
    computed: {
      currentPage() {
        return this.$route.name;
      },
    },
    mounted() {},
    methods: {},
  };
</script>

<style></style>
