<script>
  import { mapGetters, mapActions } from "vuex";
  export default {
    data() {
      return {
        loading: false,
      };
    },
    mounted() {
      this.checkAuth();
    },
    computed: {
      ...mapGetters(["loggedUser"]),
    },
    methods: {
      ...mapActions(["CHECK_USER"]),
      async checkAuth() {
        try {
          this.CHECK_USER().then((res) => {
            if (res.status === 401) {
              localStorage.removeItem("token");
              localStorage.removeItem("T-AUTH");
              localStorage.removeItem("fresh");

              this.$router.push({ name: "index" });
            }
            if (res.status === 200) {
            }
            // setTimeout(() => {
            //   this.$toast.error(" ", "Sessão expirada", {
            //     icon: "icon-person",
            //     position: "topRight",
            //     timeout: 2000
            //   });
            // }, 2000);
          });
        } catch (error) {
          console.log("checkAuth mixin error", error);
        }
      },
    },
    beforeRouteUpdate(to, from, next) {
      console.log("1");
      this.checkAuth();
      next((vm) => {
        console.log("2");
      });
    },
  };
</script>
