import Vue from "vue";
import Vuex from "vuex";

// import axios from "axios";
import axios from "../../http";
import event from "../../helpers/eventBus";

Vue.use(Vuex);

export default {
  state: {
    contatos: [],
    contact: {},
    contact_from_book: {},
  },
  getters: {
    contatos: (state) => state.contatos,
    contacts: (state) => state.contatos,
    contact: (state) => state.contact,
    contact_from_book: (state) => state.contact_from_book,
  },
  mutations: {
    SET_CONTATOS(state, contato) {
      state.contatos = contato;
    },
    SELECT_CONTATO(state, contato) {
      state.contact = contato;
    },
    ADD_CONTACT(state, contato) {
      state.contacts.push(contato);
    },
    OPEN_CONTACT_PAGE(state, contact) {
      state.contact_from_book = contact;
    },
    UPDATE_CONTACT(state, contact) {
      let index = state.contats.findIndex((con) => {
        return con.id === contact.id;
      });
      state.contacts[index] = contact;
    },
  },

  actions: {
    UPDATE_CONTACT_IN: (context, payload) => {
      context.commit("UPDATE_CONTACT", payload);
    },
    SELECT_FROM_BOOK: (context, payload) => {
      context.commit("OPEN_CONTACT_PAGE", payload);
    },
    SELECT_CONTATO: (context, payload) => {
      context.commit("SELECT_CONTATO", payload);
    },
    LOAD_CONTACTS: (context, payload) => {
      axios()
        .get("patients")
        .then(function(response) {
          context.commit("SET_CONTATOS", response.data);
        })
        .catch(function(error) {
          console.log(error);
        });
    },
    SAVE_CONTACT: async (context, payload) => {
      try {
        const response = await axios().post("patients", payload);
        context.commit("SET_CONTATOS", response.data);
        console.log("SAVE_CONTACT::response.data", response.data);
      } catch (error) {
        console.log(error);
      }
    },
    UPDATE_CONTACT: async (context, payload) => {
      try {
        const response = await axios().put("patients/" + payload.id, payload);
        context.commit("SET_CONTATOS", response.data);
        console.log("SAVE_CONTACT::response.data", response.data);
      } catch (error) {
        console.log(error);
      }
    },
    IMPORT_PATIENTS: async (context, payload) => {
      try {
        const response = await axios().post(
          "import_patients_from_list",
          payload
        );
        context.commit("SET_CONTATOS", []);
        context.commit("SET_CONTATOS", response.data);
        event.$emit("imported_excel");
      } catch (error) {
        console.log(error);
      }
    },
    FIND_CONTACT: async (context, payload) => {
      try {
        const response = await axios().get("patients/" + payload);
        context.commit("ADD_CONTACT", response.data);
        console.log("response FIND_CONTACT", response);
      } catch (error) {
        console.log(error);
      }
    },
  },
};
