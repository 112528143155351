<template>
  <div class="w-100 slide-in-right ">
    <div
      class="w-100 scale-in-center"
      id="step-0"
      ref="actionBlockStep_0"
      v-show="this.$masterHelper.isEmpty(selectedAction_Function) && step == 0"
    >
      <div class="d-flex flex-column mt-5" id="fncBefore">
        <div>
          <strong>AÇÕES DE ENTRADA</strong>
        </div>
        <small class="mt-2"
          >Inclua ações que serão executadas antes do envio do primeiro
          conteúdo</small
        >
        <button
          @click="addActionBefore()"
          id="actionBefor"
          class="btn btn-primary  mt-3 dropdown-toggle dropdown-toggle-split "
          data-toggle="dropdown"
          aria-haspopup="true"
          aria-expanded="false"
          data-reference="parent"
        >
          + Adiconar ação de entrada
        </button>
        <div class="dropdown-menu" aria-labelledby="actionBefor">
          <a
            class="dropdown-item"
            href="#"
            @click.prevent="selectActionType('http_request')"
            >Requisição HTTP</a
          >
          <a
            class="dropdown-item"
            href="#"
            @click.prevent="selectActionType('script')"
            >Executar script</a
          >
        </div>
      </div>
      <div class="d-flex flex-column mt-5" id="fncAfter">
        <div>
          <strong>AÇÕES DE SAÍDA</strong>
        </div>
        <small class="mt-2"
          >Inclua ações que serão executadas após o envio do último conteúdo ou
          resposta do usuário.</small
        >
        <button
          id="actionAfter"
          class="btn btn-primary mt-3 "
          @click="addActionAfter()"
          data-toggle="dropdown"
          aria-haspopup="true"
          aria-expanded="false"
          data-reference="parent"
        >
          + Adicionar ação de saída
        </button>
        <div class="dropdown-menu" aria-labelledby="actionAfter">
          <a
            class="dropdown-item"
            href="#"
            @click.prevent="selectActionType('http_request')"
            >Requisição HTTP</a
          >
          <a
            class="dropdown-item"
            href="#"
            @click.prevent="selectActionType('script')"
            >Executar script</a
          >
        </div>
      </div>
    </div>
    <div
      class="w-100 scale-in-center"
      ref="actionBlockStep_1"
      v-show="step === 1 && data.actionType != 'script'"
    >
      <hr />
      <div class="d-flex align-items-center justify-content-between">
        <button class="btn">
          <i class="fas fa-long-arrow-alt-left"></i>
          <strong class="text-primary ml-2" @click="goBack()">Voltar</strong>
        </button>
        <button class="btn" @click="saveFunction()">
          <i class="far fa-save"></i>
        </button>
      </div>
      <div class="mt-3">
        Essa ação permite realizar uma requisição HTTP para qualquer API
        pública.
      </div>
      <hr />
      <div class="w-100 d-flex align-items-center  mt-3">
        <div class="form-group w-100">
          <label for=""><strong>Url</strong></label>
          <input
            type="text"
            name=""
            v-model="data.request.url"
            class="form-control "
            placeholder="endpoint"
            aria-describedby="helpId"
          />
        </div>
        <div class="form-group">
          <label for=""><strong> Método</strong></label>
          <select v-model="data.request.type" class="form-control">
            <option value="GET">GET</option>
            <option value="POST">POST</option>
            <option value="PUT">PUT</option>
            <option value="DELETE">DELETE</option>
          </select>
        </div>
      </div>
      <hr />
      <div class="form-group">
        <strong for="">Salvar resposta </strong>
        <input
          type="text"
          class="form-control mt-2"
          placeholder="Variável para o corpo da resposta"
          aria-describedby="helpId"
          v-model="data.request.response_var"
        />
        <small>Variável para o corpo da resposta no formato JSON</small>
      </div>

      <!-- <div class="form-group">
        <input
          type="text"
          name=""
          id=""
          class="form-control mt-2"
          placeholder="Variável para status da resposta"
          aria-describedby="helpId"
          v-model="response_status_var"
        />
        <small>Variável para status da resposta</small>
      </div> -->
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  data() {
    return {
      step: 0,
      data: {
        functionExecs: null,
        actionType: null,
        request: {
          url: null,
          type: null,
          response_var: null,
          response_status_var: null,
        },
      },
    };
  },
  computed: {
    ...mapGetters(["selectedAction_Function"]),
  },
  watch: {
    ActionAfter(val, oldVal) {
      this.$forceUpdate();
    },
    ActionBefore(val, oldVal) {
      this.$forceUpdate();
    },
    data(val, oldVal) {
      this.$forceUpdate();
    },
  },
  mounted() {
    this.$event.$on("toggleActionWindow", () => {
      this.$props.open = !this.$props.open;
    });
    setTimeout(() => {
      this.$event.$emit("check_functions_in_block");
    }, 1000);
    this.$event.$on("block_function_res", (res) => {
      if (res.actionType) {
        this.data = res;
        this.step = 1;
      }
      this.$forceUpdate();
    });
  },
  methods: {
    addActionBefore() {
      this.$forceUpdate();

      this.data.functionExecs = "before";
      this.$forceUpdate();
    },
    addActionAfter() {
      this.$forceUpdate();

      this.data.functionExecs = "after";
      this.$forceUpdate();
    },
    selectActionType(type) {
      this.step = 1;
      this.$forceUpdate();
      this.data.actionType = type;
      this.$forceUpdate();
    },
    goBack() {
      this.step = 0;
      if (this.data.actionType) {
        this.$event.$emit("goBackToNormal");
      }
      this.$forceUpdate();
    },
    saveFunction() {
      this.$event.$emit("functionSave", this.data);
      this.$forceUpdate();
      setTimeout(() => {
        this.goBack();
      }, 300);

      // this.$forceUpdate();
    },
  },
};
</script>

<style></style>
