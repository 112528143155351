<template>
  <div
    class="message scale-in-center"
    :class="[{ received: msgFrom(data) }, { sent: !msgFrom(data) }]"
  >
    <div
      class="p-1"
      v-html="breakLines(data.msg)"
      v-if="!data.msg !== 'null'"
    ></div>
    <div v-if="data.media && !$masterHelper.isEmpty(data.media.file)">
      <div
        class="d-flex flex-column"
        v-if="
          data.media &&
            isType(data.media.file, 'image') &&
            getImage(data.media.file)
        "
      >
        <img
          width="200px"
          :src="url"
          alt=""
          v-if="url"
          class="pointer"
          data-toggle="modal"
          data-target="#staticBackdrop"
          @click="expand"
        />
        <span v-if="data.media" class="mt-2" v-html="data.media.caption"></span>
        <div class="d-flex pl-2 pr-2 pt-2" v-if="!url">
          <div class="spinner-border text-primary" role="status">
            <span class="sr-only">Loading...</span>
          </div>
          <span class="ml-3">Carregando</span>
        </div>
      </div>

      <div v-if="data.media && isType(data.media.file, 'video')">
        <div class="d-flex pl-2 pr-2 pt-2" v-if="isLoadingVideo">
          <span> {{ loadVideo(data.media.file) }}</span>
          <div class="spinner-border text-primary" role="status">
            <span class="sr-only">Loading...</span>
          </div>
          <span class="ml-3">Carregando video</span>
        </div>
        <div v-if="!isLoadingVideo">
          <video controls>
            <source :src="url" type="video/mp4" />
            <source :src="url" type="video/webm" />
          </video>
        </div>
      </div>
    </div>
    <span class="metadata">
      <span class="time">12:00</span>
    </span>
    <small v-if="displayName && !msgFrom(data)" class="text-muted">name</small>
    <portal to="modals">
      <div
        v-if="url"
        class="modal fade"
        id="staticBackdrop"
        data-backdrop="static"
        data-keyboard="false"
        tabindex="-1"
        aria-labelledby="staticBackdropLabel"
        aria-hidden="true"
      >
        <div class="modal-dialog modal-dialog-centered">
          <div class="modal-content">
            <div class="modal-header">
              <!-- <h5 class="modal-title" id="staticBackdropLabel">Modal title</h5> -->
              <button
                type="button"
                class="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div class="modal-body">
              <img
                :src="url"
                alt=""
                v-if="url"
                class="pointer"
                data-toggle="modal"
                data-target="#staticBackdrop"
                :style="modalImgStyle"
              />
              <hr />

              <strong class="mt-2" v-html="data.media.caption"></strong>
            </div>
          </div>
        </div>
      </div>
    </portal>
  </div>
</template>

<script>
  // import io from "socket.io-client";
  import axios from "../../../http";

  const fs = require("fs");

  export default {
    name: "MessageBox",
    data() {
      return {
        type: null,
        url: null,
        modalImgStyle: {
          maxWidth: " 100%",
          maxHeight: " 100%",
          display: "block",
        },
        caption: null,
        isLoadingVideo: true,
      };
    },
    props: {
      data: {
        required: true,
      },
      displayName: {
        type: Boolean,
      },
    },
    mounted() {
      // console.log(" this.$props.dontDisplayName", this.$props.dontDisplayName);
      // var socket = io.connect("http://localhost:3002");
      // socket.on("message", function(data) {
      //   alert(data);
      // });
      // this.$forceUpdate();
    },
    methods: {
      msgFrom(msg) {
        return msg.msg_from == 1;
      },
      breakLines(msg) {
        var match = /\n/.exec(msg);
        let y = msg.split("\n");
        let z = "";
        let lastWordIndex = y.length - 1;
        if (match) {
          y.forEach((word, index) => {
            // let newText = z;
            if (index !== lastWordIndex) {
              z += word + "<br/>";
            } else {
              z += word;
            }
          });
          return z;
        } else {
          return msg;
        }
      },
      // renderFiles(data) {

      // },
      getExtention(file) {
        return file.substring(file.lastIndexOf(".") + 1);
      },
      isType(file, isThis) {
        if (file) {
          console.log("file:::", file);
          let types = {
            image: ["jpg", "jpeg", "jiff", "png"],
            video: ["mp4"],
          };
          console.log(
            "typeof::::",
            types[isThis].includes(this.getExtention(file))
          );
          return types[isThis].includes(this.getExtention(file));
        }
      },
      expand(e) {
        e.style.width = "60%";
        e.style.height = "auto";
        e.style.transition = "width 0.5s ease";
      },
      async getImage(fileName) {
        try {
          let response = await axios().get("/file/" + fileName, {
            responseType: "arraybuffer",
          });
          let b64 = `data:${response.headers["content-type"]};base64,${btoa(
            String.fromCharCode(...new Uint8Array(response.data))
          )}`;
          this.url = b64;
          return b64;
        } catch (error) {
          console.log("error on fetching image", error);
        }
      },

      async loadVideo(fileName) {
        try {
          let response = await axios().get("/file/" + fileName, {
            responseType: "arraybuffer",
          });
          // const uint8Array = new Uint8Array(response.data);
          setTimeout(() => {
            var video = new Blob([new Uint8Array(response.data)], {
              type: "video/mp4",
            });
            let b64 = (window.URL || window.webkitURL).createObjectURL(video);

            this.url = b64;
            setTimeout(() => {
              this.isLoadingVideo = false;
            }, 3000);
            return b64;
          }, 1000);
        } catch (error) {
          console.log("error on fetching image", error);
        }
      },

      // arrayBufferToBase64(buffer) {
      //   var binary = "";
      //   var bytes = new Uint8Array(buffer);
      //   var len = bytes.byteLength;
      //   for (var i = 0; i < len; i++) {
      //     binary += String.fromCharCode(bytes[i]);
      //   }
      //   return window.btoa(binary);
      // },
    },
  };
</script>

<style lang="scss">
  .message {
    //   color: #000 !important;
    clear: both;
    line-height: 18px;
    font-size: 15px;
    padding: 8px;
    position: relative;
    margin: 8px 0;
    max-width: 25vw;
    word-wrap: break-word;
  }

  .message:after {
    position: absolute;
    content: "";
    width: 0;
    height: 0;
    border-style: solid;
  }

  .message:first-child {
    margin: 16px 0 8px;
  }

  .message.received {
    background: #fff;
    color: black;
    border-radius: 0px 5px 5px 5px;
    float: left;
    .metadata .time {
      color: black;
    }
  }

  .message.received .metadata {
    padding: 0 0 0 16px;
  }

  .message.received:after {
    border-width: 0px 10px 10px 0;
    border-color: transparent #fff transparent transparent;
    top: 0;
    left: -10px;
  }

  .message.sent {
    background: #e1ffc7;
    color: black;

    border-radius: 5px 0px 5px 5px;
    float: right;
  }

  .message.sent:after {
    border-width: 0px 0 10px 10px;
    border-color: transparent transparent transparent #e1ffc7;
    top: 0;
    right: -10px;
  }

  .metadata {
    display: inline-block;
    float: right;
    padding: 0 0 0 7px;
    position: relative;
    bottom: -4px;
  }

  .metadata .time {
    //   color: rgba(0, 0, 0, 0.45);
    font-size: 11px;
    display: inline-block;
  }

  /* Extra small devices (less than 768px) */
  @media (max-width: 767px) {
    .modal-fullscreen-xs-down {
      padding: 0 !important;
    }
    .modal-fullscreen-xs-down .modal-dialog {
      width: 100%;
      height: 100%;
      margin: 0;
      padding: 0;
    }
    .modal-fullscreen-xs-down .modal-content {
      height: auto;
      min-height: 100%;
      border: 0 none;
      border-radius: 0;
      box-shadow: none;
    }
  }

  /* Small devices (less than 992px) */
  @media (max-width: 991px) {
    .modal-fullscreen-sm-down {
      padding: 0 !important;
    }
    .modal-fullscreen-sm-down .modal-dialog {
      width: 100%;
      height: 100%;
      margin: 0;
      padding: 0;
    }
    .modal-fullscreen-sm-down .modal-content {
      height: auto;
      min-height: 100%;
      border: 0 none;
      border-radius: 0;
      box-shadow: none;
    }
  }

  /* Medium devices (less than 1200px) */
  @media (max-width: 1199px) {
    .modal-fullscreen-md-down {
      padding: 0 !important;
    }
    .modal-fullscreen-md-down .modal-dialog {
      width: 100%;
      height: 100%;
      margin: 0;
      padding: 0;
    }
    .modal-fullscreen-md-down .modal-content {
      height: auto;
      min-height: 100%;
      border: 0 none;
      border-radius: 0;
      box-shadow: none;
    }
  }
</style>
