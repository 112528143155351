import Vue from "vue";
import Vuex from "vuex";
import axios from "../../http";
Vue.use(Vuex);

export default {
  state: {
    information: {},
  },
  getters: {
    information: (state) => state.information,
  },
  mutations: {
    SET_INFORMATION(state, information) {
      state.information = information;
    },
  },

  actions: {
    LOAD_INFO: async (context) => {
      try {
        const response = await axios().get("company");
        context.commit("SET_INFORMATION", response.data);
        console.log("response.data", response.data);
      } catch (error) {
        console.log(error);
      }
    },
    SAVE_INFO: (context, payload) => {
      try {
        const response = axios().post("company", payload);
        context.commit("SET_INFORMATION", response.data);
        return response.data;
      } catch (error) {
        console.log(error);
      }
    },
    UPDATE_INFO: (context, payload) => {
      try {
        const response = axios().put("company/" + payload.id, payload);
        context.commit("SET_INFORMATION", response.data);
        return response.data;
      } catch (error) {
        console.log(error);
      }
    },
  },
};
