<template>
  <!-- <div class="row"> -->
  <div class="row" v-has:access.onload="'manage-chat'">
    <div class="col-md-12">
      <div class="card mb-0">
        <div class="row no-gutters">
          <ContactList />
          <ChatMessage />
          <PatientManager />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import ContactList from "@/components/chat/ContactList";
  import ChatMessage from "@/components/chat/ChatMessage";
  import PatientManager from "@/components/chat/PatientManager";

  export default {
    components: {
      ContactList,
      ChatMessage,
      PatientManager,
    },
    data() {
      return {};
    },
    mounted() {
      this.$store.dispatch("LOAD_CONTACTS");
      console.log("mounted");
    },
    methods: {},
  };
</script>

<style></style>
