import Vue from "vue";
import Vuex from "vuex";
import axios from "../../http";
Vue.use(Vuex);

export default {
  state: {
    roles: [],
    role: {},
    permissions: [],
  },
  getters: {
    roles: (state) => state.roles,
    role: (state) => state.role,
    permissions: (state) => state.permissions,
  },
  mutations: {
    SET_ROLES(state, roles) {
      state.roles = roles;
    },
    ADD_ROLE(state, role) {
      state.roles.push(role);
    },
    SET_PERMISSIONS(state, permissions) {
      state.permissions = permissions;
    },
    DELETE_ROLE(state, id) {
      let index = state.roles.findIndex((role) => {
        return role.id === id;
      });
      state.roles.splice(index, 1);
    },
  },

  actions: {
    LOAD_ROLES: (context, payload) => {
      axios()
        .get("roles")
        .then(function(response) {
          context.commit("SET_ROLES", response.data);
        })
        .catch(function(error) {
          console.log(error);
        });
    },
    SAVE_ROLE: (context, payload) => {
      axios()
        .post("roles", payload)
        .then(function(response) {
          context.commit("ADD_ROLE", response.data);
        })
        .catch(function(error) {
          console.log(error);
        });
    },
    LOAD_PERMISSIONS: (context) => {
      axios()
        .get("permissions")
        .then(function(response) {
          context.commit("SET_PERMISSIONS", response.data);
        })
        .catch(function(error) {
          console.log(error);
        });
    },
    UPDATE_ROLE: (context, payload) => {
      axios()
        .put("roles/" + payload.id, payload)
        .then(function(response) {
          // context.commit("SET_PERMISSIONS", response.data);
          console.log("updated role", response.data);
        })
        .catch(function(error) {
          console.log(error);
        });
    },
    DELETE_ROLE: (context, id) => {
      context.commit("DELETE_ROLE", id);
      axios()
        .delete("roles/" + id)
        .then((response) => {})
        .catch((error) => {
          console.log(error);
        });
    },
  },
};
