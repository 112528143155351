import Vue from "vue";
import Vuex from "vuex";

// import axios from "axios";
import axios from "../../http";
Vue.use(Vuex);
// Vue.use(router);

export default {
  state: {
    transmition_list: [],
  },
  getters: {
    transmition_list: (state) => state.transmition_list,
  },
  mutations: {
    SET_TRANSMITION_LIST(state, transmition_list) {
      state.transmition_list = transmition_list;
    },
    PUSH_TO_LIST(state, list) {
      state.transmition_list.unshift(list);
    },
  },

  actions: {
    SAVE_TRANSMITION_LIST: (context, payload) => {
      // axiios
      console.log("criar lista de transmissão com esses contatos", payload);
      return axios()
        .post("transmition_list", payload)
        .then(function(response) {
          console.log("salvando a lista");
          context.commit("PUSH_TO_LIST", response.data);
          return response.data;
        })
        .catch(function(error) {
          console.log(error);
        });
    },
    LOAD_TRANSMITION_LIST: (context) => {
      return axios()
        .get("transmition_list")
        .then(function(response) {
          context.commit("SET_TRANSMITION_LIST", response.data);
          return true;
        })
        .catch(function(error) {
          console.log(error);
        });
    },
  },
};
