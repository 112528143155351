<template>
  <div class="container-flex new-chat-container ">
    <div class="row  no-gutters">
      <div class="col-3 column">
        <ContactList class="card" />
      </div>
      <div class="col-6 column">
        <MessageContainer
          class="card h-100"
          v-if="!$masterHelper.isEmpty(ticket)"
        />
        <!--==========-->
        <div
          class="chat_painel"
          v-if="
            $masterHelper.isEmpty(ticket) &&
              $masterHelper.isEmpty(contact_from_book)
          "
        >
          <div>
            <h1>Selecione um contato</h1>
            <!-- <h1>Informações de atendimento</h1> -->
            <!-- <div class="flex-gap-btn">
              <button type="button" class="btn btn-primary">
                Pausados <span class="badge badge-light">4</span>
              </button>
              <button type="button" class="btn btn-primary">
                Em atendimento <span class="badge badge-light">4</span>
              </button>
              <button type="button" class="btn btn-primary">
                Aguardando atendimento
                <span class="badge badge-light">4</span>
              </button>
            </div> -->
          </div>
        </div>
        <div
          class="chat_painel mb-4"
          v-if="!$masterHelper.isEmpty(contact_from_book)"
        >
          <div class="d-flex align-items-center" v-if="isOpenning">
            <div class="spinner-border text-primary" role="status">
              <span class="sr-only">Loading...</span>
            </div>
            <h2 class="ml-4">Abrindo ticket</h2>
          </div>
          <div
            class="d-flex justify-content-center align-items-center flex-column"
            v-if="!isOpenning"
          >
            <img
              class="avatar mb-3"
              width="60"
              :src="contact_from_book.avatar_url"
              alt=""
            />
            <h3>{{ contact_from_book.name }}</h3>
            <h4>{{ contact_from_book.number }}</h4>
            <hr />
            <h4>Inicíar atendimento?</h4>
            <div class="mt-2">
              <button
                class="btn btn-primary mr-2"
                @click="startConversation(1)"
              >
                Sim
              </button>
              <button class="btn btn-danger" @click="startConversation(0)">
                Não
              </button>
            </div>
          </div>
        </div>
        <!--==========-->
      </div>
      <div class="col-3 column">
        <ContactManager class="card h-100" />
      </div>
    </div>
  </div>
</template>

<script>
  import { mapGetters, mapActions } from "vuex";

  import ContactList from "@/components/chat/new/ContactList";
  import MessageContainer from "@/components/chat/new/MessageContainer";
  import ContactManager from "@/components/chat/new/ContactManager";
  export default {
    components: {
      ContactList,
      MessageContainer,
      ContactManager,
    },

    data() {
      return {
        isOpenning: false,
      };
    },
    computed: {
      ...mapGetters(["ticket", "contact_from_book"]),
    },
    mounted() {
      this.$event.$on("closing-ticket", () => {
        this.CLOSE_TICKET();
      });
    },
    methods: {
      ...mapActions([
        "CLOSE_TICKET",
        "NEW_TICKET",
        "SELECT_FROM_BOOK",
        "N_SELECT_TICKET",
      ]),

      startConversation(x) {
        if (x === 1) {
          this.isOpenning = true;
          this.NEW_TICKET(this.contact_from_book).then(() => {
            this.N_SELECT_TICKET(this.contact_from_book.id);
            this.$event.$emit("select-ticket");
            this.$event.$emit("contact-from-book");

            this.isOpenning = false;
            this.SELECT_FROM_BOOK({});
          });
        }
        if (x === 0) this.SELECT_FROM_BOOK({});
      },
    },
  };
</script>

<style lang="scss">
  .new-chat-container {
    max-height: 100% !important;
    .row {
      max-height: 80vh !important;
      .column {
        max-height: 80vh !important;
      }
    }
  }

  .chat_painel {
    background-color: #fff;
    height: 80vh !important;
    padding: 2em;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    .avatar {
      width: 100px;
      height: 100px;
      border-radius: 15em !important;
    }
  }
</style>
