<template>
  <div class="row" style="max-height:70vh">
    <div class="col-12 col-sm-6 col-xl-3">
      <div class="card">
        <div
          class="card-header d-flex align-items-center justify-content-between"
        >
          <strong>Contatos</strong>
          <button class="btn btn-warning" @click.prevent="selectAllContacts()">
            Selecionar todos
          </button>
        </div>
        <div class="card-body">
          <div class="">
            <div class="form-group d-flex align-items-center">
              <select
                id="transList"
                class="form-control"
                v-model="selectedList"
                ref="transmissionSelect"
              >
                <option
                  disabled
                  :value="[]"
                  selected
                  v-if="transmition_list.length < 1"
                  >Nenhuma lista criada</option
                >
                <option
                  disabled
                  :value="[]"
                  selected
                  v-if="transmition_list.length"
                  >Selecione uma lista</option
                >

                <option
                  :value="list.contacts"
                  v-for="list in transmition_list"
                  :key="list.id"
                  :data-id="list.id"
                  >{{ list.name }}</option
                >
              </select>
              <button class="btn" @click.prevent="clearContactList()">
                <i class="fas fa-times"></i>
              </button>
            </div>
            <div class="d-flex mb-3">
              <input
                type="text"
                class="form-control"
                v-model="search"
                placeholder="Pesquisar: nome ou numero"
              />
            </div>
          </div>
          <div class="contacts-selection-container">
            <div
              @click="selectContact(contact)"
              class="contact"
              v-for="(contact, index) in Core"
              :key="index"
            >
              <div class="contact-info">
                {{ contact.name.substr(0, 15) }} <br />
                {{ contact.number }}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class=" col-12 col-sm-6  col-xl-3">
      <div class="card">
        <div
          class="card-header  d-flex align-items-center justify-content-between"
        >
          <strong>Contatos selecionados</strong>
          <button
            class="btn btn-warning"
            @click.prevent="
              clearSelectedContacts();
              contatos;
              selectedList = [];
              transmition_list_id = null;
            "
          >
            Remover todos
          </button>
        </div>
        <div
          class="d-flex p-3 m-2 justify-content-end "
          v-has:access.permission="'save-campaign-contact-list'"
        >
          <div class="alert alert-primary " v-if="!creatingList">
            <div>Salve essa lista caso queira reutilizá-la novamente.</div>
            <hr />
            <div class="d-flex  justify-content-end">
              <!-- <button class="btn btn-primary">Criar lista</button> -->
              <span class="text-primary pointer" @click="toggleSaveList()"
                >Criar lista</span
              >
            </div>
          </div>
          <div class="alert alert-primary w-100" v-if="creatingList">
            <input
              v-model="listName"
              type="text"
              class="form-control"
              placeholder="nome da sua lista"
            />
            <hr />
            <div class="d-flex  justify-content-end">
              <!-- <button class="btn btn-primary">Criar lista</button> -->
              <span class="text-danger pointer" @click="toggleSaveList()"
                >Cancelar</span
              >
              <strong
                class="text-primary pointer ml-4"
                @click="saveTrasnmitionList()"
                >Salvar</strong
              >
            </div>
          </div>
        </div>
        <div class="card-body pt-0">
          <div class="contacts-selection-container">
            <div
              @click="removeContact(contact.id)"
              class="contact"
              v-for="(contact, index) in selectedContacts"
              :key="index"
            >
              <!-- <div class="avatar">
                <img
                  width="50"
                  class="rounded-circle"
                  :src="contact.avatar_url"
                  alt=""
                />
              </div> -->
              <div class="contact-info">
                {{ contact.name.substr(0, 15) }} <br />
                {{ contact.number }}
              </div>
            </div>
            <div class="text-primary mt-2" v-if="selectedContacts.length < 1">
              Nenhum contato selecionado
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class=" col-12  col-xl-6" style="height:fit-content;">
      <div class="card">
        <div
          class="card-header  d-flex align-items-center justify-content-between"
        >
          <button
            class="btn btn-primary"
            @click="toggleCampaignList()"
            v-if="!campaignList"
          >
            Campanhas salvas
          </button>
          <button
            class="btn btn-warning"
            @click="toggleCampaignList()"
            v-if="campaignList"
          >
            Voltar
          </button>

          <div class="ml-auto" v-if="messages.length && !savingCampaign">
            <button class="btn btn-warning" @click="saveCampaign()">
              Salvar campanha
            </button>
            <button class="btn btn-warning ml-2" @click="startTransmition()">
              Enviar
            </button>
          </div>
        </div>
        <!--==============================campaign list================================-->
        <div class="card w-100 h-100 p-5" v-if="campaignList">
          <div class="w-100">
            <h3 v-if="!savingCampaign">Campanhas salva</h3>
            <h3 v-if="savingCampaign">Salvar campanha</h3>

            <div class="campaignsList" v-if="!savingCampaign">
              <div
                class="item d-flex justify-content-between align-items-center"
                v-for="camp in campaigns"
                :key="camp.id"
                @click="selectThisCampaign(camp)"
              >
                <div>
                  {{ camp.name }}
                </div>
                <div>
                  {{ camp.created_at }}
                </div>
              </div>
            </div>
          </div>
          <div
            class="w-100 h-100 d-flex align-items-center justify-content-center"
          >
            <h3 v-if="!campaigns.length && !savingCampaign">
              Nenhuma campanha salva.
            </h3>
            <div v-if="savingCampaign && !isRequesting">
              <h3>Dê um nome a sua campanha</h3>
              <input
                type="text"
                class="form-control mt-4"
                placeholder="nome"
                v-model="campaignName"
              />
              <button
                class="btn-block btn btn-default mt-4"
                :class="[{ 'btn-primary': campaignName }]"
                :disabled="!campaignName"
                @click="submitCreatedCampaign()"
              >
                Salvar
              </button>
            </div>
            <div
              class="w-100 d-flex align-items-center justify-content-center"
              v-if="isRequesting"
            >
              <div class="spinner-border text-secondary mr-5" role="status">
                <span class="sr-only">Loading...</span>
              </div>
              <span> Salvando, aguarde...</span>
            </div>
          </div>
        </div>
        <!--==============================//campaign list===============================-->

        <div
          class="message_container"
          style="height:69vh!important"
          v-if="!campaignList"
        >
          <div class="__messages">
            <!--==============================messages===============================-->
            <div
              v-for="(msg, index) in messages"
              :key="index"
              :id="'msg_block_' + index"
              class="d-flex justify-content-end align-items-center scale-in-center"
              style="position:relative"
            >
              <div class="d-flex flex-column mr-2 show-fade">
                <button
                  class="btn mb-2 show-fade"
                  v-show="index > 0"
                  @click="move(index, index - 1)"
                >
                  <i class="fas fa-chevron-up"></i>
                </button>
                <button
                  class="btn mb-2 show-fade"
                  v-show="index !== lastIndex(messages)"
                  @click="move(index, index + 1)"
                >
                  <i class="fas fa-chevron-down"></i>
                </button>
              </div>
              <MessageBlock :data="msg" />
              <span hidden>{{ autoScroll(index) }}</span>
              <button class="btn mb-2 show-fade" @click="removeBlock(index)">
                <i class="far fa-trash-alt text-danger"></i>
              </button>
            </div>
            <!--============================//messages===============================-->
          </div>
          <div class="__compose_area d-flex flex-column" style="">
            <div class="w-100 p-2 mb-3 " v-if="tempFile">
              <div class="preview_container d-flex justify-content-between">
                <div class="d-flex left-side ">
                  <img height="100px" :src="tempFile.src" alt="" />
                  <div class="d-flex flex-column  ml-2 p-2">
                    <p>
                      <strong>{{ tempFile.name }}</strong>
                    </p>
                    <p>
                      <strong>{{ tempFile.size }}</strong>
                    </p>
                  </div>
                </div>
                <button class="btn"><i class="far fa-trash-alt"></i></button>
              </div>
            </div>
            <div class="col">
              <div class="d-flex  align-items-center">
                <div class="mt-0 mb-0 w-100">
                  <div
                    id="customArea"
                    onkeyup="onEditorChange()"
                    contenteditable="true"
                    class="composer form-control bg-transparent w-100"
                  ></div>
                  <input ref="files" type="file" hidden @change="handler()" />
                  <textarea
                    id="textarea1"
                    hidden
                    type="text"
                    v-model="block.message"
                  ></textarea>
                </div>
                <button
                  @click="createBlock()"
                  class="btn btn-circle  btn-info float-right text-white ml-3"
                >
                  <i class="fas fa-plus"></i>
                </button>
                <button
                  @click="loadPreview()"
                  class="btn btn-circle  btn-info float-right text-white ml-3"
                >
                  <i class="fas fa-paperclip"></i>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import { mapGetters, mapActions } from "vuex";
  import MessageBlock from "@/components/chat/new/MultiMediaBox";
  import axios from "../../http";
  export default {
    components: {
      MessageBlock,
    },
    data() {
      return {
        selectedList: [],
        selectedContacts: [],
        search: null,
        creatingList: false,
        savingCampaign: false,
        listName: null,
        campaignName: null,
        isRequesting: false,
        transmition_id: null,
        //============compose message vars=================
        isCreating: false,
        isEditing: false,
        messages: [],
        campaignList: false,
        selectedCampaign: false,
        block: {
          message: null,
          src: null,
          link: null,
          name: null,
          size: null,
        },
        tempFile: null,
        lastIndex: (messages) => this.$masterHelper.arrLastIndex(messages),
        //===========//compose message vars=================
      };
    },
    mounted() {
      window.onEditorChange = () => {
        let element = document.querySelector("[contenteditable]");
        this.block.message = element.textContent;
      };
      this.N_LOAD_CAMPAIGN();
      this.LOAD_TRANSMITION_LIST();
    },
    computed: {
      ...mapGetters([
        "contacts",
        "transmition_list",
        "campaigns",
        "vemomStatus",
        "loggedUser",
      ]),
      Core() {
        let core_contacts = this.contacts;
        let selecteds = this.selectedContacts;
        core_contacts = core_contacts.filter((contact) => {
          return !selecteds.some((selected) => {
            return selected.id === contact.id;
          });
        });

        if (this.search) {
          if (this.isNumber(this.search)) {
            core_contacts = core_contacts.filter((contact) => {
              return contact.number.includes(this.search);
            });
          } else {
            core_contacts = core_contacts.filter((contact) => {
              return contact.name
                .toLowerCase()
                .includes(this.search.toLowerCase());
            });
          }
        }
        if (this.selectedList.length > 0) {
          var select = document.getElementById("transList");
          var selectedListId = select.options[select.selectedIndex].dataset.id;
          this.transmition_list_id = parseInt(selectedListId);
          core_contacts = core_contacts.filter((contact) => {
            return this.selectedList.some((list) => {
              return contact.id === list;
            });
          });
        }
        return core_contacts;
      },
    },
    methods: {
      ...mapActions([
        "SELECT_CONTACT",
        "REMOVE_CONTACT",
        "SET_CAMPAIGN",
        "REMOVE_ALL_CONTACT",
        "SELECT_ALL_CONTACT",
        "SAVE_TRANSMITION_LIST",
        "LOAD_TRANSMITION_LIST",
        "LOAD_CAMPAIGNS",
        "SAVE_CAMPAIGN",
        "VENOM_SESSION_STATUS",
        "N_SET_CAMPAIGN",
        "N_LOAD_CAMPAIGN",
        "START_TRANSMITION",
      ]),
      isNumber(input) {
        return /^\d+$/.test(input);
      },
      selectContact(contato) {
        let isSelected = this.selectedContacts.find((contact) => {
          return contact.id === contato.id;
        });
        if (!isSelected) {
          this.selectedContacts.push(contato);
        }
      },
      removeContact(id) {
        let index = this.selectedContacts
          .map((contact) => contact.id)
          .indexOf(id);
        this.selectedContacts.splice(index, 1);
      },
      selectAllContacts() {
        this.Core.forEach((con) => {
          this.selectContact(con);
        });
      },
      clearSelectedContacts() {
        this.selectedContacts = [];
      },
      toggleSaveList() {
        this.creatingList = !this.creatingList;
        if (!this.creatingList) {
          this.listName = null;
        }
      },
      async saveTrasnmitionList() {
        //TODO criar validações antes de salvar
        if (
          this.listName &&
          this.listName.length > 0 &&
          this.selectedContacts.length > 0
        ) {
          var list = {
            name: this.listName,
            contacts: this.selectedContacts.map((contact) => contact.id),
          };
          var list = await this.SAVE_TRANSMITION_LIST(list);
          this.creatingList = false;
          this.listName = null;
          this.transmition_list_id = list.id;
          this.$refs.transmissionSelect.classList.add("transmission_created");
          setTimeout(() => {
            this.$refs.transmissionSelect.classList.remove(
              "transmission_created"
            );
          }, 3000);
          this.$toast.success(" ", "Lista salva", {
            icon: "icon-person",
            position: "topRight",
            timeout: 3000,
          });
        } else {
          // alert error
          this.$toast.error(
            " ",
            "Insira um nome pra sua lista e selecione alguns contatos",
            {
              icon: "icon-person",
              position: "topRight",
              timeout: 8000,
            }
          );
        }
      },
      autoScroll(index) {
        if (index == this.lastIndex(this.messages)) {
          setTimeout(() => {
            // let element = this.$refs[`msg_block_${index}`];
            let element = document.getElementById(`msg_block_${index}`);
            element.scrollTop = element.scrollHeight;
            // element.scrollIntoView({
            //   block: "center",
            //   behavior: "smooth",
            //   inline: "end",
            // });
          }, 2000);
        }
      },
      //===============compose message functions=================
      blockExeption() {
        //check message block
        if (!this.block.message && !this.tempFile) {
          this.$toast.error(" ", "Campo de mensagem vazia", {
            icon: "icon-person",
            position: "topRight",
            timeout: 3000,
          });
          return false;
        }
        return true;
      },
      createBlock() {
        if (this.blockExeption()) {
          if (this.tempFile) {
            this.block.src = this.tempFile.src;
            this.block.name = this.tempFile.name;
            this.block.size = this.tempFile.size;
          }
          this.messages.push(this.block);
          this.clearBlock();
          this.clearTempFile();
        }
      },
      clearBlock() {
        this.block = {
          message: null,
          src: null,
          link: null,
        };
        let element = document.querySelector("[contenteditable]");
        element.textContent = "";
      },
      clearTempFile() {
        this.tempFile = null;
      },
      removeBlock(index) {
        this.messages.splice(index, 1);
      },
      campaignExeption() {
        if (this.campaigns.length) {
          let existingName = this.campaigns.some((camp) => {
            return camp.name === this.campaignName;
          });
          if (existingName) {
            this.$toast.warning(" ", "Já existe uma campanha com este nome.", {
              icon: "icon-person",
              position: "topRight",
              timeout: 3000,
            });
            return true;
          } else {
            return false;
          }
        }
      },
      async submitCreatedCampaign() {
        if (!this.campaignExeption()) {
          this.isRequesting = true;
          let cam = {
            name: this.campaignName,
            messages: this.messages,
          };
          await this.N_SET_CAMPAIGN(cam);
          this.isRequesting = false;
          this.$toast.success(" ", "A campanha foi salva", {
            icon: "icon-person",
            position: "topRight",
            timeout: 3000,
          });
          this.savingCampaign = false;
          this.campaignName = null;
        }
      },
      //image handler========================================
      loadPreview() {
        this.$refs.files.click();
        this.messageType = "image";
      },
      handler() {
        let tempFile = this.$refs.files.files[0];
        if (this.listForImage(tempFile.name)) {
          let reader = new FileReader();
          reader.addEventListener(
            "load",
            function() {
              if (reader.result !== undefined) {
                this.tempFile = {
                  src: reader.result,
                  name: tempFile.name,
                  size: this.formatBytes(tempFile.size),
                };
              }
            }.bind(this),
            false
          );
          reader.readAsDataURL(tempFile);
        }
        const input = this.$refs.files;
        input.type = "text";
        input.type = "file";
      },
      listForImage(file) {
        var forImage = ["jpeg", "jpg", "png", "gif", "webp", "jfif"];
        var canUpload = forImage.some((ext) => {
          return ext === this.getExtention(file);
        });
        return canUpload;
      },
      getExtention(file) {
        console.log("fiole", file);
        return file.substring(file.lastIndexOf(".") + 1);
      },
      formatBytes(bytes, decimals = 2) {
        if (bytes === 0) return "0 Bytes";

        const k = 1024;
        const dm = decimals < 0 ? 0 : decimals;
        const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];

        const i = Math.floor(Math.log(bytes) / Math.log(k));

        return (
          parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i]
        );
      },
      ////image handler========================================
      //============compose message functions=================
      move(from, to) {
        this.messages.splice(to, 0, this.messages.splice(from, 1)[0]);
      },
      saveCampaign() {
        this.campaignName = null;
        this.campaignList = true;
        this.savingCampaign = true;
      },
      toggleCampaignList() {
        this.campaignList = !this.campaignList;
        this.savingCampaign = false;
      },
      async selectThisCampaign(campaign) {
        this.messages = null;
        this.selectedCampaign = true;
        this.transmition_id = campaign.id;
        this.messages = campaign.content;
        this.toggleCampaignList();
      },
      async startTransmition() {
        this.START_TRANSMITION({
          transmition_id: this.transmition_id,
          clientName: this.loggedUser.clientName,
          selectedContacts: this.selectedContacts,
          content: this.messages,
        }).then(() => {
          this.$toast.success(" ", "Enviando...", {
            icon: "icon-person",
            position: "topRight",
            timeout: 3000,
          });
          this.selectedContacts = [];
          this.messages = [];
          this.savingCampaign = false;
          setTimeout(() => {
            this.$router.push({ name: "campaign_report" });
          }, 2000);
        });
      },
      clearContactList() {
        this.selectedList = [];
        this.transmition_list_id = null;
      },
    },
  };
</script>

<style lang="scss">
  .show-fade {
    transition: 0.5s !important;
  }
  .contacts-selected-container {
    display: flex;
    flex-wrap: wrap;
    // margin-top: 2em;
    max-height: 60vh;
    overflow-x: hidden;
    overflow-y: auto;
    &::-webkit-scrollbar {
      width: 8px; /* width of the entire scrollbar */
    }
    &::-webkit-scrollbar-thumb {
      background-color: rgb(204, 204, 204); /* color of the scroll thumb */
      border-radius: 20px; /* roundness of the scroll thumb */
      /* // border: 1px solid rgb(235, 235, 235); creates padding around scroll thumb */
    }
    &:last-child {
      padding-bottom: 5em;
    }
  }
  .contacts-selection-container {
    padding-bottom: 3em;
    display: flex;
    flex-wrap: wrap;
    margin-top: 2em;
    max-height: 50vh;
    height: 100%;
    overflow-x: auto;
    /* overflow-y: auto; */
    &::-webkit-scrollbar {
      width: 8px; /* width of the entire scrollbar */
    }
    &::-webkit-scrollbar-thumb {
      background-color: rgb(204, 204, 204); /* color of the scroll thumb */
      border-radius: 20px; /* roundness of the scroll thumb */
      /* // border: 1px solid rgb(235, 235, 235); creates padding around scroll thumb */
    }
  }
  .contact {
    display: flex;
    width: 100%;
    padding: 1em;
    border: 1px solid rgb(226, 226, 226);
    border-radius: 10px;
    margin-top: 1em;
    // margin-right: 1em;
    .avatar {
      margin-right: 3em;
    }
    &:hover {
      border: 1px solid rgb(128, 61, 216);
    }
  }
  .transmission_created {
    border: 1px solid #5f76e8 !important;
  }

  div.composer {
    display: block;
    min-height: 60px;
    max-height: 98px;
    overflow-x: auto;
    width: calc(100% - 103px);
    line-height: 21px;
    padding: 10px;
    font-size: 15px;
    line-height: 25px;
    float: left;
    border: 2px solid rgba(211, 211, 211, 0.24);

    overflow-wrap: break-word;
    &::-webkit-scrollbar {
      width: 8px; /* width of the entire scrollbar */
    }
    &::-webkit-scrollbar-thumb {
      background-color: rgb(204, 204, 204); /* color of the scroll thumb */
      border-radius: 20px; /* roundness of the scroll thumb */
      border: 1px solid rgba(158, 158, 158, 0.589); /* creates padding around scroll thumb */
    }
    &::-webkit-scrollbar-track {
      background: rgba(255, 255, 255, 0.589); /* color of the tracking area */
    }
  }
  .campaignsList {
    padding-bottom: 3em;
    display: flex;
    flex-wrap: wrap;
    margin-top: 2em;
    max-height: 80vh;
    height: 100%;
    overflow-x: auto;
    .item {
      display: flex;
      width: 100%;
      padding: 1em;
      border: 1px solid #e2e2e2;
      border-radius: 10px;
      margin-top: 1em;
      &:hover {
        cursor: pointer;
        border: 1px solid rgb(128, 61, 216);
      }
    }
  }
</style>
