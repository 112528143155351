<template>
  <aside
    class="left-sidebar"
    data-sidebarbg="skin6"
    id="left_side_bar"
    v-if="nav"
  >
    <!-- Sidebar scroll-->
    <div class="scroll-sidebar" data-sidebarbg="skin6">
      <!-- Sidebar navigation-->
      <nav class="sidebar-nav">
        <ul id="sidebarnav">
          <li class="sidebar-item">
            <router-link
              aria-expanded="false"
              class="sidebar-link sidebar-link"
              :to="{ name: 'index' }"
              ><i class="mr-3 fas fa-home"></i
              ><span class="hide-menu">Dashboard</span></router-link
            >
          </li>
          <li class="list-divider"></li>
          <li class="nav-small-cap">
            <span class="hide-menu">Menu</span>
          </li>
          <li class="sidebar-item" v-has:access.permission="'manage-settings'">
            <!-- <li class="sidebar-item"> -->
            <router-link
              aria-expanded="false"
              class="sidebar-link"
              :to="{ name: 'info' }"
              ><i class="mr-3 fas fa-cogs"></i
              ><span class="hide-menu">Configurações</span></router-link
            >
          </li>
          <li class="sidebar-item" v-has:access.permission="'manage-chat'">
            <!-- <li class="sidebar-item"> -->
            <router-link
              aria-expanded="false"
              class="sidebar-link"
              :to="{ name: 'chat' }"
              ><i class="mr-3 far fa-comments"></i
              ><span class="hide-menu">Chat</span></router-link
            >
          </li>
          <li class="sidebar-item" v-has:access.permission="'manage-campaign'">
            <!-- <li class="sidebar-item"> -->
            <router-link
              aria-expanded="false"
              class="sidebar-link"
              :to="{ name: 'tickets' }"
              ><i class="mr-3 fas fa-ticket-alt"></i
              ><span class="hide-menu">Tickets</span></router-link
            >
          </li>
          <li class="sidebar-item" v-has:access.permission="'manage-campaign'">
            <!-- <li class="sidebar-item"> -->
            <router-link
              aria-expanded="false"
              class="sidebar-link"
              :to="{ name: 'campaign' }"
              ><i class="mr-3 far fa-paper-plane"></i
              ><span class="hide-menu">Campanha</span></router-link
            >
          </li>
          <li class="sidebar-item" v-has:access.permission="'manage-campaign'">
            <!-- <li class="sidebar-item"> -->
            <router-link
              aria-expanded="false"
              class="sidebar-link"
              :to="{ name: 'campaign_report' }"
              ><i class="fas fa-chart-line mr-3 "></i
              ><span class="hide-menu">Relatorios</span></router-link
            >
          </li>
          <li class="sidebar-item" v-has:access.permission="'manage-patients'">
            <!-- <li class="sidebar-item"> -->
            <router-link
              aria-expanded="false"
              class="sidebar-link"
              :to="{ name: 'patient' }"
              ><i class="mr-3 far fa-address-book"></i
              ><span class="hide-menu">Pacientes</span></router-link
            >
          </li>
          <!-- <li class="sidebar-item"> -->
          <li
            class="sidebar-item"
            v-has:access.permission="'manage-appointment'"
          >
            <router-link
              aria-expanded="false"
              class="sidebar-link"
              :to="{ name: 'appointment' }"
              ><i class="mr-3 fas fa-user-clock"></i
              ><span class="hide-menu">API</span></router-link
            >
          </li>
          <li class="sidebar-item" v-has:access.permission="'manage-settings'">
            <!-- <li class="sidebar-item"> -->
            <router-link
              aria-expanded="false"
              class="sidebar-link"
              :to="{ name: 'security' }"
              ><i class="mr-3 fas fa-shield-alt"></i
              ><span class="hide-menu">Segurança</span></router-link
            >
          </li>
        </ul>
      </nav>
      <!-- End Sidebar navigation -->
    </div>
    <!-- End Sidebar scroll-->
  </aside>
</template>

<script>
  export default {
    name: "LeftNav",
    data() {
      return {
        nav: true,
      };
    },
    mounted() {
      this.$event.$on("toggleSideNav", () => {
        var wrapper = document.getElementById("mainWrapper");
        var navHeader = document.getElementById("navbarSupportedContent");
        var topHeader = document.getElementById("topHeader");
        var navBrand = document.getElementById("navBrand");

        this.nav = !this.nav;
        if (this.nav) {
          wrapper.classList.remove("collapsed-wrapper");
          navHeader.classList.remove("collapsed-wrapper");

          wrapper.style.marginLeft = "260px";
          topHeader.classList.remove("collapsed-wrapper");
          navBrand.style.display = "block";
        } else {
          wrapper.classList.add("collapsed-wrapper");
          navHeader.classList.add("collapsed-wrapper");

          topHeader.classList.add("collapsed-wrapper");
          navBrand.style.display = "none";
          wrapper.style.marginLeft = "unset";
        }
      });
    },
  };
</script>

<style lang="scss">
  aside .sidebar-link.router-link-exact-active {
    border-radius: 0px 60px 60px 0px;
    color: #fff !important;
    background-color: #12378b;
    /* background: linear-gradient( to right, #8971ea, #7f72ea, #7574ea, #6a75e9, #5f76e8 ); */
    box-shadow: 0px 7px 12px 0px rgba(95, 118, 232, 0.21);
    opacity: 1;
  }
  // .left-sidebar {
  //   background-color: #fff;
  //   color: #12378b;
  //   box-shadow: 0px 7px 12px 0px rgba(95, 118, 232, 0.21);
  // }
</style>
