import Vue from "vue";
import router from "../router/index";

import store from "../store/index";

Vue.directive("has", (el, binding, vnode) => {
  const modifiers = binding.modifiers;
  const arg = binding.arg;
  const value = binding.value;
  var user = store.getters.loggedUser;
  if (user && user.roles && user.roles.length) {
    if (arg === "access") {
      if (modifiers.onload && !access(value)) {
        el.remove();
        console.log("você não tem permissão");
        window.location = "/";
      }
      if (modifiers.permission) {
        if (!access(value)) {
          el.remove();
        }
      }
    }
  }

  // if (modifiers) {
  //   console.log("has modifiers", modifiers);
  // }

  function access(value) {
    var hasIt = user.roles[0].permissions.some((permission) => {
      return permission.name === value;
    });
    return hasIt;
  }
});
