<template>
  <div class="col-lg-6 ">
    <TransferTicket />
    <div v-if="!$masterHelper.isEmpty(ticket)">
      <div
        class="border-right"
        style="height: calc(100vh - 262px);overflow-y: hidden;"
      >
        <div class="card-header">
          <div class="d-flex align-items-center justify-content-between">
            <div class="d-flex align-items-center">
              <div class="chat-img d-inline-block">
                <img
                  v-if="getReceiver()"
                  :src="getReceiver().avatar_url"
                  alt="user"
                  width="45"
                  height="45"
                  class="rounded-circle"
                />
              </div>
              <div class="d-flex flex-column ml-3">
                <div>
                  {{ getReceiver().name }}
                </div>
                <strong>Ticket :{{ ticket.id }}</strong>
              </div>
            </div>
            <div class="dropdown sub-dropdown">
              <button
                class="btn btn-link text-muted dropdown-toggle"
                type="button"
                id="dd1"
                data-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false"
              >
                <i class="fas fa-ellipsis-v"></i>
              </button>
              <div
                class="dropdown-menu dropdown-menu-right"
                aria-labelledby="dd1"
                x-placement="bottom-end"
                style="position: absolute; will-change: transform; top: 0px; left: 0px; transform: translate3d(-110px, 39px, 0px);"
              >
                <a
                  class="dropdown-item d-flex"
                  href="#"
                  @click.prevent="closeChat()"
                >
                  <div class="mr-3"><i class="fas fa-door-closed"></i></div>
                  <span>Fechar</span>
                </a>
                <a class="dropdown-item d-flex" href="#" @click="transfer()">
                  <div class="mr-3"><i class="fas fa-exchange-alt"></i></div>

                  <span>Transferir</span></a
                >
                <a
                  class="dropdown-item text-danger d-flex"
                  href="#"
                  @click.prevent="closeTicket()"
                >
                  <div class="mr-3"><i class="fas fa-sign-out-alt"></i></div>
                  <span>Encerrar</span></a
                >
              </div>
            </div>
          </div>
        </div>
        <!--chat Row -->
        <div
          class="w-100 h-100 p-3 conversation-container chat-msg-container mb-5"
        >
          <MessageBox
            v-for="message in selectedTicketMessages"
            :key="message.id"
            :data="selectedTicketMessages"
            :id="'msg_block__' + message.id"
          />

          <div
            id="bottom_container"
            class="p-2 message scale-in-center received"
            style="visibility: hidden;"
          ></div>
        </div>

        <div v-show="!loadedMsg && isLoadingMsgs">Carregando mensagens</div>
      </div>
      <div class="card-body border-top">
        <div
          class="row justify-content-between"
          v-show="this.ticket.operator_id"
        >
          <div class="col-11">
            <div class="input-field mt-0 mb-0">
              <textarea
                v-model="textMessage"
                id="textarea1"
                placeholder="Digite a mensagem e aperte Enter"
                class="form-control border-0"
                cols="100"
                type="text"
              ></textarea>
            </div>
          </div>
          <button
            class="btn btn-circle  btn-cyan float-right text-white"
            @click="sendMessage()"
          >
            <i class="fas fa-paper-plane"></i>
          </button>
        </div>
        <div v-if="!this.ticket.operator_id" class="text-center">
          <button class="btn btn-warning" @click="startTicket()">
            Iníciar atendimento
          </button>
        </div>
      </div>
    </div>
    <!--====Quando não tem chat aberto, tudo abaixo desta linha mostra nas seguintes condições=====-->
    <div class="w-100 h-100" v-if="!passiveChatOnly">
      <div
        v-if="
          !startingNewTicket &&
            !openChat &&
            !this.$whatsappReceptiveOnly &&
            !ticket.id
        "
        class="p-3 w-100 h-100 d-flex align-items-center justify-content-center"
      >
        <div class="d-flex flex-column">
          <!-- <small class="text-danger">Essa função não esta finalizada</small> -->

          <img
            ref="cta_img"
            class="mb-2"
            src="../../assets/icons/undraw_Group_chat_re_frmo.png"
            width="200"
          />
          <h4 ref="cta_desc">Inicie uma nova chamada</h4>

          <button
            class="btn btn-primary mt-3 "
            ref="cta_btn"
            @click="startNewConversation()"
          >
            Iniciar
          </button>
        </div>
      </div>
      <!--==== CONDIÇÃO: Iniciando uma nova conversa=====-->
      <div
        v-if="startingNewTicket"
        class="p-3 w-100 h-100 d-flex align-items-center justify-content-center scale-in-center flex-column"
      >
        <div class="w-100">
          <div class="form-group" v-if="$canUseDepartments">
            <label for="">Selecione um departamento</label>
            <select class="form-control" name="" id="">
              <option>Departamento 1</option>
              <option>Departamento 2</option>
              <option>Departamento 2</option>
            </select>
          </div>
          <div class="form-group">
            <label for="">Selecione o paciente</label>
            <multiselect
              :internal-search="false"
              @search-change="searching"
              v-model="selectedPatient"
              :options="onlyWhoHasClosedTicket"
              placeholder="Select one"
              label="name"
              track-by="name"
              @input="selectPatient"
            ></multiselect>
          </div>
        </div>
        <div class="d-flex" v-if="!$masterHelper.isEmpty(selectedPatient)">
          <button class="btn btn-primary" @click="openTicket()">
            Iniciar conversa
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import { mapActions, mapGetters } from "vuex";

  import Multiselect from "vue-multiselect";
  import MessageBox from "@/components/chat/MessageBox";
  import TransferTicket from "@/components/chat/TransferTicket";
  import io from "socket.io-client";

  export default {
    name: "ChatMessages",
    components: {
      Multiselect,
      MessageBox,
      TransferTicket,
    },
    data() {
      return {
        selectedPatient: {},
        openChat: false,
        startingNewTicket: false,
        patient: {},
        openTicketData: {
          department_id: null,
          patient_id: null,
        },
        passiveChatOnly: this.$whatsappReceptiveOnly,
        optionsList: [],
        loadedMsg: false,
        isLoadingMsgs: false,
        loadedMessagesFrom_ticket: [],
        textMessage: null,
        searching: "",
        UPDATESOCKET: 0,
      };
    },
    computed: {
      ...mapGetters([
        "ticket_messages",
        "contatos",
        "ticket",
        "tickets",
        "loggedUser",
        "messages",
      ]),

      selectedTicketMessages() {
        // this.UPDATESOCKET;
        return this.messages.filter((msg) => {
          return msg.ticket_id === this.ticket.id;
        });
        // this.loadedMessagesFrom_ticket = this.ticket_messages;
        // return this.ticket_messages;
      },
      onlyWhoHasClosedTicket() {
        this.UPDATESOCKET;
        let searching = this.searching;
        let contacts = this.contatos.filter((contact) => {
          return !this.tickets.some((ticket) => {
            return (
              ticket.closed_at === null && ticket.patient_id === contact.id
            );
          });
        });
        if (searching.trim().length) {
          contacts = contacts.filter((con) => {
            return (
              con.name.toLowerCase().includes(searching.toLowerCase()) ||
              (con.cpf && con.cpf.includes(searching)) ||
              con.number.includes(searching)
            );
          });
        }
        return contacts;
      },
    },
    mounted() {
      this.closeChat();
      this.socket = io(process.env.VUE_APP_SOCKET_SERVER);
      setTimeout(() => {
        if (this.contatos.length > 0) {
          this.search();
        }

        this.socket.on(this.loggedUser.clientName + "::message", (data) => {
          console.log("incomming message:", data);
          this.SOCKET__MESSAGE(data);
          if (data.data.ticket_id === this.ticket.id) {
            this.scrollLastMessage();
          }
          this.reCompute();
        });
        this.socket.on(
          this.loggedUser.clientName + "::updated-ticket",
          (data) => {
            console.log("updated:ticket", data);
            this.reCompute();
            if (
              data.data.id === this.ticket?.id &&
              !this.ticket.operator_id &&
              this.ticket.operator_id !== this.loggedUser.id
            ) {
              this.closeChat();
              console.log("updated:ticket in here");
              this.reCompute();
            }
            this.$forceUpdate();
            this.reCompute();
          }
        );

        let url = this.loggedUser.clientName + "::new-ticket";
        console.log("url::", url);
        this.socket.on(this.loggedUser.clientName + "::new-ticket", (data) => {
          //atualizar o ticket
          console.log("ticket adicioinado", data);
          this.SOCKET_NEW_TICKET(data.data);
          this.reCompute();
        });
      }, 3000);
      this.$event.$on("start_loading_msgs_in_chat", () => {
        if (this.selectedTicketMessages.length) {
          this.startFormatingMessages();
          this.openChat = true;
          this.scrollLastMessage();
        }
      });
    },
    methods: {
      ...mapActions([
        "CLOSE_MESSAGES",
        "CLOSE_TICKET",
        "SOCKET__UPDATE_MESSAGES",
        "NEW_TICKET",
        "SEND_MESSAGE",
        "SOCKET__MESSAGE",
        "UPDATE_TICKET",
        "CLOSE_THIS_TICKETS",
        "SOCKET_NEW_TICKET",
      ]),
      startTicket() {
        let data = {
          operator_id: this.loggedUser.id,
          id: this.ticket.id,
          isGoingToStart: true,
        };
        this.UPDATE_TICKET(data).then(() => {
          this.$forceUpdate();
        });
      },
      sendMessage() {
        let data = {
          ticket: this.ticket,
          contact: this.getReceiver(),
          message: this.textMessage,
        };
        console.log("enviando pro socket", data);
        // this.socket.emit("chat-new-message", data);
        // this.socket.emit("send-new-message", data);

        this.SEND_MESSAGE(data);
        this.textMessage = null;
      },
      scrollLastMessage() {
        setTimeout(() => {
          let element = document.getElementById("bottom_container");
          console.log("element is::", element);
          // element.scrollIntoView();
          element.scrollIntoView({
            block: "end",
            behavior: "smooth",
            inline: "end",
          });
        }, 100);
      },
      search(val) {
        let contacts = this.onlyWhoHasClosedTicket;
        console.log("closed ones", contacts);
        if (val) {
          this.optionsList = contacts.filter((con) => {
            return (
              con.name.toLowerCase().includes(val.toLowerCase()) ||
              (con.cpf && con.cpf.includes(val)) ||
              con.number.includes(val)
            );
          });
        } else {
          this.optionsList = contacts;
        }
      },
      getReceiver() {
        // console.log("this.ticket ", this.ticket);
        let contact = this.contatos.find((contact) => {
          return contact.id === this.ticket.patient_id;
        });
        // console.log("this.contatos ", this.contatos);
        return contact;
      },
      closeChat() {
        this.openChat = false;
        this.loadedMessagesFrom_ticket = [];
        this.CLOSE_TICKET();
        this.CLOSE_MESSAGES();
      },
      startNewConversation() {
        setTimeout(() => {
          this.$refs.cta_img.classList.add("slide-out-top");
          this.$refs.cta_desc.classList.add("slide-out-right");
          this.$refs.cta_btn.classList.add("slide-out-bottom");
          //        this.startingNewTicket = true;
        }, 100);
        setTimeout(() => {
          // this.$refs.cta_img.classList.remove("slide-out-top");
          // this.$refs.cta_desc.classList.remove("slide-out-right");
          // this.$refs.cta_btn.classList.remove("slide-out-bottom");
          this.startingNewTicket = true;
        }, 1000);
      },
      selectPatient(val) {
        // console.log("val", val);
        // open a new ticket
        //create action
        if (val) {
          this.openTicketData.patient_id = val.id;
        }
      },
      formating(msg) {
        var match = /\n/.exec(msg);
        let y = msg.split("\n");
        let z = "";
        let lastWordIndex = y.length - 1;
        if (match) {
          y.forEach((word, index) => {
            // let newText = z;
            if (index !== lastWordIndex) {
              z += word + "<br/>";
            } else {
              z += word;
            }
          });
          return z;
        } else {
          return msg;
        }
      },
      render(relEl, tpl, parse = true) {
        if (!relEl) return;
        const range = document.createRange();
        range.selectNode(relEl);
        const child = range.createContextualFragment(tpl);
        return parse ? relEl.appendChild(child) : { relEl, el };
      },
      async formatMessage(msgId, msg) {
        var element = document.getElementById("msg_" + msgId);

        // var element = this.$refs["msg_" + msgId];
        // console.log("msg in formatMessage", msg);

        // element.innerHTML = msg;
        await this.render(element, msg);
      },
      async startFormatingMessages() {
        this.isLoadingMsgs = true;
        for await (let msg of this.selectedTicketMessages) {
          console.log("msg loop", msg.msg);
          await this.formatMessage(msg.id, msg.msg);
        }
        setTimeout(() => {
          this.loadedMsg = true;
          this.isLoadingMsgs = false;
        }, 2000);
      },
      transfer() {
        this.$event.$emit("toggleTransferTicket");
      },
      openTicket() {
        this.NEW_TICKET(this.selectedPatient);
        this.selectedPatient = {};
      },
      closeTicket() {
        // CLOSE_THIS_TICKETS
        this.CLOSE_THIS_TICKETS(this.ticket);
        this.closeChat();
      },
      reCompute() {
        this.UPDATESOCKET = this.UPDATESOCKET + 1;
      },
    },
  };
</script>

<style lang="scss">
  .conversation-container {
    position: relative;
    background: #efe7dd
      url("https://cloud.githubusercontent.com/assets/398893/15136779/4e765036-1639-11e6-9201-67e728e86f39.jpg")
      repeat;
    max-height: calc(100% - 72px);
    min-height: 90%;
    overflow-x: hidden;
    overflow-y: auto;
    &::-webkit-scrollbar {
      width: 8px; /* width of the entire scrollbar */
    }
    &::-webkit-scrollbar-thumb {
      background-color: rgb(204, 204, 204); /* color of the scroll thumb */
      border-radius: 20px; /* roundness of the scroll thumb */
      /* // border: 1px solid rgb(235, 235, 235); creates padding around scroll thumb */
    }
  }
</style>
