<template>
  <div class="card" v-if="loaded">
    <div class="card-body">
      <div class="card-title d-flex justify-content-between">
        <strong>Informações</strong>
        <button class="btn btn-primary" @click="saveInformation()">
          Salvar
        </button>
      </div>
      <h6 class="card-subtitle">
        Os dados aqui são utilizados no sistema como parametros de comunicação.
      </h6>
      <form>
        <div class="form-row">
          <div class="form-group col-md-4">
            <label for="company">Nome da empresa</label>
            <input
              v-model="company.name"
              type="text"
              class="form-control"
              id="company"
            />
          </div>
          <div class="form-group col-md-2">
            <label for="contact">Contato fixo</label>
            <input
              v-model="company.contact"
              type="text"
              class="form-control"
              id="contact"
            />
          </div>
          <div class="form-group col-md-3">
            <label for="email">Email</label>
            <input
              v-model="company.email"
              type="email"
              class="form-control"
              id="email"
            />
          </div>
          <div class="form-group col-md-3">
            <label for="site">Site</label>
            <input
              v-model="company.site"
              type="text"
              class="form-control"
              id="site"
            />
          </div>
        </div>

        <div class="form-row">
          <div class="form-group col-md-2">
            <label for="inputZip">CEP</label>
            <input
              v-model="company.address.cep"
              type="text"
              class="form-control"
              id="inputZip"
            />
          </div>
          <div class="form-group col-md-4">
            <label for="address">Endereço</label>
            <input
              v-model="company.address.street"
              type="text"
              class="form-control"
              id="address"
            />
          </div>
          <div class="form-group col-md-1">
            <label for="add_num">Numero</label>
            <input
              v-model="company.address.building_number"
              type="text"
              class="form-control"
              id="add_num"
            />
          </div>

          <div class="form-group col-md-2">
            <label for="inputState">Estado</label>
            <multiselect
              id="state"
              v-model="selectedUF"
              :options="UFS"
              :show-labels="false"
            >
            </multiselect>
          </div>
          <div class="form-group col-md-3">
            <label for="city">Cidade</label>
            <multiselect
              :disabled="!company.address.state"
              id="city"
              v-model="company.address.city"
              :options="CITIES"
              :show-labels="false"
            >
            </multiselect>
          </div>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
  import { mapActions, mapGetters } from "vuex";
  import axios from "../../http";
  import Multiselect from "vue-multiselect";
  import ufCidades from "../../local_db/estado_cidades";
  export default {
    name: "Info",
    components: {
      Multiselect,
    },
    data() {
      return {
        loaded: false,
        UFS: [],
        CITIES: [],
        selectedUF: "",
        company: {
          name: "",
          contact: "",
          email: "",
          site: "",
          address: {
            cep: "",
            street: null,
            building_number: null,
            city: null,
            state: null,
          },
        },
      };
    },
    computed: {
      ...mapGetters(["information"]),
    },
    mounted() {
      this.getUF();
      setTimeout(() => {
        console.log(
          "the information",
          this.$masterHelper.isEmpty(this.information)
        );
        if (!this.$masterHelper.isEmpty(this.information)) {
          this.company = this.information;
          if (this.company.address.state) {
            this.selectedUF = this.company.address.state;
          }
        }
      }, 1000);

      setTimeout(() => {
        this.loaded = true;
      }, 900);
    },
    watch: {
      selectedUF: function(val) {
        this.company.address.state = val;
        this.getCitiesByUF();
      },
    },
    methods: {
      ...mapActions(["SAVE_INFO", "UPDATE_INFO", "LOAD_INFO"]),
      getUF() {
        this.UFS = ufCidades.estados.map((states) => states.sigla);
      },
      getCitiesByUF() {
        let state = ufCidades.estados.find((uf) => {
          return (
            uf.sigla.toLowerCase() === this.company.address.state.toLowerCase()
          );
        });
        this.CITIES = state.cidades;
      },
      saveInformation() {
        if (this.$masterHelper.isEmpty(this.information)) {
          // save
          console.log("saving....");
          this.SAVE_INFO(this.company);
        } else {
          // update
          console.log("updating....");
          this.UPDATE_INFO(this.company);
        }
      },
    },
  };
</script>

<style></style>
