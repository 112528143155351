import Vue from "vue";
import VueRouter from "vue-router";
import Layout from "../views/Layout.vue";

import Settings from "../views/settings/Settings.vue";
import Info from "../components/settings/Info";
import Connection from "../components/settings/MultiConnection";
import Atendents from "../components/settings/Atendents";
import Bot from "../components/settings/Bot";
import Bots from "../components/settings/Bots";
import Security from "../components/settings/Security";

import Chat from "../views/Chat";
import NewChat from "../views/NewChat";

// import Campaign from "../views/CampaignOficial";
import Campaign from "../views/venom_2/Campaign";
import CampaignDashboard from "../views/venom_2/campaign/Index";
import Tickets from "../views/Tickets";

import Patient from "../views/Patient";
import NewPatient from "../views/NewPatient";

import Users from "../components/settings/Users";

import Appointment from "../views/Appointment_fixed_endpoint";
import Dashboard from "../views/Index";

// import Appointment from "../views/Appointment";
import Login from "../views/Login";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "Layout",
    component: Layout,
    children: [
      {
        path: "",
        name: "index",
        component: Dashboard,
      },
      {
        path: "chat",
        name: "chat",
        component: NewChat,
      },
      {
        path: "tickets",
        name: "tickets",
        component: Tickets,
      },
      {
        path: "campaign",
        name: "campaign",
        component: Campaign,
      },
      {
        path: "campaign_report",
        name: "campaign_report",
        component: CampaignDashboard,
      },
      {
        path: "patients",
        name: "patient",
        component: Patient,
      },
      {
        path: "new_patient",
        name: "new_patient",
        component: NewPatient,
      },
      {
        path: "appointment",
        name: "appointment",
        component: Appointment,
      },
      {
        path: "settings",
        name: "settings",
        component: Settings,
        children: [
          {
            path: "/",
            name: "info",
            component: Info,
          },
          {
            path: "connection",
            name: "connection",
            component: Connection,
          },
          {
            path: "atendents",
            name: "atendents",
            component: Atendents,
          },
          {
            path: "bots",
            name: "bots",
            component: Bots,
          },
          {
            path: "new_bot",
            name: "new_bot",
            component: Bot,
          },
          {
            path: "bot/:id",
            name: "bot",
            component: Bot,
          },
          {
            path: "security",
            name: "security",
            component: Security,
          },
          {
            path: "users",
            name: "users",
            component: Users,
          },
        ],
      },
    ],
  },
  {
    path: "/login",
    name: "login",
    component: Login,
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

// router.beforeEach((to, from) => {
//   // ...
//   // explicitly return false to cancel the navigation
//   return false;
// });

export default router;
