import Vue from "vue";
import Vuex from "vuex";

import axios from "../../http";
Vue.use(Vuex);

export default {
  state: {
    selectedContacts: [],
    campaigns: [],
    transmitions: [],
    transmition: {},
    transmition_contacts: [],
  },
  getters: {
    selectedContacts: (state) => state.selectedContacts,
    campaigns: (state) => state.campaigns,
    transmitions: (state) => state.transmitions,
    transmition: (state) => state.transmition,
    transmition_contacts: (state) => state.transmition_contacts,
  },
  mutations: {
    SELECT_CONTACT(state, contato) {
      let inSelected = state.selectedContacts.find((contact) => {
        return contact.id === contato.id;
      });
      if (!inSelected) {
        state.selectedContacts.push(contato);
      }
    },
    REMOVE_CONTACT(state, id) {
      let index = state.selectedContacts
        .map((contact) => contact.id)
        .indexOf(id);
      state.selectedContacts.splice(index, 1);
    },
    REMOVE_ALL_CONTACT(state) {
      state.selectedContacts = [];
    },
    SELECT_ALL_CONTACT(state, payload) {
      state.selectedContacts = payload;
    },
    LOAD_CAMPAIGN(state, payload) {
      state.campaigns = payload;
    },
    PUSH_NEW_TRANSMITION(state, message) {
      state.campaigns.unshift(message);
    },
    SET_TRANSMITIONS(state, transmitions) {
      state.transmitions = transmitions;
    },
    UPDATE_TRANSMITION(state, transmition) {
      let index = state.transmitions.findIndex((trans) => {
        return trans.id === transmition.id;
      });

      if (index != -1) {
        state.transmitions[index] = transmition;
      } else {
        state.transmitions.push(transmition);
      }
    },
    SET_TRANSMITION(state, transmition) {
      state.transmition = transmition;
    },
    SET_TRANSMITION_CONTACTS(state, transmition_contacts) {
      transmition_contacts.forEach((element) => {
        state.transmition_contacts.push(element);
      });
    },
    // clear state
    CLEAR_TRANSMITION_CONTACTS(state) {
      state.transmition_contacts = [];
    },
    UPDATE_TRANSMITION_CONTACT(state, payload) {
      let index = state.transmition_contacts.findIndex((state_trans_con) => {
        return (
          state_trans_con.patient_id === payload.patient_id &&
          state_trans_con.transmition_id === payload.transmition_id
        );
      });
      state.transmition_contacts[index] = payload;
    },
  },

  actions: {
    CLEAR_STATE: (context, payload) => {
      context.commit(payload.mutation);
    },
    SET_CAMPAIGN: (context, payload) => {
      axios()
        .post("message_transmition", payload)
        .then(function(response) {
          console.log("a transmissão foi realizada");
          return true;
        })
        .catch(function(error) {
          console.log(error);
        });
    },
    SET_TRANSMITION_CONTACTS: async ({ commit, getters }, transmition_id) => {
      try {
        //check if exists
        //must contain at least one of the ids in the array
        //if it contains, probably the cliente already clicked to load it
        let contains = getters.transmition_contacts.some((st) => {
          return st.transmition_id === transmition_id;
        });
        if (!contains) {
          let response = await axios().get(
            "transmition_contacts/" + transmition_id
          );
          commit("SET_TRANSMITION_CONTACTS", response.data);
          return response.data;
        }
      } catch (error) {
        console.log("SET_TRANSMITION_CONTACTS::error", error);
        return error;
      }
    },
    SELECT_CONTACT: (context, payload) => {
      context.commit("SELECT_CONTACT", payload);
    },
    SET_TRANSMITION: (context, transmition) => {
      context.commit("SET_TRANSMITION", transmition);
    },
    REMOVE_CONTACT: (context, id) => {
      context.commit("REMOVE_CONTACT", id);
    },
    REMOVE_ALL_CONTACT: (context) => {
      context.commit("REMOVE_ALL_CONTACT");
    },
    SELECT_ALL_CONTACT: (context, payload) => {
      context.commit("SELECT_ALL_CONTACT", payload);
    },
    LOAD_CAMPAIGNS: async (context) => {
      try {
        const response = await axios().get("message_transmition");
        var campaigns = await response.data.filter((campaign) => {
          return campaign.reusable === 1;
        });
        console.log("as campanhas foram carregada");
        context.commit("LOAD_CAMPAIGN", campaigns);
        return response.data;
      } catch (error) {
        console.log(error);
      }

      // axios()
      //   .get("message_transmition")
      //   .then(function(response) {
      //     var campaigns = response.data.filter((campaign) => {
      //       return campaign.reusable === 1;
      //     });
      //     context.commit("LOAD_CAMPAIGN", response.data);
      //   })
      //   .catch(function(error) {
      //     console.log(error);
      //   });
    },
    //deprecated
    SAVE_CAMPAIGN: async (context, payload) => {
      try {
        const response = await axios().post("save_campaign", payload);
        console.log("a campanha foi salva");
        context.commit("PUSH_NEW_TRANSMITION", response.data);
        return response.data;
      } catch (error) {
        console.log(error);
      }
    },

    // ======================= refactoring ================================
    N_SET_CAMPAIGN: async (context, payload) => {
      try {
        const response = await axios().post("message_transmition", payload);
        context.commit("PUSH_NEW_TRANSMITION", response.data);
        console.log("action::N_SET_CAMPAIGN::response", response.data);
        return response;
      } catch (error) {
        console.log("action::N_SET_CAMPAIGN::error", error);
      }
    },
    N_LOAD_CAMPAIGN: async (context, payload) => {
      try {
        const response = await axios().get("message_transmition");
        context.commit("LOAD_CAMPAIGN", response.data);
        console.log("action::N_LOAD_CAMPAIGN::response", response.data);
        return response.data;
      } catch (error) {}
    },
    START_TRANSMITION: async (content, payload) => {
      try {
        const response = await axios().post("start_transmition", payload);
      } catch (error) {
        console.log("START_TRANSMITION::error", error);
      }
    },
    LOAD_REPORTS: async (context, payload) => {
      try {
        const response = await axios().get("transmition_reports");
        context.commit("SET_TRANSMITIONS", response.data);
        return response.data;
      } catch (error) {
        return console.error("LOAD_REPORTS::", error);
      }
    },
    UPDATE_TRANSMITION: (context, payload) => {
      context.commit("UPDATE_TRANSMITION", payload);
    },
    UPDATE_TRANSMITION_CONTACT: (context, payload) => {
      context.commit("UPDATE_TRANSMITION_CONTACT", payload);
    },
    RESEND_TRANSMITION: async (context, payload) => {
      try {
        await axios().post("transmition_exeption", payload);
        return true;
      } catch (error) {
        console.log("RESEND_TRANSMITION::error", error);
      }
    },
  },
};
