<template>
  <div class="row" style="width:100%" v-has:access.onload="'manage-settings'">
    <!-- <div class="row" style="width:100%"> -->
    <div class="col-12 col-xl-3 settings-aside">
      <ul class="list-group ">
        <li
          class="list-group-item"
          :class="[currentPage.includes('info') ? activeClass : '']"
          @click="$router.push({ name: 'info' })"
        >
          <i class="far fa-address-card"></i>
          <span class="ml-2">Informações</span>
        </li>
        <li
          class="list-group-item"
          :class="[currentPage.includes('connection') ? activeClass : '']"
          @click="$router.push({ name: 'connection' })"
        >
          <i class="fas fa-qrcode"></i>
          <span class="ml-2"> Conexões</span>
        </li>
        <!-- <li
          class="list-group-item "
          :class="[currentPage.includes('atendents') ? activeClass : '']"
          @click="$router.push({ name: 'atendents' })"
        >
          Atendente
        </li> -->
        <!-- <li
          class="list-group-item "
          @click="$router.push({ name: 'inconnectionfo' })"
        >
          Departamento
        </li> -->
        <li
          class="list-group-item "
          :class="[currentPage.includes('bot') ? activeClass : '']"
          @click="$router.push({ name: 'bots' })"
        >
          <i class="fas fa-robot"></i>
          <span class="ml-2">Bot</span>
        </li>
        <li
          class="list-group-item "
          :class="[currentPage.includes('security') ? activeClass : '']"
          @click="$router.push({ name: 'security' })"
        >
          <i class="fas fa-shield-alt"></i>
          <span class="ml-2">Cargos</span>
        </li>
        <li
          class="list-group-item "
          :class="[currentPage.includes('users') ? activeClass : '']"
          @click="$router.push({ name: 'users' })"
        >
          <i class="fas fa-users"></i>
          <span class="ml-2">Usuarios</span>
        </li>
      </ul>
    </div>
    <div class="col-12 col-xl-9 mt-2 mt-xl-0">
      <transition name="slide-in" mode="out-in">
        <router-view />
      </transition>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      activeClass: "active",
    };
  },
  computed: {
    currentPage() {
      return this.$route.name;
    },
  },
  mounted() {
    console.log(this.$router);
  },
};
</script>

<style lang="scss">
.settings-aside {
  ul > li:hover {
    cursor: pointer;
    font-weight: 400;
    /* border-radius: 0px 60px 60px 0px; */
    color: #fff !important;
    background: linear-gradient(
      to right,
      #8971ea,
      #7f72ea,
      #7574ea,
      #6a75e9,
      #5f76e8
    );
    box-shadow: 0px 7px 12px 0px rgba(95, 118, 232, 0.21);
    opacity: 1;
  }
  .active {
    color: #fff !important;
    background: linear-gradient(
      to right,
      #8971ea,
      #7f72ea,
      #7574ea,
      #6a75e9,
      #5f76e8
    );
  }
}
</style>
