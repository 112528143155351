import Vue from "vue";
import Vuex from "vuex";

// import axios from "axios";
import axios from "../../http";
Vue.use(Vuex);
// Vue.use(router);

export default {
  state: {
    bot: {},
    bots: [],
    selectedAction_Function: {},
  },
  getters: {
    bot: (state) => state.bot,
    bots: (state) => state.bots,
    selectedAction_Function: (state) => state.selectedAction_Function,
  },
  mutations: {
    SET_FUNCTION(state, payload) {
      state.selectedAction_Function = payload;
    },
    SET_BOT(state, payload) {
      state.bot = payload;
    },
    LOAD_BOTS(state, payload) {
      state.bots = payload;
    },
    UPDATE_BOT_DEFAULT(state, payload) {
      let index = state.bots.findIndex((bot) => {
        return bot.id === payload.id;
      });
      state.bots[index] = payload;
    },
  },

  actions: {
    SELECT_FUNCTION: (context, payload) => {
      context.commit("SET_FUNCTION", payload);
    },
    SAVE_BOT: async (context, payload) => {
      try {
        const response = await axios().post("bots", payload);
        context.commit("SET_BOT", response.data);
        return response.data;
      } catch (error) {
        console.log(error);
      }
    },
    LOAD_BOTS: async (context) => {
      try {
        const response = await axios().get("bots");
        context.commit("LOAD_BOTS", response.data);
        return response.data;
      } catch (error) {
        console.log(error);
      }
    },
    GET_BOT: async (context, payload) => {
      try {
        const response = await axios().get(`bot/${payload}/details`);
        context.commit("SET_BOT", response.data);
        return response.data;
      } catch (error) {
        console.log(error);
      }
    },
    UPDATE_BOT: async (context, payload) => {
      try {
        const response = await axios().post("bot_update", payload);
        context.commit("SET_BOT", response.data);
        return response.data;
      } catch (error) {
        console.log(error);
      }
    },
    DEFAULT_OFF: async (context, payload) => {
      try {
        const response = await axios().post("bot_update_default", payload);
        context.commit("UPDATE_BOT_DEFAULT", response.data);
        return response.data;
      } catch (error) {
        console.log(error);
      }
    },
  },
};
