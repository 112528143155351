import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import { masterHelper } from "./helpers/Master.js";
import PortalVue from "portal-vue";
import ToggleButton from "vue-js-toggle-button";
import VueIziToast from "vue-izitoast";
import config from "../src/APP_CONFIG.json";
import bus from "../src/helpers/eventBus";

import "popper.js";
import "bootstrap";
import "./assets/libs/perfect-scrollbar/dist/perfect-scrollbar.jquery.min.js";
// import "./assets/dist/js/sidebarmenu.js";
import "./assets/dist/js/custom.js";

import { Base64 } from "js-base64";

import "./assets/dist/animations.css";
import "./assets/dist/css/icons/font-awesome/css/fontawesome-all.css";

import "./assets/dist/css/style.css";
import "vue-multiselect/dist/vue-multiselect.min.css";
import "izitoast/dist/css/iziToast.min.css";

// dayjs

import dayjs from "dayjs";
import "dayjs/locale/pt-br";
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone"; // dependent on utc plugin
dayjs.extend(utc);
dayjs.extend(timezone);
const _dayjs = dayjs;

// import $ from "jquery";
import JQuery from "jquery";
window.$ = JQuery;
import "./directives/index";

import socket from "./socket/index";

//Styles
// src\assets\styles\animations.scss
import "./assets/styles/animations.scss";
Vue.use(PortalVue);

// =====Toggle switch=======
// https://github.com/euvl/vue-js-toggle-button
Vue.use(ToggleButton);
//=================
//======iziToast========
// https://github.com/arthurvasconcelos/vue-izitoast
Vue.use(VueIziToast);
//=================
Vue.prototype.$config = config;

Vue.prototype.$Base64 = Base64;
Vue.prototype.$version = "v2";

Vue.prototype.$event = bus;
Vue.prototype.$whatsappReceptiveOnly = false;
Vue.prototype.$canUseDepartments = false;

Vue.prototype.$masterHelper = masterHelper;

Vue.prototype.$socket = socket;
_dayjs().tz("America/Sao_Paulo");
Vue.prototype.$date = (d) => _dayjs(d).format("DD/MM/YYYY [às] HH:MM");

Vue.config.productionTip = false;

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");
