export const masterHelper = {
  compare_arrays: function(a1, a2) {
    var a = [];
    var diff = [];
    for (var i = 0; i < a1.length; i++) {
      a[a1[i]] = true;
    }

    for (var j = 0; j < a2.length; j++) {
      if (a[a2[j]]) {
        delete a[a2[j]];
      } else {
        a[a2[j]] = true;
      }
    }

    for (var k in a) {
      diff.push(k);
    }

    return diff;
  },
  isEmpty: function(obj) {
    for (var prop in obj) {
      if (obj.hasOwnProperty(prop)) return false;
    }

    return true;
  },
  formatOptionsFromBotInChat(text) {
    let txt = this.formatTostrong(text);
    if (txt.includes("|")) {
      txt = txt.replace("|", "<br>");
    }
    return txt;
  },
  formatTostrong(text) {
    // console.log("incoming", text);
    // var regex = /([^*]*(?:\*(?!\*)[^*]*)*)/g;
    // var replacement = "<strong>$1</strong>";
    // console.log("incoming", text.replace(regex, replacement));
    // return text.replace(regex, replacement);
    let newText = "";
    let count = 0;
    let makeStrong = /\*/g,
      match;
    while ((match = makeStrong.exec(text))) {
      if (match.index && match.index > -1) {
        count++;

        console.log(match.index);
        if (count % 2 != 0) {
          // text.replace(text[match.index], "<strong>");
          text =
            text.substring(0, match.index) +
            "<strong>" +
            text.substring(match.index + 1);
        } else {
          // text.replace(text[match.index], "</strong>");
          text =
            text.substring(0, match.index) +
            "</strong>" +
            text.substring(match.index + 1);
        }
        // console.log("find index 54", text[54]);
        // console.log("find index 80", text[80]);
      }
    }
    console.log("text-----", text);
    return text;
  },
  hasPermission(user, value) {
    console.log("user.roles", user);
    var hasIt = user.roles[0].permissions.some((permission) => {
      return permission.name === value;
    });
    return hasIt;
  },
  add_minutes(dt, minutes) {
    let time = new Date(dt.getTime() + minutes * 60000);
    return time;
  },
  setCookie(c_name, value, min) {
    try {
      let exdate = this.add_minutes(new Date(), min).toString();
      console.log("exdate", exdate);
      let exdateUtc = new Date(exdate).toUTCString();
      console.log("formated utc exdateUtc", exdateUtc);
      localStorage.setItem("eTTL", exdateUtc);
      let c_value = `${c_name}=${value}; expires=${exdateUtc}`;
      console.log("value of cookie:", c_value);
      document.cookie = c_value;
    } catch (error) {
      console.log("error setCookie", error);
    }
  },

  getCookie(c_name) {
    return document.cookie.split(";");
    // var i,
    //   x,
    //   y,
    //   ARRcookies = document.cookie.split(";");
    // for (i = 0; i < ARRcookies.length; i++) {
    //   x = ARRcookies[i].substr(0, ARRcookies[i].indexOf("="));
    //   y = ARRcookies[i].substr(ARRcookies[i].indexOf("=") + 1);
    //   x = x.replace(/^\s+|\s+$/g, "");
    //   if (x == c_name) {
    //     return unescape(y);
    //   }
    // }
  },
  sameObject(obj1, obj2) {
    return SON.stringify(obj1) === JSON.stringify(obj2);
  },
  /**
   * Gets the last index from the given array.
   * @function
   * @param {array} arr - the chosen array.
   */
  arrLastIndex(arr) {
    return arr.length - 1;
  },

  _imageEncode(response) {
    let image = btoa(
      new Uint8Array(response.data).reduce(
        (data, byte) => data + String.fromCharCode(byte),
        ""
      )
    );
    return `data:${response.headers[
      "content-type"
    ].toLowerCase()};base64,${image}`;
  },
};
