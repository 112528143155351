<template>
  <div class="col-lg-3" v-if="!hasSelectedTicket">
    <div class="card-header">
      <ul class="nav nav-tabs" id="myTab" role="tablist">
        <li class="nav-item" role="presentation">
          <a
            class="nav-link active"
            id="info-tab"
            data-toggle="tab"
            href="#info"
            role="tab"
            aria-controls="info"
            aria-selected="true"
            >Informações</a
          >
        </li>
        <li class="nav-item" role="presentation">
          <a
            class="nav-link"
            id="tickets-tab"
            data-toggle="tab"
            href="#tickets"
            role="tab"
            aria-controls="tickets"
            aria-selected="false"
            @click="loadContactTickets()"
            >Tickets</a
          >
        </li>
      </ul>
    </div>
    <div class="tab-content" id="myTabContent">
      <div
        class="tab-pane fade show active"
        id="info"
        role="tabpanel"
        aria-labelledby="info-tab"
      >
        <div class="card-body">
          <div class="inner-info-patient">
            <div class="form-group">
              <label for="name">Nome</label>
              <input
                type="text"
                class="form-control"
                id="name"
                aria-describedby="name"
                :disabled="!isEditingPatient"
                v-model="patient.name"
              />
            </div>
            <div class="form-group">
              <label for="cpf">CPF</label>
              <input
                type="text"
                class="form-control"
                id="cpf"
                aria-describedby="cpf"
                :disabled="!isEditingPatient"
                v-model="patient.cpf"
              />
            </div>
            <div class="form-group">
              <label for="number">Número</label>
              <input
                type="text"
                class="form-control"
                id="number"
                :disabled="!isEditingPatient"
                v-model="patient.number"
              />
            </div>
            <div class="d-flex justify-content-end mt-2">
              <button
                class="btn btn-warning"
                v-if="!isEditingPatient"
                @click="edit()"
              >
                Editar
              </button>
              <button
                class="btn btn-default"
                v-if="isEditingPatient"
                @click="edit()"
              >
                Cancelar
              </button>
              <button class="btn btn-primary ml-2" v-if="isEditingPatient">
                Salvar
              </button>
            </div>
          </div>
        </div>
      </div>
      <div
        class="tab-pane fade"
        id="tickets"
        role="tabpanel"
        aria-labelledby="tickets-tab"
      >
        <div class="card-body">
          <div class="inner-info-patient">
            <ul class="list-group old-tickets-container">
              <!-- <div v-if="myTickets.length == this.patient.tickets_count"> -->
              <li
                class="list-group-item "
                v-for="p_ticket in patientTickets"
                :key="p_ticket.id"
              >
                <div class="d-flex justify-content-between">
                  <div>Ticket {{ p_ticket.id }}</div>
                  <button
                    v-show="loadSelectedTicket !== p_ticket.id"
                    @click="loadTicketConversation(p_ticket.id)"
                    class="btn"
                  >
                    <i class="fas fa-history"></i>
                  </button>
                  <div
                    v-show="loadSelectedTicket == p_ticket.id && !isLoadingMsgs"
                  >
                    <button
                      class="btn"
                      @click="isSearchingInOldTicktes()"
                      style="display:none"
                    >
                      <i class="fas fa-search"></i>
                    </button>
                    <button
                      @click="loadTicketConversation(p_ticket.id)"
                      class="btn"
                    >
                      <i class="fas fa-times"></i>
                    </button>
                  </div>
                </div>
                <div class=" mt-2" v-if="loadSelectedTicket == p_ticket.id">
                  <span
                    v-if="isLoadingMsgs"
                    class="d-flex flex-column  justify-content-center align-items-center"
                  >
                    <div class="spinner-border text-primary" role="status">
                      <span class="sr-only">Loading...</span>
                    </div>
                    <span class="mt-2">Carregando mensagens ...</span>
                  </span>
                  <div
                    class="p-2"
                    v-if="isSearchingInClosedTicketMsg"
                    style="display:none"
                  >
                    <input
                      type="text"
                      placeholder="pesquisar"
                      class="form-control"
                      v-model="search"
                      @keyup.enter="searchInOlds()"
                    />
                    <small>encontrei {{ searchedId.length }}</small>
                    <button
                      class="btn btn-primary"
                      @click="navigateInSearch('prev')"
                    >
                      prev
                    </button>
                    <button
                      class="btn btn-primary"
                      @click="navigateInSearch('next')"
                    >
                      next
                    </button>
                  </div>
                  <div
                    id="old-msg-container"
                    class="d-flex flex-column ticket-message-bg scroll-message-ticket-container"
                    v-if="!isLoadingMsgs"
                  >
                    <MessageBox
                      :id="'old-msg-' + t_msg.id"
                      class="m-3"
                      v-for="t_msg in selectedMsgsLoaded"
                      :key="t_msg.id"
                      :data="t_msg"
                      :display-name="true"
                    />
                  </div>
                </div>
              </li>
              <!-- </div> -->
              <!-- <li v-if="myTickets().length">Sem tickets anteriores</li> -->
              <li
                class="list-group-item"
                v-if="!patientTickets.length && contact.tickets_count > 1"
              >
                Carregando, aguarde ...
              </li>
              <li class="list-group-item" v-if="contact.tickets_count == 0">
                Nenhum atendimento anterior
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import { mapActions, mapGetters } from "vuex";
  import MessageBox from "@/components/chat/MessageBox";
  // import $ from "jquery";
  export default {
    data() {
      return {
        search: null,
        searchIndex: 0,
        searchedId: [],
        isEditingPatient: false,
        viewing: "info",
        patient: {
          id: null,
          name: null,
          cpf: null,
          numero: null,
        },
        conversations: [],
        isLoadingMsgs: true,
        selectedMsgsLoaded: [],
        loadSelectedTicket: 0,
        isSearchingInClosedTicketMsg: false,
      };
    },
    components: {
      MessageBox,
    },
    computed: {
      ...mapGetters(["ticket", "contact", "tickets", "messages"]),
      hasSelectedTicket() {
        return this.$masterHelper.isEmpty(this.ticket);
      },
      patientTickets() {
        let tickets = this.tickets;
        let oldTickets = tickets.filter((ticket) => {
          return (
            ticket.closed_at !== null && ticket.patient_id === this.patient.id
          );
        });
        return oldTickets;
      },
      myTickets() {
        return this.tickets.filter((ticket) => {
          return ticket.patient_id === this.ticket.patient_id;
        });
      },
    },
    mounted() {
      this.$event.$on("start_loading_msgs_in_chat", () => {
        this.patient = {
          id: null,
          name: null,
          cpf: null,
          number: null,
        };
        this.patient = this.contact;
        $("#myTab a[href='#info']").tab("show");
        this.selectTab("info");
        this.$forceUpdate();
      });
    },
    created() {},
    methods: {
      ...mapActions(["CONTACT_TICKETS"]),
      edit() {
        this.isEditingPatient = !this.isEditingPatient;
      },
      selectTab(what) {
        this.viewing = what;
      },
      loadTicketConversation(ticket_id) {
        this.isSearchingInClosedTicketMsg = false;
        this.isLoadingMsgs = true;
        if (this.loadSelectedTicket !== ticket_id) {
          this.loadSelectedTicket = ticket_id;
        } else {
          this.loadSelectedTicket = ticket_id;
          this.loadSelectedTicket = null;
          this.isLoadingMsgs = false;
        }

        let selectedMessages = this.messages.filter((msg) => {
          return msg.ticket_id === ticket_id;
        });
        console.log("msg --- loop ticket_id", ticket_id);
        console.log("msg --- loop selectedMessages", selectedMessages);

        for (let sMesg of selectedMessages) {
          // console.log("msg loop", msg.msg);
          let hasIt = this.conversations.some((con) => {
            return con.id === sMesg.id;
          });
          if (!hasIt) {
            console.log("nope");
            this.conversations.push(sMesg);
            this.getMessages(ticket_id);
          } else {
            this.getMessages(ticket_id);
          }
        }
      },
      async getMessages(ticket_id) {
        let allMsg = this.conversations.filter((cons) => {
          return cons.ticket_id === ticket_id;
        });
        this.selectedMsgsLoaded = await allMsg;
        setTimeout(() => {
          this.isLoadingMsgs = false;
        }, 2000);
      },
      loadContactTickets() {
        let totalTickets = this.patient.tickets_count;
        let ticketsLength = this.tickets.filter((ticket) => {
          return ticket.patient_id === this.ticket.patient_id;
        }).length;
        if (ticketsLength !== totalTickets) {
          //buscar os tickets do caboclo
          this.CONTACT_TICKETS(this.patient.id);
        }
      },
    },
  };
</script>

<style lang="scss">
  .ticket-message-bg {
    background-color: rgb(207, 207, 207) !important;
  }
  .scroll-message-ticket-container {
    max-height: 30vh;
    min-height: 1vh;
    overflow-x: hidden;
    overflow-y: auto;
    &::-webkit-scrollbar {
      width: 8px; /* width of the entire scrollbar */
    }
    &::-webkit-scrollbar-thumb {
      background-color: rgb(65, 65, 65); /* color of the scroll thumb */
      border-radius: 20px; /* roundness of the scroll thumb */
      /* // border: 1px solid rgb(235, 235, 235); creates padding around scroll thumb */
    }
  }
  .found-olds {
    background-color: rgb(255, 200, 129) !important;
  }
  .old-tickets-container {
    height: 75vh;
    overflow-x: unset;
    overflow-y: scroll;
    &::-webkit-scrollbar {
      width: 8px; /* width of the entire scrollbar */
    }
    &::-webkit-scrollbar-thumb {
      background-color: rgb(204, 204, 204); /* color of the scroll thumb */
      border-radius: 20px; /* roundness of the scroll thumb */
      /* // border: 1px solid rgb(235, 235, 235); creates padding around scroll thumb */
    }
  }
</style>
