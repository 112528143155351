<template>
  <div>
    <button
      id="transferModal"
      hidden
      type="button"
      class="btn btn-primary"
      data-toggle="modal"
      data-target="#exampleModal"
    ></button>
    <div
      class="modal fade"
      id="exampleModal"
      tabindex="-1"
      aria-labelledby="exampleModalLabel"
      aria-hidden="true"
    >
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="exampleModalLabel">
              Transferir ticket
            </h5>
            <button
              type="button"
              class="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <div class="form-group">
              <label for="">Departamento</label>
              <select class="form-control" name="" id="">
                <option>Financeiro</option>
              </select>
            </div>
            <div class="form-group">
              <label for="">Atendente</label>
              <select class="form-control" name="" id="">
                <option>Bob</option>
              </select>
            </div>
          </div>
          <div class="modal-footer">
            <button
              type="button"
              class="btn btn-secondary"
              data-dismiss="modal"
            >
              Cancelar
            </button>
            <button type="button" class="btn btn-primary">Transferir</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    name: "TransferModal",

    mounted() {
      this.$event.$on("toggleTransferTicket", () => {
        document.getElementById("transferModal").click();
      });
    },
  };
</script>

<style></style>
