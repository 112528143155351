<template>
  <div class="row">
    <div class="col-12">
      <div class="card">
        <div class="card-body">
          <div class="d-flex align-items-center mb-4">
            <h4 class="card-title">Tickets</h4>
            <div class="ml-auto">
              <div class="dropdown sub-dropdown">
                <button
                  class="btn btn-link text-muted dropdown-toggle"
                  type="button"
                  id="dd1"
                  data-toggle="dropdown"
                  aria-haspopup="true"
                  aria-expanded="false"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    stroke="currentColor"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    class="feather feather-more-vertical"
                  >
                    <circle cx="12" cy="12" r="1"></circle>
                    <circle cx="12" cy="5" r="1"></circle>
                    <circle cx="12" cy="19" r="1"></circle>
                  </svg>
                </button>
                <div
                  class="dropdown-menu dropdown-menu-right"
                  aria-labelledby="dd1"
                >
                  <a class="dropdown-item" href="#">Insert</a>
                  <a class="dropdown-item" href="#">Update</a>
                  <a class="dropdown-item" href="#">Delete</a>
                </div>
              </div>
            </div>
          </div>
          <div class="table-responsive">
            <table class="table no-wrap v-middle mb-0">
              <thead>
                <tr class="border-0">
                  <th class="border-0 font-14 font-weight-medium text-muted">
                    Contato
                  </th>
                  <th
                    class="border-0 font-14 font-weight-medium text-muted px-2"
                  >
                    Setor
                  </th>
                  <th class="border-0 font-14 font-weight-medium text-muted">
                    Atendente
                  </th>
                  <th
                    class="border-0 font-14 font-weight-medium text-muted text-center"
                  >
                    Status
                  </th>
                  <th
                    class="border-0 font-14 font-weight-medium text-muted text-center"
                  >
                    Weeks
                  </th>
                  <th class="border-0 font-14 font-weight-medium text-muted">
                    Budget
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td class="border-top-0 px-2 py-4">
                    <div class="d-flex no-block align-items-center">
                      <div class="mr-3">
                        <img
                          src="../assets/images/users/widget-table-pic1.jpg"
                          alt="user"
                          class="rounded-circle"
                          width="45"
                          height="45"
                        />
                      </div>
                      <div class="">
                        <h5 class="text-dark mb-0 font-16 font-weight-medium">
                          Hanna Gover
                        </h5>
                        <span class="text-muted font-14">hgover@gmail.com</span>
                      </div>
                    </div>
                  </td>
                  <td class="border-top-0 text-muted px-2 py-4 font-14">
                    BOT
                  </td>
                  <td class="border-top-0 px-2 py-4">
                    <div class="popover-icon">
                      <img
                        class="rounded-circle"
                        src="https://static.botsrv.com/website/img/quriobot_favicon.1727b193.png"
                        width="45"
                        height="45"
                      />

                      <a
                        class="btn btn-success text-white rounded-circle btn-circle font-20"
                        href="javascript:void(0)"
                        >-></a
                      >
                    </div>
                  </td>
                  <td class="border-top-0 text-center px-2 py-4">
                    <i
                      class="fa fa-circle text-primary font-12"
                      data-toggle="tooltip"
                      data-placement="top"
                      title="Aguardando resposta"
                      data-original-title="In Testing"
                    ></i>
                  </td>
                  <td
                    class="border-top-0 text-center font-weight-medium text-muted px-2 py-4"
                  >
                    35
                  </td>
                  <td
                    class="font-weight-medium text-dark border-top-0 px-2 py-4"
                  >
                    $96K
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style></style>
