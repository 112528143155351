import axios from "axios";

export default () => {
  const USER_TOKEN = localStorage.getItem("token");
  const AuthStr = "bearer ".concat(USER_TOKEN);
  return axios.create({
    baseURL: process.env.VUE_APP_BACK_BASE_URL + "/api/",
    headers: {
      "Access-Control-Allow-Origin": "*",
      "Content-Type": "application/json",
      // "Access-Control-Expose-Headers": "*",
      Authorization: AuthStr,
    },

    // "Access-Control-Allow-Headers": "*",
    //
    // "Access-Control-Allow-Origin": "*",
    // proxy: {
    //   host: "http://192.168.0.83",
    //   port: "8081",
    // },
    // credentials: true,
    // timeout: 5000
  });
};
