<template>
  
  <div class="card">
    
    <div class="w-100 d-flex justify-content-end "></div>
    
    <div class="card-body d-flex  align-items-center justify-content-center">
      
      <div v-if="!logged"><img :src="qrCode" alt="" /></div>
      
      <div
        v-if="
          (logged && vemomStatus == 'chatsAvailable') ||
            (vemomStatus == 'qrReadSuccess' && deviceLoaded)
        "
      >
        <h1>Conectado</h1>

        <h4 class="mt-3 mb-3">Informações do celular:</h4>

        <!----------------------------Celular---------------------------->
        <div>
          <div class="mt-2  ">
            <p>
              <strong> Nome:</strong>
              {{ vemomDevice.phone.device_manufacturer }}
            </p>
            <p>
              <strong> (OS) Plataforma:</strong>
              {{ vemomDevice.platform }}
            </p>
            <p>
              <strong> Versão do Os:</strong>
              {{ vemomDevice.phone.os_version }}
            </p>
            <p>
              <strong> Número</strong>
              {{ vemomDevice.wid.user }}
            </p>
          </div>
        </div>

        <!----------------------------Bateria---------------------------->
        <div>
          <div class="mt-3">Bateria:</div>
          <div class="d-flex align-items-center mt-2  ">
            <div style="width:200px!important">
              <div class="progress" style="height: 5px;">
                <div
                  class="progress-bar bg-primary"
                  role="progressbar"
                  :style="[{ width: vemomDevice.battery + '%' }]"
                  :aria-valuenow="vemomDevice.battery"
                  aria-valuemin="0"
                  aria-valuemax="100"
                ></div>
              </div>
            </div>
            <strong class="ml-3">{{ vemomDevice.battery }} % </strong>
          </div>
        </div>

        <!--///------------------------Celular---------------------------->

      </div>

      <div
        v-if="!logged && messageAboutStatus"
        class="d-flex flex-column align-items-center"
      >
        <h3>{{ messageAboutStatus }}</h3>
        <div class="d-flex ">
          <strong style="display:none" ref="reloaded_now_wait"
            >Carregando aguarde ....</strong
          >
        </div>
      </div>
    </div>
    <div class="alert alert-warning text-center mb-0" role="alert">
      <div>
        Para desconectar basta clicar em
        <span class="text-danger">Desconectar</span> abaixo, ou sair pelo
        celular conectado.
      </div>
      <button class="btn btn-danger m-3" ref="off" @click="disconnectServer()">
        Desconectar
      </button>
      <button
        class="btn btn-primary m-3"
        ref="reloadBtn"
        @click="reloadServer()"
      >
        Recarregar QR
      </button>
    </div>
  </div>
</template>

<script>
  
  import { mapActions, mapGetters } from "vuex";
  
  export default {
    
    name: "Connection",
    
    data() {
      
      return {
        server_link: process.env.VUE_APP_VENOM_SERVER,
        socket_server_link: process.env.VUE_APP_SOCKET_SERVER,
        qrCode: null,
        logged: false,
        socket: null,
        messageAboutStatus: "Você não está conectado.",
        dontShowReloadBtn: false,
        update: 0,
        deviceLoaded: false,
      };

    },
    
    computed: {
      ...mapGetters(["vemomStatus", "vemomDevice", "loggedUser"]),
    },
    
    watch: {
      vemomStatus(x) {
        console.log("watching...", x);
      },
    },

    mounted() {
      
      setTimeout(() => {
        
        console.log("this.loggedUser.clientName", this.loggedUser.clientName);
        
        this.$socket.on(
          
          this.loggedUser.clientName + "::qrCode", 
          
          (data) => {
            
            console.log("qrCode generated");
            
            localStorage.setItem("qr", data);
            
            this.SET_VENOM_STATUS(null);
            this.logged = false;
            this.qrCode = localStorage.getItem("qr");
            this.messageAboutStatus = null;

          }

        );

        this.$socket.on(
          
          this.loggedUser.clientName + "::statusSession",

          (data) => {
            if (
              data.includes("chatsAvailable") ||
              data.includes("qrReadSuccess")
            ) {
              this.qrCode = null;
              this.logged = true;
              this.update++;
            }
            this.SET_VENOM_STATUS(data);
            this.update++;
            this.$forceUpdate();
          }

        );

      }, 2000);

      let data = this.VENOM_SESSION_STATUS();

      if(data){
        // res.then((data) => {

          console.log('=== Agora entrou no then de this.VENOM_SESSION_STATUS');

          if (data.includes("chatsAvailable") || data.includes("qrReadSuccess")) {
            
            this.LOAD_VENOM_DEVICE().then(() => {
              this.deviceLoaded = true;
              this.messageAboutStatus = null;
            });

          }

        // }
      }


    },

    
    methods: {
    
      ...mapActions([
        "VENOM_SESSION_STATUS",
        "RELOAD_VENOM",
        "KILL_TOKEN_VENOM",
        "LOAD_VENOM_DEVICE",
        "SET_VENOM_STATUS",
      ]),

    
      reloadServer() {
        this.messageAboutStatus = "Você não esta conectado";
        this.qrCode = null;
        this.logged = false;

        this.RELOAD_VENOM().then((data) => {
          this.deviceLoaded = true;
          this.messageAboutStatus = null;
          this.logged = true;
        });
      },
    
      disconnectServer() {
        this.KILL_TOKEN_VENOM();
        this.this.qrCode = null;
        this.logged = false;
        this.SET_VENOM_STATUS(null);
        alert("Voce foi desconectado");
        // this.RELOAD_VENOM();
      },

    },

  };
</script>

<style></style>
