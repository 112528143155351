<template>
  <span ref="insert_status"></span>
</template>

<script>
  export default {
    name: "BadgeStatus",
    data() {
      return {
        transmition: [
          { code: 0, color: "cyan", status: "Aguardando inicialização" },
          { code: 1, color: "cyan", status: "Enviando" },
          { code: 2, color: "success", status: "Concluido" },
          { code: 3, color: "danger", status: "Concluido / falhas" },
          { code: 4, color: "danger", status: "Falha no envio" },
          { code: 5, color: "warning", status: "Numero invalido" },
        ],
        transmition_contacts: [
          { code: 0, color: "cyan", status: "Aguardando envio" },
          { code: 1, color: "cyan", status: "Enviando" },
          { code: 2, color: "success", status: "Enviado" },
          { code: 3, color: "danger", status: "Número inválido" },
          { code: 4, color: "danger", status: "Falha no envio" },
        ],
      };
    },
    props: {
      code: {
        type: Number,
        required: true,
      },
      selectData: {
        type: String,
        require: true,
      },
      socket_update: {
        type: Number,
      },
    },
    mounted() {
      this.addClass();
    },
    watch: {
      socket_update(val) {
        this.$forceUpdate();
      },
    },
    methods: {
      addClass() {
        let selectedData = this.$props.selectData;
        console.log("swelected data::::", selectedData);
        let status = this[selectedData].find((status) => {
          return status.code == this.$props.code;
        });

        let span = document.createElement("span");
        span.classList.add("badge");
        span.classList.add("badge-" + status.color);
        span.innerText = status.status;

        this.$refs.insert_status.append(span);
      },
    },
  };
</script>

<style></style>
