<template>
  <div
    class="modal fade"
    id="contactModal"
    tabindex="-1"
    aria-labelledby="contactModalLabel"
    aria-hidden="true"
  >
    <div
      class="modal-dialog  modal-right 
      "
      style="width:20%"
    >
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" v-if="!isEditing && isCreating">
            Novo contato
          </h5>
          <h5 class="modal-title" v-if="isEditing">
            Editar contato
          </h5>

          <button
            type="button"
            class="close"
            data-dismiss="modal"
            aria-label="Close"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
          <form>
            <div class="form-group">
              <label for="name">Nome</label>
              <input
                type="text"
                class="form-control"
                id="name"
                aria-describedby="name"
                v-model="contactIn.name"
                :disabled="!isEditing && !isCreating"
              />
            </div>
            <div class="form-group">
              <label for="cpf">CPF</label>
              <input
                type="text"
                class="form-control"
                id="cpf"
                aria-describedby="cpf"
                v-model="contactIn.cpf"
                :disabled="!isEditing && !isCreating"
              />
            </div>
            <div class="form-group">
              <label for="number">Número</label>
              <input
                type="text"
                class="form-control"
                id="number"
                v-model="contactIn.number"
                :disabled="!isEditing && !isCreating"
              />
              <small id="numberHelp" class="form-text text-muted"
                >Sempre adicione o DDI
              </small>
              <small> Ex: 551199999999</small>
            </div>
          </form>
        </div>
        <div class="modal-footer" v-has:access.permission="'import-patient'">
          <button type="button" class="btn btn-secondary" data-dismiss="modal">
            Close
          </button>
          <button type="button" class="btn btn-primary" @click="save()">
            Salvar
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import { mapGetters, mapActions } from "vuex";
  import ModalJS from "../../mixins/modal";
  export default {
    mixins: [ModalJS],
    name: "ModalContact",
    data() {
      return {
        contactIn: {
          name: null,
          number: null,
          cpf: null,
        },
        canSave: false,
      };
    },
    props: {
      isEditing: {
        type: Boolean,
      },
      isCreating: {
        type: Boolean,
      },
    },
    computed: {
      ...mapGetters(["contact"]),
    },
    mounted() {
      this.$event.$on("select-this-contact", (contact) => {
        this.contactIn = contact;
      });
      this.$event.$on("select-contact", () => {
        this.contactIn = this.contact;
      });
      this.$event.$on("close-contact-modal", () => {
        this.clear();
      });
    },
    methods: {
      ...mapActions(["UPDATE_CONTACT_IN", "SAVE_CONTACT", "UPDATE_CONTACT"]),

      save() {
        var canSave = true;
        if (this.contactIn.number.length < 8) {
          canSave = false;
          this.$toast.error(" ", "Numero invalido, curto demais", {
            icon: "icon-person",
            position: "topRight",
            timeout: 8000,
          });
        }
        if (this.contactIn.cpf.length < 10) {
          canSave = false;
          this.$toast.error(" ", "Cpf invalido, curto demais", {
            icon: "icon-person",
            position: "topRight",
            timeout: 8000,
          });
        }
        if (canSave) {
          if (this.$props.isEditing) {
            this.UPDATE_CONTACT(this.contactIn);
            this.$toast.success(" ", "Contato atualizado", {
              icon: "icon-person",
              position: "topRight",
              timeout: 8000,
            });
            this.$event.$emit("updated-contact", this.contactIn);
          } else {
            this.SAVE_CONTACT(this.contactIn);
            this.$toast.success(" ", "Contato criado", {
              icon: "icon-person",
              position: "topRight",
              timeout: 8000,
            });
            this.$event.$emit("created-contact", this.contactIn);
          }

          $("#contactModal").modal("hide");
        }
      },
      clear() {
        this.contactIn = {
          name: null,
          number: null,
          cpf: null,
        };
      },
    },
  };
</script>

<style></style>
