<template>
  <div id="app">
    <transition name="slide-in" mode="out-in">
      <router-view />
    </transition>
  </div>
</template>
<script>
  window.$ = require("jquery");
  window.JQuery = require("jquery");
  export default {
    mounted() {
      //   var actives = document.getElementsByClassName("selected");
      //   [...actives].forEach((a) => {
      //     console.log(a);
      //     a.style.backgroundColor = `${this.$config.colors.primary}`;
      //   });
      //   console.log(actives);
      document.title = process.env.VUE_APP_TITLE;
    },
  };
</script>
<style lang="scss">
  html,
  body {
    margin: 0;
    padding: 0;
    border: 0;
    font-size: 100%;
    font: inherit;
    vertical-align: baseline;
    background-color: rgb(233, 233, 233) !important;
  }
  // .selected {
  //   background: transparent !important;
  // }
  .pointer {
    cursor: pointer !important;
  }
  .pointer-border {
    cursor: pointer !important;
    &:hover {
      border-bottom: 1px solid #5f76e8;
    }
  }

  .iziToast.iziToast-color-red {
    background-color: #ff4f70 !important;
  }

  html {
    scroll-behavior: smooth;
  }

  .flex-gap-btn > button {
    /* display: inline-flex; */
    flex-wrap: wrap;
    margin: 6px;
  }
</style>
