<template>
  <div>
    <a href="javascript(0);" data-toggle="modal" data-target="#importPatient">
      Importar
    </a>
    <input
      hidden
      type="file"
      class="my_input"
      @change="importExcel"
      id="upload"
      ref="inputExcel"
    />

    <div
      class="modal fade"
      id="importPatient"
      tabindex="-1"
      aria-labelledby="importPatientLabel"
      aria-hidden="true"
      data-backdrop="static"
      data-keyboard="false"
    >
      <div class="modal-dialog  modal-dialog-centered  modal-lg">
        <div class="modal-content">
          <div class="modal-header">
            <h4 class="modal-title" id="importPatientLabel">
              Importação de Pacientes
            </h4>
            <button
              type="button"
              class="close"
              data-dismiss="modal"
              aria-label="Close"
              @click="reset()"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <div class="opening-modal-container" v-if="importingStage == 0">
              <div class="instruction-container">
                <p>
                  Escolha um arquivo com o formato .xls ou .xlsx
                </p>
                <p class="text-danger">
                  Deve conter 4 colunas com exatamente os seguintes nomes: Nome
                  , Cpf , DDI e Numero.
                </p>
                <p class="text-danger">
                  É muito importante que os números contenham o DDD.
                </p>
                <p class="text-danger">
                  <strong>Deixe o DDI em uma coluna separada</strong>
                </p>
                <p><strong>Segue o exmplo da imagem</strong></p>
                <img
                  width="500"
                  class="mb-3"
                  src="../../assets/icons/excel_exemple.png"
                  alt=""
                />
                <p>
                  Em seguida aguarde.
                </p>
                <p>
                  E clique no botão inciar.
                </p>
              </div>
            </div>
            <div
              v-if="importingStage == 1"
              class="d-flex justify-content-center align-items-center flex-column"
            >
              <img
                width="300"
                src="../../assets/icons/undraw_Taking_notes_re_bnaf.png"
                alt=""
              />
              <h3 class="mt-3">Carregando arquivo...</h3>
            </div>
            <div
              class="d-flex justify-content-center align-items-center mt-2"
              v-if="importingStage == 2"
            >
              <p>
                Foram carregados {{ importedPatients_Excel.length }} pacientes
                validos da sua lista.
              </p>
            </div>
            <div
              class="d-flex align-items-center  flex-column"
              v-if="importingStage == 3"
            >
              <img
                width="300"
                src="../../assets/icons/undraw_loading_frh4.png"
                alt=""
              />
              <h4 class="mt-3">
                <strong
                  >Não atualize esta página enquanto estiver importando</strong
                >
              </h4>
              <h3 class="mt-3">Importando...</h3>
            </div>
            <div
              class="d-flex align-items-center  flex-column"
              v-if="importingStage == 4"
            >
              <img
                width="300"
                src="../../assets/icons/undraw_Confirmation_re_b6q5.png"
                alt=""
              />
              <h4>Pacientes importados com sucesso!</h4>
            </div>
          </div>
          <div class="modal-footer">
            <button
              class="btn btn-warning"
              @click="downloadTemplate()"
              v-if="importingStage < 1"
            >
              Download de planilha modelo
            </button>
            <button
              class="btn btn-primary"
              @click="triggerInput()"
              v-if="importingStage < 1"
            >
              Procurar arquivo
            </button>
            <button
              class="btn btn-primary"
              @click="startImporting()"
              v-if="importingStage == 2"
            >
              iniciar importação
            </button>
            <!-- <button
              type="button"
              class="btn btn-secondary"
              data-dismiss="modal"
            >
              Close
            </button>
            <button type="button" class="btn btn-primary">Save changes</button> -->
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "../../http";
import { mapActions } from "vuex";
export default {
  data() {
    return {
      tableImportStatus: null,
      importedPatients_Excel: [],
      importingStage: 0,
    };
  },
  watch: {
    tableImportStatus(val) {
      if (val > 99) {
        this.importingStage = 2;
      }
    },
  },
  mounted() {
    this.$event.$on("imported_excel", () => {
      this.importingStage = 4;
      this.importedPatients_Excel = [];
      setTimeout(() => {
        $("#importPatient").modal("hide");
      }, 2000);
    });
  },
  methods: {
    ...mapActions(["IMPORT_PATIENTS"]),
    // Import table
    importExcel(e) {
      this.importingStage = 1;
      var files = [];
      files = e.target.files;
      if (!files.length) {
        return;
      } else if (!/\.(xlsm|xls|xlsx)$/.test(files[0].name.toLowerCase())) {
        return alert(
          "O arquivo não tem o formato valido.Insira um dos formatos, xls ou xlsx"
        );
      }

      var fileReader = new FileReader();
      fileReader.onload = (ev) => {
        try {
          var data = ev.target.result;
          console.log("data in loop", data);
          var workbook = XLSX.read(data, {
            type: "binary",
            cellDates: true,
            cellNF: false,
            cellText: false,
          });
          var wsname = workbook.SheetNames[0]; // Take the first sheet，wb.SheetNames[0] :Take the name of the first sheet in the sheets
          var ws = XLSX.utils.sheet_to_json(workbook.Sheets[wsname], {
            raw: false,
            dateNF: "DD-MM-YYYY HH:mm:ss",
          }); // Generate JSON table content，wb.Sheets[Sheet名]    Get the data of the first sheet
          for (var i = 0; i < ws.length; i++) {
            var total = 100 / ws.length;
            this.tableImportStatus = Math.round(total * i);
            if (ws.length - 1 == i) {
              this.tableImportStatus = 100;
            }
            // console.log("ws.length - 1 == i", ws.length - 1 == i);

            console.log(ws[i]);
            if (
              ws[i].Nome &&
              ws[i].Nome.length &&
              ws[i].Cpf &&
              ws[i].Cpf.length &&
              ws[i].DDI &&
              ws[i].DDI.length &&
              ws[i].Numero &&
              ws[i].Numero.length
            ) {
              let data = {
                index: i,
                name: ws[i].Nome,
                cpf: ws[i].Cpf,
                number: (ws[i].DDI += ws[i].Numero),
              };
              this.importedPatients_Excel.push(data);
            }
          }
        } catch (e) {
          console.log(e);
          return alert("Read failure!");
        }
      };
      console.log("files", files);
      //  console.log('fileReader.readAsBinaryString(files[0]);', fileReader.readAsArrayBuffer(files))
      fileReader.readAsBinaryString(files[0]);

      var input = document.getElementById("upload");
      input.value = "";
    },
    triggerInput() {
      this.$refs.inputExcel.click();
    },
    startImporting() {
      this.importingStage = 3;
      this.IMPORT_PATIENTS(this.importedPatients_Excel).then(() => {
        $("#importPatient").modal("hide");
        setTimeout(() => {
          this.reset();
        }, 2000);
      });
    },
    reset() {
      this.importingStage = 0;
      this.importedPatients_Excel = [];
      this.tableImportStatus = 0;
    },
    downloadTemplate() {
      axios()
        .get("download_template", {
          responseType: "blob",
        })
        .then((response) => {
          var fileURL = window.URL.createObjectURL(new Blob([response.data]));

          var fileLink = document.createElement("a");

          fileLink.href = fileURL;

          fileLink.setAttribute(
            "download",
            "planilha_template_importação_pacientes.xlsx"
          );

          document.body.appendChild(fileLink);

          fileLink.click();
        });
    },
  },
};
</script>

<style lang="scss">
.opening-modal-container {
  display: flex;
  align-items: center;
}
</style>
