<template>
  <div v-if="isLoaded">
    <div class="d-flex justify-content-center justify-content-between">
      <h1>Confirmações de Agendamentos</h1>

      <button
        class="btn btn-primary ml-5"
        @click.prevent="saveBotInteraction()"
      >
        Salvar
      </button>
    </div>

    <div class="row mt-5">
      <div class="col-6">
        <div class="card">
          <div class="card-body appointment">
            <div class="title">
              Digite a mensagem padrão para a confirmação dos Agendamentos:
            </div>
            <div>
              <textarea
                id=""
                class="form-control"
                rows="5"
                v-model="Actionx.value.message"
              ></textarea>
            </div>
          </div>
        </div>
        <div class="card">
          <div class="card-body">
            <div class="alert alert-warning" role="alert">
              <strong><u>Como utilizar</u></strong>
              
              <p><b>Endpoint:</b> https://projeto-api.011brasil.com.br/api/confirmar_agendamento</p>
              
              <p><strong><b><u>Parâmetros</u>:</b></strong></p>

              <p><b>name:</b> string <br />
              <b>number:</b> string <br />
              <b>cpf:</b> string <br />
              <b>agendamento:</b> string <br />
              <b>data:</b> string <br />
              <b>endereco:</b> string <br />
              <b>local:</b> string <br />
              <b>local:</b> string <br />
              <b>extra:</b> string</p>
            </div>
          </div>
        </div>
      </div>
      <div class="col-6">
        <div class="card appointment">
          <div class="card-header d-flex flex-column">
            <span class="title">Endpoint para respostas</span>
            <!-- <span class="pointer" @click="addOption()">+</span> -->
            <div
              class="card-body d-flex justify-content-center justify-content-between"
            >
              <input
                class="ml-2 col form-control"
                type="text"
                placeholder="endpoint para resposta"
                v-model="Actionx.endpoint"
              />
              <select
                name=""
                id=""
                class="form-control col-3"
                v-model="Actionx.endpoint_request"
              >
                <option value="GET">GET</option>
                <option value="POST">POST</option>
                <option value="UPDATE">UPDATE</option>
              </select>
            </div>
          </div>
        </div>
        <div
          class="accordian-container"
          :class="[{ scroll: this.Actionx.value.options.length > 2 }]"
        >
          <div class="v-accordion" v-if="this.Actionx.value.options.length > 0">
            <div
              class="card v-accordion-item"
              v-for="(option, index) in Actionx.value.options"
              :key="index"
            >
              <div class="card-header">
                <div
                  class="d-flex justify-content-center align-items-center mb-2"
                >
                  <strong class="m-2">{{ option.option }} -</strong>
                  <input
                    class="ml-2 col form-control"
                    type="text"
                    placeholder="Resposta..."
                    v-model="option.label"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="card">
          <div class="card-header">
            Resposta padrão
          </div>
          <div class="card-body">
            <label for="">Após responder:</label>
            <textarea
              class="form-control"
              rows="2"
              v-model="Actionx.respondido"
            ></textarea>
          </div>
          <div class="card-body">
            <label for="">Mensagem de encerramento</label>
            <textarea
              class="form-control"
              rows="2"
              v-model="Actionx.msgEncerramento"
            ></textarea>
          </div>
          <div class="card-body">
            <label for="">Mensagem de Opção Inválida</label>
            <textarea
              class="form-control"
              rows="2"
              v-model="Actionx.msgOpcaoInvalida"
            ></textarea>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import io from "socket.io-client";
  import { mapGetters, mapActions } from "vuex";
  export default {
    data() {
      return {
        server_link: process.env.VUE_APP_VENOM_SERVER,
        socket_server_link: process.env.VUE_APP_SOCKET_SERVER,
        socket: null,
        isLoaded: false,
        Actionx: {
          label: "transmitAppointment",
          respondido: "Obrigado, {nome}. \nResposta enviada.",
          msgEncerramento: "{nome}, obrigado até breve.",
          msgOpcaoInvalida: "Desculpe, {nome}. Não encontramos esta opção.",

          bot_id: null,
          endpoint: null,
          endpoint_request: null,
          start_on_action_id: null,
          value: {
            message: "",
            options: [
              { option: 1, label: "" },
              { option: 2, label: "" },
            ],
          },
        },
      };
    },
    computed: {
      ...mapGetters(["action", "vemomStatus", "loggedUser"]),
    },
    mounted() {
      if (this.$masterHelper.isEmpty(this.action)) {
        this.isLoaded = false;
        this.LOAD_APPOINTMENT().then((data) => {
          if (data.bot_id && data.bot_id > 0) {
            this.Actionx = data;
          }
          console.log("data loaded::", data);
          setTimeout(() => {
            this.isLoaded = true;
          }, 1000);
        });
      } else {
        this.Actionx = this.action;
        setTimeout(() => {
          this.isLoaded = true;
        }, 1000);
      }

      setTimeout(() => {
        this.getStatus();
        this.socket = io(this.socket_server_link);
        this.socket.on(
          this.loggedUser.clientName + "::session-status",
          (data) => {
            this.checkConnection(data);
          }
        );
      }, 1000);
    },
    methods: {
      ...mapActions([
        "ADD_OPTION",
        "SAVE_INTERACTION",
        "LOAD_APPOINTMENT",
        "UPDATE_INTERACTION",
        "VENOM_SESSION_STATUS",
      ]),
      checkConnection(data) {
        switch (data) {
          case "inChat":
            this.logged = true;
            this.VENOM_SESSION_STATUS();
            console.log("inChat::", data);
            break;
          case "isLogged":
            this.logged = true;
            this.VENOM_SESSION_STATUS();
            console.log("isLogged::", data);
            break;
          case "notLogged":
            this.logged = false;
            this.$router.push({ name: "connection" });
            break;
          case "browserClose":
            this.$router.push({ name: "connection" });
            console.log("browserClose::", data);
            break;
          case "qrReadSuccess":
            this.VENOM_SESSION_STATUS();
            console.log("qrReadSuccess::", data);
            break;
          case "qrReadFail":
            this.$router.push({ name: "connection" });
            console.log("qrReadFail::", data);
            break;
          case "autocloseCalled":
            console.log("autocloseCalled::", data);
            break;
          case "desconnectedMobile":
            this.$router.push({ name: "connection" });
            console.log("desconnectedMobile::", data);
            break;
          case "serverClose":
            this.$router.push({ name: "connection" });
            console.log("serverClose::", data);
            break;

          case "qrReadError":
            console.log("qrReadError::", data);
            this.$router.push({ name: "connection" });
            break;
          default:
            break;
        }
        this.sessionStatus = data;
        this.qrCode = null;
      },
      getStatus() {
        this.checkConnection(this.vemomStatus);
      },
      async saveBotInteraction() {
        if (this.Actionx.bot_id > 0) {
          var response = await this.UPDATE_INTERACTION(this.Actionx);
          this.actionResponse(response);
          console.log("updating...", response);
        } else {
          var response = await this.SAVE_INTERACTION(this.Actionx);
          console.log("saving...", response);
          this.actionResponse(response);
        }
      },

      async actionResponse(data) {
        console.log("data", data);
        try {
          this.Actionx = await data;
          return data;
        } catch (error) {
          console.log("error@actionResponse");
        }
      },
      addOption() {
        //   var length = this.Actionx.value.option.length;
        //   var lastOption = this.Actionx.value.option[length - 1];
        //   if (!lastOption) {
        //     alert("Preencha as informações da opção primeiro");
        //   } else {
        //     this.Actionx.value.option.push({ option: null, label: "" });
        //   }

        if (this.Actionx.value.options.length === 0) {
          this.Actionx.value.options.push({ option: 1, label: "" });
        } else {
          var nextOptionNum = this.Actionx.value.options.length + 1;
          this.Actionx.value.options.push({ option: nextOptionNum, label: "" });
        }
        //   if (this.Actionx.value.options.length === 0) {
        //     this.ADD_OPTION({ option: "1", label: "" });
        //   } else {
        //     var nextOptionNum = this.Actionx.value.options.length + 1;
        //     this.ADD_OPTION({ option: nextOptionNum, label: "" });
        //   }
      },
      removeOption(index) {
        this.Actionx.value.options.splice(index, 1);
      },
    },
  };
</script>

<style lang="scss">
  .v-accordion.scroll {
    overflow-y: scroll;
    height: 50vh;
  }
</style>
