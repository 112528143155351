<template>
  <div v-if="isLoaded">
    <div class="row">
      <div class="col"><h1>Cargos</h1></div>
    </div>
    <div class="row mt-5">
      <div class="col">
        <div class="card">
          <div class="card-body">
            <div class="col-3">
              <div class="d-flex">
                <input
                  class="form-control"
                  type="text"
                  placeholder="Pesquisar"
                  v-model="search"
                />
                <button
                  class="btn btn-primary ml-3"
                  data-toggle="modal"
                  data-target="#roleModal"
                  @click="createRole()"
                >
                  +
                </button>
              </div>
            </div>
            <div class=" mt-4">
              <table class="table">
                <thead>
                  <tr>
                    <th scope="col">Nome</th>
                    <th scope="col" class="text-center">Descrição</th>
                    <th scope="col" class="text-center">Ações</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="role in filterCore" :key="role.id">
                    <td>{{ role.slug }}</td>
                    <td class="text-center">{{ role.description }}</td>

                    <td class="text-center">
                      <button class="btn btn-success" @click="viewRole(role)">
                        <i class="far fa-eye"></i>
                      </button>
                      <button
                        class="btn btn-primary ml-3"
                        @click="editRole(role)"
                      >
                        <i class="far fa-edit"></i>
                      </button>
                      <button
                        class="btn btn-danger ml-3"
                        @click="deleteRole(role.id)"
                      >
                        <i class="far fa-trash-alt"></i>
                      </button>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!------------------modal------------------->
    <div
      class="modal fade"
      id="roleModal"
      tabindex="-1"
      aria-labelledby="roleModalLabel"
      aria-hidden="true"
    >
      <div
        class="modal-dialog  modal-right 
      "
        style="width:20%"
      >
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" v-if="isCreating">Novo Cargo</h5>
            <h5 class="modal-title" v-if="!isCreating && isEditing">
              {{ role.slug }}
            </h5>
            <h5 class="modal-title" v-if="!isCreating && !isEditing">
              {{ role.slug }}
            </h5>
            <button
              type="button"
              class="close"
              data-dismiss="modal"
              aria-label="Close"
              @click="reset()"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <form>
              <div class="form-group">
                <label for="name">Nome</label>
                <input
                  type="text"
                  class="form-control"
                  id="name"
                  aria-describedby="name"
                  v-model="role.slug"
                  :disabled="disableToView()"
                />
              </div>
              <div class="form-group">
                <label for="description">Descrição</label>

                <textarea
                  class="form-control"
                  v-model="role.description"
                  rows="5"
                  :disabled="disableToView()"
                ></textarea>
              </div>
            </form>
            <div class="mt-4">
              <strong>Permissões</strong>
              <ul class="list-group mt-3">
                <li
                  class="list-group-item d-flex justify-content-between align-items-center"
                  v-for="permission in permissions"
                  :key="permission.id"
                >
                  <div>{{ permission.slug }}</div>
                  <button
                    v-if="!hasThisPermission(permission)"
                    class="btn btn-warning"
                    @click="togglePermission(permission)"
                    :disabled="disableToView()"
                  >
                    adicionar
                  </button>
                  <button
                    v-if="hasThisPermission(permission)"
                    class="btn btn-danger"
                    @click="togglePermission(permission)"
                    :disabled="disableToView()"
                  >
                    remover
                  </button>
                </li>
              </ul>
            </div>
          </div>
          <div class="modal-footer">
            <button
              type="button"
              class="btn btn-secondary"
              data-dismiss="modal"
            >
              Close
            </button>
            <button
              type="button"
              class="btn btn-primary"
              @click="saveRole()"
              :disabled="disableToView()"
            >
              Salvar
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";

export default {
  data() {
    return {
      search: "",
      isLoaded: false,
      isEditing: false,
      isCreating: false,
      role: {
        id: null,
        slug: "",
        description: "",
        permissions: [],
      },
      permissions_to_remove: [],
      permissions_to_add: [],
    };
  },
  computed: {
    ...mapGetters(["users", "roles", "permissions"]),
    filterCore() {
      if (this.search.length > 0) {
        return this.roles.filter((role) => {
          return (
            role.slug.toLowerCase().includes(this.search.toLowerCase()) ||
            role.description.toLowerCase().includes(this.search.toLowerCase())
          );
        });
      } else {
        return this.roles;
      }
    },
  },
  mounted() {
    var that = this;
    if (!this.roles.length && !this.permissions.length) {
      this.LOAD_ROLES();
      this.LOAD_PERMISSIONS();
    }

    setTimeout(() => {
      this.isLoaded = true;
    }, 1000);
    $("#roleModal").on("hidden.bs.modal", function(e) {
      that.reset();
    });
  },
  methods: {
    ...mapActions([
      "LOAD_ROLES",
      "LOAD_PERMISSIONS",
      "SAVE_ROLE",
      "UPDATE_ROLE",
      "DELETE_ROLE",
    ]),
    hasThisPermission(permission) {
      return this.role.permissions.some((rolePer) => {
        return permission.id === rolePer.id;
      });
    },
    editRole(role) {
      this.role = role;
      this.isEditing = true;
      setTimeout(() => {
        $("#roleModal").modal("show");
      }, 200);
    },
    viewRole(role) {
      this.reset();
      this.role = role;
      setTimeout(() => {
        $("#roleModal").modal("show");
      }, 200);
    },
    createRole() {
      this.reset();
      this.isCreating = true;
    },
    disableToView() {
      return !this.isEditing && !this.isCreating;
    },
    togglePermission(permisison) {
      var hasIt = this.role.permissions.some((perInRole) => {
        return perInRole.id === permisison.id;
      });

      var perIndex = this.role.permissions.findIndex((per) => {
        return per.id === permisison.id;
      });

      if (hasIt) {
        this.role.permissions.splice(perIndex, 1);
      } else {
        this.role.permissions.push(permisison);
      }
    },
    reset() {
      this.isEditing = false;
      this.isCreating = false;
      this.role = {
        id: null,
        name: "",
        description: "",
        permissions: [],
      };
    },
    saveRole() {
      if (this.isEditing && !this.isCreating) {
        //update
        this.UPDATE_ROLE(this.role);
        this.reset();
        this.$toast.success(" ", "Cargo atualizado", {
          icon: "icon-person",
          position: "topRight",
          timeout: 3000,
        });
      } else if (!this.isEditing && this.isCreating) {
        //create
        this.SAVE_ROLE(this.role);
        this.reset();
        this.$toast.success(" ", "Cargo criado", {
          icon: "icon-person",
          position: "topRight",
          timeout: 3000,
        });
      }
      $("#roleModal").modal("hide");
    },
    deleteRole(id) {
      this.DELETE_ROLE(id);
      this.$toast.warning(" ", "Cargo deletado", {
        icon: "icon-person",
        position: "topRight",
        timeout: 3000,
      });
    },
  },
};
</script>

<style></style>
