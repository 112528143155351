import { mapActions, mapGetters } from "vuex";
export default {
  mounted() {
    let that = this;
    $("#contactModal").on("hidden.bs.modal", function(event) {
      that.close();
    });
  },
  computed: {
    ...mapGetters([]),
  },
  methods: {
    ...mapActions(["SELECT_CONTATO"]),
    close() {
      this.$event.$emit("close-contact-modal");
      this.SELECT_CONTATO({});
    },
  },
};
