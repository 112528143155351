<template>
  <!-- Modal -->
  <div
    class="modal fade"
    id="campaignDisplay"
    data-backdrop="static"
    data-keyboard="false"
    tabindex="-1"
    aria-labelledby="campaignDisplayLabel"
    aria-hidden="true"
  >
    <div class="modal-dialog modal-xl">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="campaignDisplayLabel">
            Detalhes da campanha
          </h5>
          <button
            type="button"
            class="close"
            data-dismiss="modal"
            aria-label="Close"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body" v-if="!isEmpty(transmitionObj)">
          <div class="row" v-if="render">
            <div class="col">
              <p><strong> Cod: </strong>{{ transmitionObj.uuid }}</p>
              <p>
                <strong> Data criação: </strong
                >{{ $date(transmitionObj.started_at) }}
              </p>
              <p>
                <strong> Concluido: </strong
                >{{ $date(transmitionObj.finished_at) }}
              </p>
              <p>
                <strong> Criado por: </strong
                >{{ getUser(transmitionObj.created_by).name || "-" }}
              </p>
            </div>
            <div class="col">
              <p>
                <strong> Total de contatos: </strong>
                {{ campaignContacts.length }}
              </p>
              <p>
                <strong> Contatos invalidos </strong>
                {{
                  campaignContacts.map((campCon) => campCon.status === 3).length
                }}
              </p>
              <span class="d-flex align-items-center">
                <strong class="mr-4"> Status atual: </strong>

                <h3>
                  <BadgeStatus
                    v-if="render"
                    :code="transmitionObj.status"
                    selectData="transmition"
                  />
                </h3>
              </span>
            </div>
          </div>
          <div class="alert alert-warning" role="alert">
            <p>
              <span>Os contatos inválidos podem ser editados. Clique em</span>
              <span class="ml-2"
                ><i title="Editar contato" class="fas fa-user-edit"></i
              ></span>
            </p>
            <p>
              Se você reenviar a campanha, o mesmo será enviado somente para os
              contatos que tiveram exceções.
            </p>
          </div>
          <div class="d-flex">
            <table class="table">
              <thead>
                <tr>
                  <th>Nome</th>
                  <th>Enviado</th>
                  <th>Status</th>
                </tr>
              </thead>
              <tbody>
                <tr
                  v-for="con in campaignContacts"
                  :key="con.uuid"
                  class="pointer"
                >
                  <td scope="row">
                    <span class="d-flex align-items-center">
                      <div class="mr-2">
                        {{ getClient(con.patient_id).name }}
                      </div>
                      <span @click="edit(getClient(con.patient_id))">
                        <i
                          title="Editar contato"
                          class="fas fa-user-edit ml-4 pointer"
                          v-if="con.status === 3"
                        ></i>
                      </span>
                    </span>
                  </td>
                  <td class="d-flex">
                    <div>{{ con.started_at }}</div>
                  </td>
                  <td>
                    <h4 :id="con.uuid">
                      <BadgeStatus
                        v-if="render"
                        :code="con.status"
                        selectData="transmition_contacts"
                      />
                    </h4>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        <div class="modal-body" v-if="isEmpty(transmition)">
          <div
            class="w-100 h-100 d-flex align-items-center justify-content-center"
          >
            <div class="spinner-border text-secondary" role="status">
              <span class="sr-only">Loading...</span>
            </div>
            <span class="ml-4">Carregando, aguarde...</span>
          </div>
        </div>
        <div class="modal-footer">
          <button
            @click="resend()"
            class="btn btn-primary"
            v-if="
              campaignContacts.map((campCon) => campCon.status === 3).length >
                0 && btnStatus()
            "
          >
            Reenviar
          </button>
          <button type="button" class="btn btn-secondary" data-dismiss="modal">
            Fechar
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import { mapGetters, mapActions } from "vuex";
  import BadgeStatus from "@/components/BadgeStatus";

  export default {
    components: {
      BadgeStatus,
    },
    data() {
      return {
        isEmpty: (obj) => {
          return this.$masterHelper.isEmpty(obj);
        },
        UPDATE_SOCKET: 0,
        render: true,
        isEditing: false,
      };
    },
    computed: {
      ...mapGetters([
        "transmition",
        "users",
        "transmition_contacts",
        "contacts",
        "loggedUser",
      ]),
      campaignContacts() {
        this.UPDATE_SOCKET;
        return this.transmition_contacts.filter((tr_contacts) => {
          return tr_contacts.transmition_id === this.transmition.id;
        });
      },
      transmitionObj() {
        this.UPDATE_SOCKET;
        return this.transmition;
      },
    },
    mounted() {
      this.$socket.on(
        this.loggedUser.clientName + "::updated-transmition-contact",
        (data) => {
          this.UPDATE_TRANSMITION_CONTACT(data);
          this.reload();
          this.UPDATE_SOCKET++;
          console.log("::updated-transmition-contact");
        }
      );
      this.blockBtn(false);
    },

    methods: {
      ...mapActions([
        "UPDATE_TRANSMITION_CONTACT",
        "SELECT_CONTATO",
        "RESEND_TRANSMITION",
      ]),
      getUser(id) {
        let user = this.users.find((user) => {
          return user.id === id;
        });
        if (!user) return id;
        return user;
      },
      getClient(id) {
        return this.contacts.find((c) => {
          return c.id === id;
        });
      },
      reload() {
        this.render = false;
        this.$nextTick(() => {
          this.render = true;
        });
      },
      edit(contact) {
        $("#campaignDisplay").modal("hide");
        this.SELECT_CONTATO(this.clearObj(contact)).then(() => {
          this.$event.$emit("select-contact");
          $("#contactModal").modal("show");
        });
      },
      clearObj(patient) {
        const { id, cpf, name, number } = patient;
        return {
          id,
          cpf,
          name,
          number,
        };
      },
      blockBtn(state) {
        localStorage.setItem("noResend", state);
      },
      btnStatus() {
        return localStorage.getItem("noResend");
      },
      resend() {
        this.blockBtn(true);
        this.RESEND_TRANSMITION({
          clientName: this.loggedUser.clientName,
          transmition_id: this.transmition.id,
          transmition_messages_id: this.transmition.transmition_messages_id,
        });
      },
    },
  };
</script>

<style></style>
