<template>
  <span>
    <div
      v-if="!loaded"
      class="page-loader d-flex justify-content-center align-items-center"
    >
      <div class="spinner-border text-primary" role="status">
        <span class="sr-only">Loading...</span>
      </div>
    </div>
    <div class="row" v-if="loaded && actions">
      <!-- ====================================
    Actions
    ===================================== -->
      <div class="col-6">
        <!-- Bot configs-->
        <div class="card">
          <div
            class="card-header d-flex align-items-center justify-content-between"
          >
            <div
              v-if="!isNamingBot"
              class="d-flex align-items-center "
              @click="nameTheBot()"
            >
              <div>
                <span v-if="!botName">Dê um nome ao bot</span>
                <span v-if="botName">{{ botName }}</span>
                <button class="btn">
                  <i class="fas fa-pencil-alt ml-2"></i>
                </button>
              </div>
            </div>
            <div class="d-flex align-items-center  w-75" v-if="isNamingBot">
              <input
                type="text"
                class=" form-control "
                v-model="botName"
                ref="botNameInput"
              />
              <button class="btn" @click="saveBotName()">
                <i class="far fa-save ml-2"></i>
              </button>
            </div>

            <div class="d-flex ">
              <label class="form-check-label" for="flexCheckDefault">
                Bot padrão
              </label>
              <toggle-button
                class="ml-2"
                color="#5f76e8"
                v-model="isThisBotDefault"
                :labels="{ checked: 'Sim', unchecked: 'Não' }"
                sync
              />
            </div>
          </div>
        </div>
        <!-- / Bot configs-->
        <!-- default messages-->
        <div class="card">
          <div
            class="card-header d-flex align-items-center justify-content-between"
          >
            <span>Mensagens padrão</span>
            <div
              class="pointer"
              v-show="!expandedMsgDefault"
              @click="toggleMsgDefault()"
            >
              <i class="fas fa-chevron-circle-down "></i>
            </div>
            <div
              class="pointer"
              v-show="expandedMsgDefault"
              @click="toggleMsgDefault()"
            >
              <i class="fas fa-chevron-circle-up "></i>
            </div>
          </div>
          <div class="card-body" v-if="expandedMsgDefault">
            <div class="">
              <label for="notFound"
                ><strong>Mensagem de "Opção não encontrada"</strong></label
              >
              <textarea
                v-model="defaults[0].message"
                id="notFound"
                class="form-control"
                cols="3"
                rows="2"
                placeholder="ex: Desculpe {nome}, não encontrei esta opção."
              ></textarea>
            </div>
            <div class="mt-5">
              <label for="bye"><strong>Mensagem de encerramento</strong></label>
              <textarea
                v-model="defaults[1].message"
                id="bye"
                class="form-control"
                cols="3"
                rows="2"
                placeholder="ex: {nome}. Obrigado por entrar em contato, volte sempre."
              ></textarea>
            </div>
          </div>
        </div>
        <!-- default messages-->
        <div class="card">
          <div
            class="card-header d-flex align-items-center justify-content-between"
          >
            <div>Blocos</div>
            <div class="d-flex align-items-center">
              <!-- <select class=" form-control" name="" id="">
              <option>Selecione um bot</option>
            </select> -->
              <button
                v-has:access.permission="'manage-chat'"
                v-if="!hasHumanContact"
                class="ml-2 btn btn-secondary"
                @click.prevent="toggleExtraFunctions()"
              >
                Atendimento humano
              </button>
              <button
                class="ml-2 btn btn-primary"
                @click.prevent="addActionBlock()"
              >
                +
              </button>
            </div>
          </div>
        </div>

        <div class="scrollable-container">
          <div
            class="card"
            v-for="(action, index) in actions"
            :key="index"
            :id="'block__' + action.block_id"
          >
            <div
              class="card-header  d-flex align-items-center justify-content-between "
              :class="[
                { 'selected-menu': selectedMenu(action.block_id) },
                { 'bg-warning text-dark': action.willSendToHuman },
              ]"
            >
              <div
                class="block-names  d-flex align-items-center justify-content-between"
              >
                <div
                  class="name-contianer"
                  v-show="!editingBlockName(action.block_id)"
                >
                  <div v-if="!action.label">Bloco - {{ action.block_id }}</div>
                  <div v-if="action.label && action.label.length > 0">
                    {{ action.label }}
                  </div>
                </div>
                <input
                  v-model="action.label"
                  class="form-control"
                  type="text"
                  v-if="editingBlockName(action.block_id)"
                  @keypress.enter="saveBlockName(action)"
                  placeholder="nome deste bloco"
                />
                <button
                  class="btn"
                  @click.prevent="changeBlockName(action)"
                  v-show="
                    !editingBlockName(action.block_id) &&
                      !action.willSendToHuman
                  "
                >
                  <i class="fas fa-pencil-alt ml-2"></i>
                </button>
                <button
                  class="btn"
                  @click="saveBlockName(action)"
                  v-show="editingBlockName(action.block_id)"
                >
                  <i class="far fa-save"></i>
                </button>
              </div>

              <div class="d-flex  justify-content-between">
                <button
                  v-if="!action.willSendToHuman"
                  class="btn"
                  @click.prevent="actionSettings(action, index)"
                >
                  <i class="fas fa-cogs"></i>
                </button>
                <button
                  class="btn  ml-2"
                  @click.prevent="removeActionBlock(index)"
                >
                  <i class="fas fa-times"></i>
                </button>
              </div>
            </div>
            <div class="card-body">
              <label :for="'msg' + index">Messagem</label>
              <textarea
                :id="'msg' + index"
                class="form-control"
                rows="5"
                v-model="action.message"
              ></textarea>
              <div class="options_container mt-3">
                <div
                  class=" d-flex align-items-center justify-content-between mb-2"
                >
                  <label v-if="!action.end && action.options.length"
                    >Opções
                  </label>
                  <label
                    v-if="
                      action.end && action.options && action.options.length > 0
                    "
                    >Opções</label
                  >
                  <label
                    v-if="
                      action.end && action.options && action.options.length < 1
                    "
                    >Agora este bloco finaliza o atendimento</label
                  >

                  <!-- <button
                    class="ml-2 btn btn-info flex-end"
                    @click.prevent="addActionOption(action, index)"
                  >
                    nova opção
                  </button> -->
                </div>
                <div
                  class="d-flex justify-content-center align-items-center mb-2"
                  v-for="(option, opIndex) in action.options"
                  :key="opIndex"
                  :id="
                    'block_option_' +
                      option.option +
                      '_block_' +
                      action.block_id
                  "
                >
                  <strong class="m-2" style="width:30px"
                    >{{ opIndex + 1 }} -</strong
                  >
                  <input
                    class="ml-2 col form-control"
                    type="text"
                    v-model="option.option"
                    hidden
                  />
                  <input
                    class="ml-2 col form-control"
                    type="text"
                    placeholder="Descrição da opção..."
                    v-model="option.label"
                  />
                  <button
                    class="ml-2 btn btn-danger"
                    @click.prevent="removeActionOption(index, opIndex)"
                  >
                    <i class="fas fa-times"></i>
                  </button>
                </div>
                <div v-if="action.exits">
                  <span> Saída -></span>
                  <!-- <span v-if="action.exits"> -->
                  {{ action.exits }}
                  <!-- {{ actions[action.exits - 1].label }}</span
                  >
                  <span v-if="action.exits"> bloco-{{ action.exits }}</span> -->
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- ====================================
    Options
    ===================================== -->
      <div class="col-6">
        <div class="card">
          <div
            class="card-body d-flex align-items-center justify-content-between"
          >
            <strong>Ao finalizar salve suas configurações</strong>

            <button class="btn btn-primary" @click="checkForEmptyOptions()">
              Salvar
            </button>
          </div>
          <div class="card-body" v-if="blockswithEmptyOptions.length > 0">
            <div class="alert alert-danger" role="alert">
              <strong>Aviso ! </strong>
              <p>
                Corrija os
                <strong> {{ blockswithEmptyOptions.length }} problemas </strong>
                e depois tente salvar novamente
              </p>
              <p>
                Os blocos que estão com bordas vermelhas possuem os seguintes
                problemas:
              </p>
              <div class=" errors-container">
                <div
                  v-for="blockErr in blockswithEmptyOptions"
                  :key="blockErr.block_id + '_' + blockErr.option"
                >
                  <strong
                    class="text-primary  pointer-border"
                    @click="findActionBlockInList(blockErr.block_id)"
                    >Bloco - {{ blockErr.block_id }}</strong
                  >
                  esta com a <strong>opção - {{ blockErr.option }}</strong> sem
                  saida.
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="card">
          <div
            class="card-header d-flex align-items-center justify-content-between"
            :class="[
              { 'selected-menu': selectedMenu(selectedAction.block_id) },
            ]"
          >
            <div>
              <!-- {{ selectedMenu(selectedAction.block_id) }} -->
              <span>
                Opções do bloco
              </span>
              <span v-if="!selectedAction.label && selectedAction.block_id">
                - {{ selectedAction.block_id }}
              </span>
              <span v-if="selectedAction.label">{{
                selectedAction.label
              }}</span>

              <button
                class="btn ml-2"
                @click.prevent="findActionBlockInList(selectedAction.block_id)"
              >
                <i class="fas fa-search-location" style="font-size: 20px"></i>
              </button>
            </div>
            <div>
              <button
                v-if="!this.$masterHelper.isEmpty(selectedAction)"
                class="btn "
                @click.prevent="closeSettings(action)"
              >
                <i class="fas fa-times"></i>
              </button>
            </div>
          </div>
          <div class="card-body" id="action-block">
            <h4 v-if="!selectedAction.block_id">
              Selecione um menu
            </h4>

            <div class="mb-5" v-if="selectedAction.block_id">
              <div class="action-configs-container">
                <div
                  class="d-flex  justify-content-between align-items-baseline"
                >
                  <div class="d-flex left-side-">
                    <div>
                      Encerrar o atendimento
                    </div>
                    <toggle-button
                      class="ml-2"
                      color="#5f76e8"
                      :value="selectedAction.end"
                      @change="
                        exclusiveToEnd();
                        selectedAction.end = $event.value;
                      "
                      :labels="{ checked: 'Sim', unchecked: 'Não' }"
                      sync
                    />
                    <div
                      class="ml-2 pointer"
                      @click.prevent="toggleEndBlockHelp()"
                      v-show="!help_with_endBlock"
                    >
                      <i class="far fa-question-circle "></i>
                    </div>
                    <div
                      class="ml-2 pointer"
                      @click.prevent="toggleEndBlockHelp()"
                      v-show="help_with_endBlock"
                    >
                      <i class="fas fa-times text-danger "></i>
                    </div>
                  </div>
                  <!-- && !selectedAction.end -->
                  <!-- <div v-if="!selectedAction.end"> -->
                  <div>
                    <button
                      class="btn"
                      @click="openOptionSettings()"
                      v-show="!openFunctions && step == 'selectActionType'"
                    >
                      <i class="fas fa-cog"></i>
                    </button>
                    <button
                      class="btn"
                      @click="
                        step = 'selectActionType';
                        openFunctions = false;
                      "
                      v-show="openFunctions && step !== 'selectActionType'"
                    >
                      x
                    </button>
                  </div>
                </div>
                <!--================================== Add exists==================================== -->
                <div
                  class=" action-settings-option-wrapper"
                  v-if="!selectedAction.end && !openFunctions"
                >
                  <button
                    class=" btn btn-warning flex-end"
                    @click.prevent="
                      addActionOption(selectedAction, selectedAction.index)
                    "
                  >
                    Nova opção
                  </button>
                  <button
                    v-if="!selectedAction.willHaveExit"
                    class="ml-2 btn btn-warning flex-end"
                    @click="addExitToBlock(false)"
                  >
                    Saída padrão
                  </button>
                </div>
                <div>
                  <div class="alert alert-warning" v-if="exitError">
                    <p>Ops!</p>
                    <p>Você ja escolheu opções para este bloco.</p>
                    <p>Quer que este bloco utilize saidas sem opções?</p>
                    <strong
                      class="text-primary pointer"
                      @click="addExitToBlock(true)"
                      >Sim</strong
                    >
                    <strong class="ml-3 pointer" @click="exitError = false"
                      >Não</strong
                    >
                  </div>
                </div>
                <div
                  v-if="selectedAction.willHaveExit && !openFunctions"
                  class="mt-2"
                >
                  <div
                    class="d-flex w-100 justify-content-between align-items-baseline"
                  >
                    <span>Saída</span>
                    <select
                      class="form-control  xpto"
                      style="width:70%"
                      v-model="selectedAction.exits"
                    >
                      <option
                        value=""
                        disabled
                        v-if="actionBlocksToSelect.length < 1"
                        >Crie mais um bloco</option
                      >

                      <!-- <option value="" v-if=""></option> -->
                      <option
                        v-for="(menu, menuIndex) in actionBlocksToSelect"
                        :key="menuIndex"
                        :value="menu.block_id"
                      >
                        <span v-if="!menu.label">
                          Bloco - {{ menu.block_id }}
                        </span>
                        <span v-if="menu.label">{{ menu.label }}</span>
                      </option>
                    </select>
                    <button
                      class="btn ml-1"
                      @click.prevent="
                        findActionBlockInList(selectedAction.exits)
                      "
                    >
                      <i
                        class="fas fa-search-location"
                        style="font-size: 20px"
                      ></i>
                    </button>
                  </div>
                </div>
                <!--================================== Add exists==================================== -->
                <!-- selectedActionOptionsError -->
                <div
                  class="alert alert-warning mt-3"
                  role="alert"
                  v-if="selectedActionOptionsError"
                >
                  <p>Ops!</p>
                  <p>Você ja escolheu opções ou uma saida para este bloco.</p>
                  <p>Quer que este bloco finalize a interação do bot?</p>

                  <strong
                    class="text-primary pointer"
                    @click="confirm_exclusiveToEnd()"
                    >Sim</strong
                  >
                  <strong
                    class="ml-3 pointer"
                    @click="selectedActionOptionsError = false"
                    >Não</strong
                  >
                </div>

                <div
                  class="alert alert-primary mt-3"
                  role="alert"
                  v-if="help_with_endBlock"
                >
                  Se essa opção estiver ativo o seu cliente ira receber a
                  mensagem deste bloco, logo depois o bot encerra a interação.
                  <br />
                  <hr />
                  <strong>Sempre utilize esta função no ultimo bloco !</strong>
                </div>
                <!--------------------------- action functions-------------------------->
                <div class="option-settings-container" v-if="openFunctions">
                  <!-- action functions -->
                  <div class="w-100 slide-in-right ">
                    <div
                      class="w-100 scale-in-center"
                      id="step-0"
                      ref="actionBlockStep_0"
                      v-show="step == 'selectActionType'"
                    >
                      <div class="d-flex flex-column mt-5" id="fncBefore">
                        <div>
                          <strong>AÇÕES DE ENTRADA</strong>
                        </div>
                        <small class="mt-2">
                          Inclua ações que serão executadas antes do envio do
                          primeiro conteúdo.
                        </small>
                        <button
                          @click="addActionBefore()"
                          id="actionBefor"
                          class="btn btn-primary  mt-3 dropdown-toggle dropdown-toggle-split "
                          data-toggle="dropdown"
                          aria-haspopup="true"
                          aria-expanded="false"
                          data-reference="parent"
                        >
                          + Adiconar ação de entrada
                        </button>
                        <div
                          class="dropdown-menu"
                          aria-labelledby="actionBefor"
                        >
                          <a
                            class="dropdown-item"
                            href="#"
                            @click.prevent="selectActionType('http_request')"
                            >Requisição HTTP</a
                          >
                        </div>
                      </div>
                      <div class="d-flex flex-column mt-5" id="fncAfter">
                        <div>
                          <strong>AÇÕES DE SAÍDA</strong>
                        </div>
                        <small class="mt-2"
                          >Inclua ações que serão executadas após o envio do
                          último conteúdo ou resposta do usuário.</small
                        >
                        <button
                          id="actionAfter"
                          class="btn btn-primary mt-3 "
                          @click="addActionAfter()"
                          data-toggle="dropdown"
                          aria-haspopup="true"
                          aria-expanded="false"
                          data-reference="parent"
                        >
                          + Adicionar ação de saída
                        </button>
                        <div
                          class="dropdown-menu"
                          aria-labelledby="actionAfter"
                        >
                          <a
                            class="dropdown-item"
                            href="#"
                            @click.prevent="selectActionType('http_request')"
                            >Requisição HTTP</a
                          >
                        </div>
                      </div>
                    </div>
                    <!-- placeholder for function -->
                    <div
                      class="accordion"
                      id="accordionExample"
                      v-if="step === 'Request'"
                    >
                      <div class="card">
                        <div
                          class="card-header border-default "
                          id="headingOne"
                        >
                          <div
                            class="d-flex justify-content-between align-items-baseline cursor"
                            type="button"
                            data-toggle="collapse"
                            data-target="#collapseOne"
                            aria-expanded="true"
                            aria-controls="collapseOne"
                          >
                            <h2>
                              <button
                                class="btn btn-link btn-block text-left"
                                type="button"
                                data-toggle="collapse"
                                data-target="#collapseOne"
                                aria-expanded="true"
                                aria-controls="collapseOne"
                              >
                                Headers
                              </button>
                            </h2>
                            <div>Quantidade: {{ countHeaders() }}</div>
                          </div>
                        </div>

                        <div
                          id="collapseOne"
                          class="collapse show border-default-inner"
                          aria-labelledby="headingOne"
                          data-parent="#accordionExample"
                        >
                          <div class="card-body">
                            <div class="d-flex justify-content-end">
                              <div
                                class="w-100 d-flex justify-content-between align-items-baseline"
                              >
                                <small>
                                  <p>
                                    Para salvar ou clique no icone
                                    <i class="far fa-save text-primary"></i>

                                    ou enter. <br />
                                    Para editar clique 2 vezes na chave ou no
                                    valor.
                                  </p>
                                </small>
                                <div>
                                  <button
                                    v-if="validHeader()"
                                    class="btn"
                                    @click="saveHeader()"
                                  >
                                    <i class="far fa-save"></i>
                                  </button>

                                  <button
                                    v-if="!addNewHeader"
                                    class="btn"
                                    @click="toggleCreateHeader()"
                                  >
                                    +
                                  </button>
                                  <button
                                    v-if="addNewHeader"
                                    class="btn"
                                    @click="toggleCreateHeader()"
                                  >
                                    x
                                  </button>
                                </div>
                              </div>
                            </div>
                            <ul class="list-group mt-2">
                              <li class="list-group-item" v-if="addNewHeader">
                                <div
                                  class="d-flex justify-content-around align-items-end"
                                >
                                  <div>
                                    <label for=""><small>Chave</small></label>
                                    <input
                                      v-model="newHeader.key"
                                      type="text"
                                      class="form-control"
                                      @keypress.enter="
                                        toggleFocus('headerValue')
                                      "
                                    />
                                  </div>
                                  <div>
                                    <label for=""><small>Valor</small></label>
                                    <input
                                      ref="headerValue"
                                      v-model="newHeader.value"
                                      type="text"
                                      class="form-control"
                                      @keypress.enter="saveHeader()"
                                    />
                                  </div>
                                </div>
                              </li>
                              <li
                                class="list-group-item border border-default"
                                v-for="(head,
                                index) in selectedActionRequestHeaders"
                                :key="index"
                              >
                                <div class="w-100 d-flex justify-content-end">
                                  <button
                                    class="btn"
                                    @click="removeHeader(index)"
                                  >
                                    x
                                  </button>
                                </div>
                                <div class="d-flex flex-column ">
                                  <div>
                                    <label
                                      for=""
                                      @dblclick="editThisHeader(index, 'key')"
                                      ><small>Chave</small></label
                                    >
                                    <div
                                      @dblclick="editThisHeader(index, 'key')"
                                      v-if="
                                        isEditingHeader === 'key' &&
                                        index === headerIndex &&
                                        isEditingHeader
                                          ? false
                                          : true
                                      "
                                    >
                                      <button class="btn">
                                        {{ head.key }}
                                      </button>
                                    </div>
                                  </div>
                                  <div
                                    v-if="
                                      isEditingHeader === 'key' &&
                                        index === headerIndex &&
                                        isEditingHeader
                                    "
                                  >
                                    <input
                                      id="editHeadKeyInput"
                                      v-model="head.key"
                                      type="text"
                                      class="form-control"
                                      @blur="checkIfFouced()"
                                      @keypress.enter="checkIfFouced()"
                                    />
                                  </div>
                                </div>
                                <div class="d-flex flex-column">
                                  <div>
                                    <label
                                      for=""
                                      @dblclick="editThisHeader(index, 'value')"
                                      ><small>Valor</small></label
                                    >
                                    <div
                                      @dblclick="editThisHeader(index, 'value')"
                                      v-if="
                                        isEditingHeader === 'value' &&
                                        index === headerIndex &&
                                        isEditingHeader
                                          ? false
                                          : true
                                      "
                                    >
                                      <button class="btn">
                                        {{ head.value }}
                                      </button>
                                    </div>
                                  </div>
                                  <div
                                    v-if="
                                      isEditingHeader === 'value' &&
                                        index === headerIndex &&
                                        isEditingHeader
                                    "
                                  >
                                    <input
                                      id="editHeadValueInput"
                                      v-model="head.value"
                                      type="text"
                                      class="form-control"
                                      @blur="checkIfFouced()"
                                      @keypress.enter="checkIfFouced()"
                                    />
                                  </div>
                                </div>
                              </li>
                            </ul>
                          </div>
                        </div>
                      </div>
                      <div class="card">
                        <div
                          class="card-header border-default "
                          id="headingTwo"
                        >
                          <h2 class="mb-0">
                            <button
                              @click="isAdding = 'request'"
                              class="btn btn-link btn-block text-left collapsed "
                              type="button"
                              data-toggle="collapse"
                              data-target="#collapseTwo"
                              aria-expanded="false"
                              aria-controls="collapseTwo"
                            >
                              Request
                            </button>
                          </h2>
                        </div>
                        <div
                          id="collapseTwo"
                          class="collapse border-default-inner"
                          aria-labelledby="headingTwo"
                          data-parent="#accordionExample"
                          v-if="isAdding === 'request'"
                        >
                          <div class="card-body">
                            <div class="form-row">
                              <div class="form-group col-md-9">
                                <label for="url_">Url</label>
                                <input
                                  id="url_"
                                  type="text"
                                  name=""
                                  v-model="
                                    selectedAction.function.request[
                                      functionExecs
                                    ].url
                                  "
                                  class="form-control "
                                  placeholder="endpoint"
                                  aria-describedby="helpId"
                                />
                              </div>
                              <div class="form-group col-md-3">
                                <label for="method_">Method</label>
                                <select
                                  id="method_"
                                  v-model="
                                    selectedAction.function.request[
                                      functionExecs
                                    ].type
                                  "
                                  class="form-control"
                                >
                                  <option value="GET">GET</option>
                                  <option value="POST">POST</option>
                                  <!-- <option value="PUT">PUT</option>
                                  <option value="DELETE">DELETE</option> -->
                                </select>
                              </div>
                            </div>
                            <div class="form-group">
                              <label for="varPayload">Payload</label>
                              <button class="btn" @click="addPayload()">
                                +
                              </button>
                              <ul
                                class="list-group"
                                v-if="
                                  selectedAction.function.request[functionExecs]
                                    .payload.length
                                "
                              >
                                <li
                                  class="list-group-item"
                                  v-for="(payload,
                                  payloadIndex) in selectedAction.function
                                    .request[functionExecs].payload"
                                  :key="payloadIndex"
                                >
                                  <button
                                    class="btn float-right"
                                    @click="removePayload(payloadIndex)"
                                  >
                                    x
                                  </button>
                                  <div
                                    class="d-flex justify-content-around align-items-end"
                                  >
                                    <div>
                                      <label for=""><small>Chave</small></label>
                                      <input
                                        type="text"
                                        class="form-control"
                                        v-model="
                                          selectedAction.function.request[
                                            functionExecs
                                          ].payload[payloadIndex].key
                                        "
                                      />
                                    </div>
                                    <div>
                                      <label for=""><small>Valor</small></label>
                                      <input
                                        type="text"
                                        class="form-control"
                                        v-model="
                                          selectedAction.function.request[
                                            functionExecs
                                          ].payload[payloadIndex].value
                                        "
                                      />
                                    </div>
                                  </div>
                                </li>
                              </ul>
                            </div>
                            <hr />
                            <div class="form-group">
                              <strong for="">Salvar resposta </strong>
                              <input
                                type="text"
                                class="form-control mt-2"
                                placeholder="Variável para o corpo da resposta"
                                aria-describedby="helpId"
                                v-model="
                                  selectedAction.function.request[functionExecs]
                                    .response_var
                                "
                              />
                              <small
                                >Variável para o corpo da resposta no formato
                                JSON</small
                              >
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <!-- placeholder for function -->
                  </div>
                  <!-- action functions -->

                  <div
                    class="alert alert-warning mt-3"
                    role="alert"
                    v-if="confirmTransfer"
                  >
                    <p>Ops!</p>
                    <p>Você ja escolheu para este bloco finalizar.</p>
                    <p>
                      Para usar a opção de transferir, não pode finalizar o
                      chamado neste bloco.
                    </p>
                    <p>Deseja usar a opção de transferir?</p>

                    <strong
                      class="text-primary pointer"
                      @click="confirmsTheTransfer()"
                      >Sim</strong
                    >
                    <strong class="ml-3 pointer" @click="denyTheTransfer()"
                      >Não</strong
                    >
                  </div>
                  <!-- Confirm transfer -->
                </div>
                <!-- ------------------------- action function-------------------------  -->
              </div>
              <!-- action option  -->
              <div
                class="mt-3"
                :class="[
                  { 'action-ops-container': selectedAction.options.length > 3 },
                ]"
              >
                <ul class="list-group ">
                  <li
                    class="list-group-item  flex-column"
                    v-for="(sel_ops, selActionIndex) in selectedAction.options"
                    :key="selActionIndex"
                  >
                    <div
                      class="w-100 d-flex  align-items-center  justify-content-between"
                    >
                      <span class="d-flex w-100"
                        ><strong>{{ sel_ops.option }} - </strong
                        >{{ sel_ops.label }}
                      </span>
                      <select
                        class="form-control "
                        style="width:80%"
                        v-model="sel_ops.to_block"
                      >
                        <option
                          value=""
                          disabled
                          v-if="actionBlocksToSelect.length < 1"
                          >Crie mais um bloco</option
                        >

                        <option
                          v-for="(menu, menuIndex) in actionBlocksToSelect"
                          :key="menuIndex"
                          :value="menu.block_id"
                        >
                          <span v-if="!menu.label">
                            Bloco - {{ menu.block_id }}
                          </span>
                          <span v-if="menu.label">{{ menu.label }}</span>
                        </option>
                      </select>
                      <button
                        class="btn ml-1"
                        @click.prevent="findActionBlockInList(sel_ops.to_block)"
                      >
                        <i
                          class="fas fa-search-location"
                          style="font-size: 20px"
                        ></i>
                      </button>
                    </div>
                  </li>
                </ul>
              </div>
              <hr />
              <div class="form-group" v-if="!selectedAction.end">
                <label for="">Salvar resposta do cliente em uma variavel</label>
                <input
                  class="form-control"
                  type="text"
                  placeholder="varResCliente"
                  v-model="selectedAction.clientResponseVar"
                />
              </div>
            </div>
            <strong v-if="selectedAction.block_id && selectedAction.options < 1"
              >Nenhuma opção neste bloco</strong
            >
          </div>
        </div>
      </div>
    </div>
  </span>
</template>

<script>
  import { mapActions, mapGetters } from "vuex";
  import ActionWindow from "@/components/botConfigs/ActionWindow";

  export default {
    name: "Bot",
    components: {
      ActionWindow,
    },
    data() {
      return {
        exitError: false,
        newHeader: {
          key: null,
          value: null,
        },
        headerIndex: null,
        isEditingHeader: null,
        bot_id: null,
        defaults: [
          { message: "Desculpe {nome}.\nNão encontramos esta opção.", type: 2 },
          { message: "{nome} muito obrigado.", type: 3 },
        ],
        botName: null,
        isThisBotDefault: false,
        actions: [],
        selectedAction: {
          willHaveExit: false,
          clientResponseVar: null,
          label: null,
          block_id: null,
          end: false,
          message: "",
          options: [],
          exits: null,
          transfers: {
            // to transfer ticket to human
            isAble: false,
            to: [],
            departments: [],
          },
          function: {
            actionType: null,
            request: {
              before: {
                payload: [],
                headers: [],
                url: null,
                type: null,
                response_var: null,
              },
              after: {
                payload: [],
                headers: [],
                url: null,
                type: null,
                response_var: null,
              },
            },
          },
        },
        isNamingBot: false,
        help_with_endBlock: false,
        selectedActionOptionsError: false,
        blockswithEmptyOptions: [],
        expandedMsgDefault: false,
        changingBlocksName: false,
        blockForEditing: {
          block_id: null,
        },
        loaded: false,
        confirmTransfer: false,
        openFunctions: false,
        step: "selectActionType",
        addNewHeader: false,
        functionExecs: null,
        isAdding: false,
      };
    },
    computed: {
      ...mapGetters(["bot"]),
      actionBlocksToSelect() {
        return this.actions.filter((action) => {
          return action.block_id !== this.selectedAction.block_id;
        });
      },
      selectedActionRequestHeaders() {
        let whenExecs = this.functionExecs;
        if (whenExecs) {
          return this.selectedAction.function.request[whenExecs].headers;
        }
      },
      hasHumanContact() {
        return this.actions.some((ax) => {
          return ax.willSendToHuman;
        });
      },
    },
    mounted() {
      console.log(this.$route);
      if (!this.$masterHelper.isEmpty(this.$route.params)) {
        this.GET_BOT(this.$route.params.id);

        setTimeout(() => {
          if (!this.bot) {
            this.$router.push({ name: "bots" });
          }
          // console.log(this.bot.actions);
          this.loadData();
        }, 5000);
      } else {
        this.loaded = true;
      }
      this.$event.$on("functionSave", (data) => {
        this.selectedAction.function = data;
        this.selectedOptionSettings = null;
        this.$forceUpdate();
      });

      this.$event.$on("check_functions_in_block", () => {
        this.$event.$emit("block_function_res", this.selectedAction.function);
        this.$forceUpdate();
      });
    },
    methods: {
      ...mapActions(["SAVE_BOT", "GET_BOT", "UPDATE_BOT", "SELECT_FUNCTION"]),
      checkIfFouced() {
        this.isEditingHeader = null;
        this.headerIndex = null;
      },
      loadData() {
        if (!this.$masterHelper.isEmpty(this.$route.params)) {
          this.loaded = true;
          this.actions = this.bot.actions;
          this.defaults = this.bot.defaults;
          this.botName = this.bot.botName;
          this.isThisBotDefault = this.bot.isThisBotDefault;
          this.bot_id = this.$route.params.id;
        }
      },
      changeBlockName(block) {
        this.changingBlocksName = true;
        this.blockForEditing.block_id = block.block_id;
      },
      findActionBlockInList(id) {
        if (id) {
          var element = document.getElementById("block__" + id);
          console.log("findActionBlockInList::element", element);
          element.classList.add("found");
          setTimeout(() => {
            element.classList.remove("found");
          }, 3000);
          element.scrollIntoView({ behavior: "smooth" });
        }
      },
      editingBlockName(id) {
        return this.changingBlocksName && this.blockForEditing.block_id === id;
      },
      saveBlockName(block) {
        // if (this.blockForEditing.block_id === this.selectedAction.block_id) {
        //   this.selectedAction.label = block.label;
        // }
        this.changingBlocksName = false;
        this.blockForEditing.block_id = null;
      },
      addActionBlock(obj = null) {
        var m_id = this.getMenuIndexToIdentity();
        if (!obj) {
          this.actions.push({
            willSendToHuman: false,
            willHaveExit: false,
            clientResponseVar: null,
            label: null,
            block_id: m_id,
            end: false,
            message: "mensagem",
            options: [],
            exits: null,
            transfers: {
              isAble: false,
              to: [],
              departments: [],
            },
            function: {
              actionType: null,
              request: {
                before: {
                  payload: [],
                  headers: [],
                  url: null,
                  type: null,
                  response_var: null,
                },
                after: {
                  payload: [],
                  headers: [],
                  url: null,
                  type: null,
                  response_var: null,
                },
              },
            },
          });
        } else {
          this.actions.push(obj);
        }
      },
      removeActionBlock(index) {
        if (this.actions[index].block_id === this.selectedAction.block_id) {
          this.closeSettings();
        }
        this.actions.splice(index, 1);
      },
      addActionOption(action = null, index) {
        this.actions[index].exits = null;
        this.actions[index].willHaveExit = false;

        this.selectedOptionSettings = null;
        this.openFunctions = false;
        if (this.actions[index].end) {
        } else {
          var action_length = this.actions[index].options.length;
          var option_num = action_length < 1 ? 1 : action_length + 1;
          console.log(" option_num", option_num);
          this.actions[index].options.push({
            to_block: null,
            option: option_num,
            label: "mensagem da opção" + option_num,
          });
        }
      },
      removeActionOption(index, opIndex) {
        this.actions[index].options.splice(opIndex, 1);
        this.actions[index].options.forEach((option, opIndex) => {
          var number = opIndex < 1 ? 1 : opIndex + 1;
          option.option = number;
        });
      },
      getMenuIndexToIdentity() {
        if (this.actions.length < 1) {
          return 1;
        } else {
          return this.actions[this.actions.length - 1].block_id + 1;
        }
      },
      actionSettings(action, index) {
        action.index = index;
        this.step = "selectActionType";
        this.openFunctions = false;
        this.selectedAction = {};
        this.selectedOptionSettings = null;
        setTimeout(() => {
          this.selectedAction = action;
        }, 300);
      },
      closeSettings() {
        this.selectedAction = {};
      },
      selectedMenu(id) {
        return (
          this.selectedAction.block_id && this.selectedAction.block_id === id
        );
      },
      nameTheBot() {
        this.isNamingBot = true;
        setTimeout(() => {
          this.$refs.botNameInput.focus();
        }, 100);
      },
      saveBotName() {
        this.isNamingBot = false;
      },
      clearErrors() {
        //https://clubmate.fi/remove-a-class-name-from-multiple-elements-with-pure-javascript
        var blockWithError = document.getElementsByClassName("blockWithError");
        var optionWithError = document.getElementsByClassName(
          "optionWithError"
        );

        while (blockWithError[0]) {
          blockWithError[0].classList.remove("blockWithError");
        }
        while (optionWithError[0]) {
          optionWithError[0].classList.remove("optionWithError");
        }
      },
      checkForEmptyOptions() {
        this.clearErrors();

        setTimeout(() => {
          var blockswithEmptyOptions = [];
          this.actions.forEach((action) => {
            if (action.options && action.options.length > 0) {
              action.options.forEach((action_option, index) => {
                var number = index < 1 ? 1 : index + 1;
                if (!action_option.to_block) {
                  blockswithEmptyOptions.push({
                    block_id: action.block_id,
                    option: number,
                  });
                  document
                    .getElementById("block__" + action.block_id)
                    .classList.add("blockWithError");
                  document
                    .getElementById(
                      "block_option_" + number + "_block_" + action.block_id
                    )
                    .classList.add("optionWithError");
                }
              });
            }
          });
          this.blockswithEmptyOptions = blockswithEmptyOptions;
          if (blockswithEmptyOptions.length === 0) {
            this.saveBotConfigs();
          }
          console.log("errors in blocks", blockswithEmptyOptions);
        }, 1000);
      },
      toggleEndBlockHelp() {
        this.help_with_endBlock = !this.help_with_endBlock;
      },
      exclusiveToEnd() {
        var options = this.selectedAction.options.length;

        if (options > 0 || this.selectedAction.willHaveExit) {
          setTimeout(() => {
            this.selectedAction.end = false;
          }, 200);
          this.selectedActionOptionsError = true;
        }
      },
      confirm_exclusiveToEnd() {
        this.selectedAction.end = true;
        this.selectedAction.exits = null;
        this.selectedAction.willHaveExit = false;
        this.selectedAction.options = [];
        this.selectedActionOptionsError = false;
      },
      saveBotConfigs() {
        var bot = {
          label: this.botName,
          default: this.isThisBotDefault,
          actions: this.actions,
          defaults: this.defaults,
        };
        if (this.bot_id !== null) {
          bot.bot_id = this.bot_id;
          this.UPDATE_BOT(bot);
        } else {
          this.bot_id = null;
          this.SAVE_BOT(bot);
        }

        console.log("bot::", bot);
        this.$router.push({ name: "bots" });
      },
      toggleMsgDefault() {
        this.expandedMsgDefault = !this.expandedMsgDefault;
      },
      makeTransfer(to = null) {},
      isAbleToTransfer() {
        if (this.selectedAction.end) {
          this.selectedAction.transfers.isAble = false;
          this.confirmTransfer = true;
        } else {
          this.selectedAction.transfers.isAble = !this.selectedAction.transfers
            .isAble;
        }
      },
      confirmsTheTransfer() {
        this.confirmTransfer = false;
        this.selectedAction.end = false;
        this.isAbleToTransfer();
      },
      denyTheTransfer() {
        this.confirmTransfer = false;
        this.selectedAction.end = true;
      },
      openOptionSettings() {
        this.openFunctions = !this.openFunctions;

        // if (
        //   this.selectedAction.function.request[this.functionExecs]?.url?.length >
        //   2
        // ) {
        //   this.step = "Request";
        // } else {
        //   this.selectedOptionSettings = null;
        //   this.step = "selectActionType";
        // }

        // this.$forceUpdate();
      },
      addActionBefore() {
        this.functionExecs = "before";
        this.$forceUpdate();
      },
      addActionAfter() {
        this.functionExecs = "after";
        this.$forceUpdate();
      },
      selectActionType(type) {
        this.selectedAction.function.actionType = type;
        this.step = "Request";
        this.$forceUpdate();
      },
      goBack() {
        this.step = "selectActionType";
      },
      saveFunction() {
        this.selectedOptionSettings = null;
        this.step = "selectActionType";
        this.$forceUpdate();
      },
      toggleCreateHeader() {
        this.addNewHeader = !this.addNewHeader;
        this.newHeader = {
          key: null,
          value: null,
        };
      },
      toggleFocus(ref) {
        //use refs
        console.log("this.$refs[ref]", this.$refs[ref]);
        this.$refs[ref].focus();
      },
      saveHeader() {
        console.log("this.newHeader.key", this.newHeader.key);
        console.log("this.newHeader.value", this.newHeader.value);

        let obj = {
          key: this.newHeader.key,
          value: this.newHeader.value,
        };
        let whenExecs = this.functionExecs;
        this.selectedAction.function.request[whenExecs].headers.push(obj);
        console.log(
          "action headers",
          this.selectedAction.function.request[whenExecs].headers
        );
        this.toggleCreateHeader();
        setTimeout(() => {
          this.newHeader = {
            key: null,
            value: null,
          };
        }, 1000);
      },
      editThisHeader(index, what) {
        this.headerIndex = index;
        this.isEditingHeader = what;
        setTimeout(() => {
          if (what === "key") {
            document.getElementById("editHeadKeyInput").focus();
          }
          if (what === "value") {
            document.getElementById("editHeadValueInput").focus();
          }
        }, 1000);
        this.$forceUpdate();
      },
      validHeader() {
        return (
          this.newHeader.key &&
          this.newHeader.value &&
          this.newHeader.key.length &&
          this.newHeader.value.length
        );
      },
      hideHeaderText(index, what) {
        // !isEditingHeader&&
        //  headerIndex == index &&
        // ' !isEditingHeader
        let isWhat =
          this.isEditingHeader === what &&
          index === this.headerIndex &&
          this.isEditingHeader;
        return isWhat ? false : true;
      },
      countHeaders() {
        return (
          this.selectedAction.function.request.before.headers.length +
          this.selectedAction.function.request.after.headers.length
        );
      },
      removeHeader(index) {
        this.selectedAction.function.request[this.functionExecs].headers.splice(
          index,
          1
        );
      },
      addExitToBlock(confirm = false) {
        if (!this.selectedAction.exits) {
          if (!confirm) {
            if (this.selectedAction.options.length > 0) {
              //show message error
              this.exitError = true;
            } else {
              this.selectedAction.willHaveExit = true;
              this.selectedAction.options = [];
              this.exitError = false;
            }
          } else {
            this.selectedAction.willHaveExit = true;
            this.selectedAction.options = [];
            this.exitError = false;
          }
        }
        this.$forceUpdate();
      },
      addPayload() {
        this.selectedAction.function.request[this.functionExecs].payload.push({
          key: null,
          value: null,
        });
      },
      removePayload(index) {
        console.log("removePayload", index);
        this.selectedAction.function.request[this.functionExecs].payload.splice(
          index,
          1
        );
      },
      transferToHuman() {
        //adiciona novo bloco de ação
        this.actions.some((ax) => {
          return ax.sendToHuman;
        });
      },
      toggleExtraFunctions() {
        var m_id = this.getMenuIndexToIdentity();
        let obj = {
          willSendToHuman: true,
          willHaveExit: false,
          clientResponseVar: null,
          label: "Atendimento humano",
          block_id: m_id,
          end: false,
          message: "{nome}, aguarde. \nEm breve um atendente ira te chamar.",
          options: [],
          exits: null,
          transfers: {
            isAble: false,
            to: [],
            departments: [],
          },
          function: {
            actionType: null,
            request: {
              before: {
                payload: [],
                headers: [],
                url: null,
                type: null,
                response_var: null,
              },
              after: {
                payload: [],
                headers: [],
                url: null,
                type: null,
                response_var: null,
              },
            },
          },
        };
        let hasOne = this.actions.some((ax) => {
          return ax.willSendToHuman == true;
        });
        console.log("has one? ", hasOne);
        !hasOne ? this.addActionBlock(obj) : false;
      },
    },
  };
</script>

<style lang="scss" scoped>
  .scrollable-container {
    height: 65vh;
    overflow-y: auto;
    &::-webkit-scrollbar {
      width: 8px; /* width of the entire scrollbar */
      background-color: rgb(211, 211, 211);
    }
    &::-webkit-scrollbar-thumb {
      background-color: rgb(177, 177, 177); /* color of the scroll thumb */
      border-radius: 20px; /* roundness of the scroll thumb */
      // border: 3px solid orange; /* creates padding around scroll thumb */
    }
  }

  .selected-menu {
    background-color: #5f76e8 !important;
    color: #fff !important;

    svg {
      color: #fff !important;
    }
  }

  .found {
    border: 1px solid #5f76e8;
    -webkit-box-shadow: 0px 0px 15px 0px #5f76e859;
    box-shadow: 0px 0px 15px 0px #5f76e859;
  }
  .blockWithError {
    border: 1.5px solid red !important ;
  }
  .optionWithError {
    border: 1.5px solid red !important ;
  }
  .errors-container {
    height: 7vh;
    overflow-y: auto;
    &::-webkit-scrollbar {
      width: 8px; /* width of the entire scrollbar */
    }
    &::-webkit-scrollbar-thumb {
      background-color: rgb(204, 204, 204); /* color of the scroll thumb */
      border-radius: 20px; /* roundness of the scroll thumb */
      border: 1px solid rgba(158, 158, 158, 0.589); /* creates padding around scroll thumb */
    }
    &::-webkit-scrollbar-track {
      background: rgba(255, 255, 255, 0.589); /* color of the tracking area */
    }
  }

  .action-ops-container {
    height: 25vh;
    overflow-y: auto;
    &::-webkit-scrollbar {
      width: 8px; /* width of the entire scrollbar */
    }
    &::-webkit-scrollbar-thumb {
      background-color: rgb(204, 204, 204); /* color of the scroll thumb */
      border-radius: 20px; /* roundness of the scroll thumb */
      // border: 1px solid rgb(235, 235, 235); /* creates padding around scroll thumb */
    }
    // &::-webkit-scrollbar-track {
    //   background: rgba(158, 158, 158, 0.589); /* color of the tracking area */
    // }
  }

  .page-loader {
    height: 70vh;
  }
  .border-default {
    border: 1px solid #e9ecef !important;
  }
  .border-default-inner {
    border: 1px solid #e9ecef !important;

    border-top: unset !important;
  }
  .action-settings-option-wrapper {
    // d-flex justify-content-end
    margin-top: 0.5em;
    display: flex;
    justify-content: flex-end;
  }
</style>
