<template>
  <div>
    <div class="row">
      <div class="col">
        <div class="card">
          <div
            class="card-header d-flex justify-content-between align-items-center"
          >
            <div
              class="d-flex  justify-content-between align-items-center col-6"
            >
              Contatos

              <input
                v-model="search"
                type="text"
                class="form-control ml-3 "
                placeholder="Pesquise por Nome, número ou cpf"
              />
            </div>
            <div
              class="d-flex justify-content-between align-items-center "
              v-has:access.permission="'import-patient'"
            >
              <ImportButton />
              <button
                class="btn btn-primary  ml-3"
                data-toggle="modal"
                data-target="#contactModal"
                @click="
                  isEditing = false;
                  isCreating = true;
                "
              >
                Criar novo
              </button>
            </div>
          </div>
          <div class="card-body">
            <table class="table">
              <thead>
                <tr>
                  <th class="text-primary">Id</th>
                  <th>Nome</th>
                  <th>Numero</th>
                  <th>CPF</th>
                </tr>
              </thead>
              <tbody class="scroll-container">
                <tr v-if="contatos.lenght < 1">
                  <h4><strong>Carregando...</strong></h4>
                </tr>
                <tr
                  v-for="(patient, index) in filterCore"
                  :key="index"
                  @click="editPatient(patient)"
                  class="pointer"
                  :class="[{ selected: isEditingThisPatient(patient.id) }]"
                >
                  <td scope="row">{{ patient.id }}</td>
                  <td>{{ patient.name }}</td>
                  <td>{{ patient.number }}</td>
                  <td>{{ patient.cpf }}</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
    <portal to="modals">
      <ContactModal :isCreating="isCreating" :isEditing="isEditing" />
    </portal>
    <!-- <div
      class="modal fade"
      id="createPatient"
      tabindex="-1"
      aria-labelledby="createPatientLabel"
      aria-hidden="true"
    >
      <div
        class="modal-dialog  modal-right 
      "
        style="width:20%"
      >
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title">Novo Paciente</h5>
            <button
              type="button"
              class="close"
              data-dismiss="modal"
              aria-label="Close"
              @click="clear()"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <form>
              <div class="form-group">
                <label for="name">Nome</label>
                <input
                  type="text"
                  class="form-control"
                  id="name"
                  aria-describedby="name"
                  v-model="patient.name"
                  :disabled="!isEditing && !isCreating"
                />
              </div>
              <div class="form-group">
                <label for="cpf">CPF</label>
                <input
                  type="text"
                  class="form-control"
                  id="cpf"
                  aria-describedby="cpf"
                  v-model="patient.cpf"
                  :disabled="!isEditing && !isCreating"
                />
              </div>
              <div class="form-group">
                <label for="number">Número</label>
                <input
                  type="text"
                  class="form-control"
                  id="number"
                  v-model="patient.number"
                  :disabled="!isEditing && !isCreating"
                />
                <small id="numberHelp" class="form-text text-muted"
                  >Sempre adicione o DDI
                </small>
                <small> Ex: 551199999999</small>
              </div>
            </form>
          </div>
          <div class="modal-footer" v-has:access.permission="'import-patient'">
            <button
              type="button"
              class="btn btn-secondary"
              data-dismiss="modal"
            >
              Close
            </button>
            <button
              type="button"
              class="btn btn-primary"
              @click="savePatient()"
            >
              Salvar
            </button>
          </div>
        </div>
      </div>
    </div> -->
  </div>
</template>

<script>
  import { mapGetters, mapActions } from "vuex";
  import ImportButton from "@/components/importing/ImportingExcel";
  import ContactModal from "@/components/modals/ContactModal";
  export default {
    name: "PatientTable",
    components: {
      ImportButton,
      ContactModal,
    },
    data() {
      return {
        patient: {
          name: "",
          cpf: "",
          number: "",
          id: null,
        },
        isEditing: false,
        isCreating: false,

        search: "",
      };
    },
    computed: {
      ...mapGetters(["contatos", "loggedUser"]),
      filterCore() {
        if (this.search.length > 0) {
          console.log("this.search", this.search);
          return this.contatos.filter((contact) => {
            return (
              contact.name.toLowerCase().includes(this.search.toLowerCase()) ||
              (contact.cpf && contact.cpf.includes(this.search)) ||
              contact.number.includes(this.search)
            );
          });
        } else {
          return this.contatos;
        }
      },
    },
    mounted() {
      this.closeModal();
    },
    methods: {
      ...mapActions(["SAVE_CONTACT", "UPDATE_CONTACT", "SELECT_CONTATO"]),
      closeModal() {
        var that = this;
        $("#createPatient").on("hide.bs.modal", function(e) {
          that.clear();
        });
      },
      clear() {
        this.patient = {
          name: "",
          cpf: "",
          number: "",
        };
      },
      savePatient() {
        var canSave = true;
        if (this.patient.number.length < 8) {
          canSave = false;
          this.$toast.error(" ", "Numero invalido, curto demais", {
            icon: "icon-person",
            position: "topRight",
            timeout: 8000,
          });
        }
        if (this.patient.cpf.length < 10) {
          canSave = false;
          this.$toast.error(" ", "Cpf invalido, curto demais", {
            icon: "icon-person",
            position: "topRight",
            timeout: 8000,
          });
        }
        if (canSave) {
          if (this.isEditing) {
            this.UPDATE_CONTACT(this.patient);
            this.$toast.success(" ", "Contato atualizado", {
              icon: "icon-person",
              position: "topRight",
              timeout: 8000,
            });
          } else {
            this.SAVE_CONTACT(this.patient);
            this.$toast.success(" ", "Contato criado", {
              icon: "icon-person",
              position: "topRight",
              timeout: 8000,
            });
          }

          this.clear();
          $("#createPatient").modal("hide");
        }
      },
      editPatient(patient) {
        let hasit = this.$masterHelper.hasPermission(
          this.loggedUser,
          "import-patient"
        );
        if (hasit) {
          this.isEditing = true;
        }
        this.SELECT_CONTATO(this.clearObj(patient)).then(() => {
          this.$event.$emit("select-contact");
          $("#contactModal").modal("show");
        });
      },
      clearObj(patient) {
        const { id, cpf, name, number } = patient;
        return {
          id,
          cpf,
          name,
          number,
        };
      },
      isEditingThisPatient(id) {
        return this.patient.id === id;
      },
    },
  };
</script>
<style lang="scss">
  .modal-right .modal-content {
    height: 100vh;
  }
  tr.pointer {
    &:hover {
      background-color: rgba(188, 211, 255, 0.2);
      color: black;
    }
  }
  .selected {
    background-color: rgba(188, 211, 255, 0.2);
    color: black;
  }
  tbody.scroll-container {
    height: 50vh !important;
    display: block;
    overflow: auto;
  }

  thead,
  tbody tr {
    display: table;
    width: 100%;
    table-layout: fixed; /* even columns width , fix width of table too*/
  }
</style>
