<template>
  <div class="card">
    <div class="card-header d-flex justify-content-between">
      <div>
        Tickets
      </div>

      <div class="d-flex align-self-center">
        <span
          class="text-danger mr-5 pointer"
          @click.prevent="closeAllTickets()"
          v-if="!closeTicket.isClosing"
        >
          Encerrar todos os tickets
        </span>
        <div class="d-flex w-100" v-if="closeTicket.isClosing">
          <div class="spinner-border text-primary mr-3" role="status">
            <span class="sr-only">Loading...</span>
          </div>
          <strong>{{ closeTicket.message }}</strong>
        </div>
      </div>
    </div>
    <div class="card-body">
      <div v-if="!tickets.length">
        <h4>Nenhum ticket criado</h4>
      </div>
      <table class="table" v-if="tickets.length">
        <thead>
          <tr>
            <th scope="col">Id</th>
            <th scope="col">Status</th>
            <th scope="col">Paciente</th>
            <th scope="col">Atendente</th>
            <th scope="col">Início</th>
            <th scope="col">Fim</th>
          </tr>
        </thead>
        <tbody
          v-if="loading"
          style="min-height:57vh"
          class=" w-100 d-flex align-items-center justify-content-center"
        >
          <div class="spinner-border text-primary" role="status">
            <span class="sr-only">Loading...</span>
          </div>
        </tbody>
        <tbody v-if="!loading">
          <tr v-for="(ticket, index) in tableTickets" :key="index">
            <th scope="row">{{ ticket.id }}</th>
            <td>
              <span class="badge badge-success">{{ ticket.status }}</span>
            </td>
            <td>{{ ticket.patient_id }}</td>
            <td>{{ ticket.operator_id }}</td>
            <td>{{ ticket.started_at }}</td>
            <td>{{ ticket.closed_at }}</td>
          </tr>
        </tbody>
        <tfoot>
          <tr>
            <div class="d-flex align-items-basseline">
              <nav aria-label="Page navigation example">
                <ul class="pagination">
                  <li class="page-item">
                    <a
                      v-if="currentPage > 1"
                      class="page-link"
                      href="#"
                      aria-label="Previous"
                      @click="nextPage(currentPage - 1)"
                    >
                      <span aria-hidden="true">&laquo;</span>
                      <span class="sr-only">Previous</span>
                    </a>
                  </li>
                  <li
                    class="page-item"
                    v-for="(page, index) in ticketPage.last_page"
                    :key="index"
                    v-show="
                      page === (currentPage == 1 ? 1 : currentPage - 1) ||
                        page === currentPage + 1 ||
                        page === currentPage
                    "
                    :class="[{ active: currentPage === page }]"
                  >
                    <a class="page-link" href="#" @click="nextPage(page)">{{
                      page
                    }}</a>
                  </li>
                  <li
                    class="page-item"
                    v-if="ticketPage.last_page > currentPage"
                  >
                    <a
                      class="page-link"
                      href="#"
                      aria-label="Next"
                      @click="nextPage(currentPage + 1)"
                    >
                      <span aria-hidden="true">&raquo;</span>
                      <span class="sr-only">Next</span>
                    </a>
                  </li>
                </ul>
              </nav>
            </div>
          </tr>
        </tfoot>
      </table>
    </div>
  </div>
</template>

<script>
  import { mapActions, mapGetters } from "vuex";

  export default {
    data() {
      return {
        rowStart: null,
        rowEnd: null,
        perPage: 10,
        currentPage: 1,
        loading: false,
        closeTicket: {
          isClosing: false,
          index: null,
          message: null,
        },
      };
    },
    computed: {
      ...mapGetters(["tickets", "ticketPage"]),
      tableTickets() {
        return this.tickets.filter((ticket, index) => {
          if (!this.rowStart && this.rowStart !== 0) {
            return true;
          } else {
            console.log("index" + index);
            console.log("rowStart" + this.rowStart);
            console.log("rowEnd" + this.rowEnd);

            return index >= this.rowStart && index <= this.rowEnd;
          }
        });
      },
    },
    created() {
      this.nextPage(1);
    },
    methods: {
      ...mapActions(["NEXT_PAGE", "GET_PAGE", "CLOSE_ALL_TICKETS"]),
      nextPage(n) {
        this.currentPage = n;
        console.log("n is ", n);
        if (!this.tickets.length) {
          this.loading = true;
          this.NEXT_PAGE(1).then(() => {
            this.loading = false;
          });

          this.rowEnd = n * this.perPage - 1;
          this.rowStart = this.rowEnd - this.perPage + 1;
        } else if (n * this.perPage <= this.tickets.length) {
          this.rowEnd = n * this.perPage - 1;
          this.rowStart = this.rowEnd - this.perPage + 1;
        } else {
          this.loading = true;
          // this.GET_PAGE(n).then(() => {
          //   this.rowEnd = n * this.perPage - 1;
          //   this.rowStart = this.rowEnd - this.perPage + 1;
          //   this.loading = false;
          // });

          this.NEXT_PAGE(n).then(() => {
            this.rowEnd = n * this.perPage - 1;
            this.rowStart = this.rowEnd - this.perPage + 1;
            this.loading = false;
          });
        }
        this.$forceUpdate();
      },
      closeAllTickets() {
        this.closeTicket = {
          isClosing: true,
          index: 0,
          message: "Encerrando,  aguarde",
        };
        if (this.closeTicket.index == 0) {
          this.CLOSE_ALL_TICKETS().then(() => {
            this.closeTicket = {
              isClosing: true,
              index: 1,
              message: "Todos os tickets foram encerrados!",
            };
            setTimeout(() => {
              this.closeTicket = {
                isClosing: false,
                index: 0,
                message: null,
              };
              console.log("closed all");
            }, 2000);
          });
        }
      },
    },
  };
</script>

<style></style>
