<template>
  <div v-if="isLoaded">
    <div class="row">
      <div class="col"><h1>Usuários</h1></div>
    </div>
    <div class="row mt-5">
      <div class="col">
        <div class="card">
          <div class="card-body">
            <div class="col-3">
              <div class="d-flex">
                <input
                  class="form-control"
                  type="text"
                  placeholder="Pesquisar"
                  v-model="search"
                />
                <button
                  class="btn btn-primary ml-3"
                  data-toggle="modal"
                  data-target="#roleModal"
                  @click="createUser()"
                >
                  +
                </button>
              </div>
            </div>
            <div class=" mt-4">
              <table class="table">
                <thead>
                  <tr>
                    <th scope="col">Nome</th>
                    <th scope="col" class="text-center">Cargo</th>
                    <th scope="col" class="text-center">Ações</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="user in filterCore" :key="user.id">
                    <td>{{ user.name }}</td>
                    <td class="text-center">
                      <p v-for="uRole in user.roles" :key="uRole.id">
                        {{ uRole.name }}
                      </p>
                    </td>

                    <td class="text-center">
                      <button class="btn btn-success" @click="viewUser(user)">
                        <i class="far fa-eye"></i>
                      </button>
                      <button
                        class="btn btn-primary ml-3"
                        @click="editUser(user)"
                      >
                        <i class="far fa-edit"></i>
                      </button>
                      <button
                        class="btn btn-danger ml-3"
                        @click="deleteUser(user)"
                      >
                        <i class="far fa-trash-alt"></i>
                      </button>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!------------------modal------------------->
    <div
      class="modal fade"
      id="userModal"
      tabindex="-1"
      aria-labelledby="userModalLabel"
      aria-hidden="true"
    >
      <div
        class="modal-dialog  modal-right 
      "
        style="width:20%"
      >
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" v-if="isCreating">Novo Usuario</h5>
            <h5 class="modal-title" v-if="!isCreating && isEditing">
              {{ user.name }}
            </h5>
            <h5 class="modal-title" v-if="!isCreating && !isEditing">
              {{ user.name }}
            </h5>
            <button
              type="button"
              class="close"
              data-dismiss="modal"
              aria-label="Close"
              @click="reset()"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <form>
              <div class="form-group">
                <label for="name">Nome</label>
                <input
                  type="text"
                  class="form-control"
                  id="name"
                  aria-describedby="name"
                  v-model="user.name"
                  :disabled="disableToView()"
                />
              </div>
              <div class="form-group">
                <label for="email">Email</label>
                <input
                  type="text"
                  class="form-control"
                  id="email"
                  aria-describedby="email"
                  v-model="user.email"
                  :disabled="disableToView()"
                />
              </div>
              <div class="form-group">
                <label for="password">Senha</label>
                <input
                  type="password"
                  class="form-control"
                  id="password"
                  aria-describedby="password"
                  v-model="user.password"
                  :disabled="disableToView()"
                />
              </div>
              <div class="form-group">
                <label for="role">Cargo</label>
                <multiselect
                  :disabled="disableToView()"
                  :multiple="false"
                  id="role"
                  v-model="selected_roles"
                  :options="roles"
                  :show-labels="false"
                  track-by="slug"
                  label="slug"
                  @input="filterId"
                >
                </multiselect>
              </div>
            </form>
          </div>
          <div class="modal-footer">
            <button
              type="button"
              class="btn btn-secondary"
              data-dismiss="modal"
            >
              Close
            </button>
            <button
              type="button"
              class="btn btn-primary"
              @click="saveRole()"
              :disabled="disableToView()"
            >
              Salvar
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import { mapGetters, mapActions } from "vuex";
  import Multiselect from "vue-multiselect";
  export default {
    components: {
      Multiselect,
    },
    data() {
      return {
        search: "",
        isLoaded: false,
        isEditing: false,
        isCreating: false,
        user: {
          name: "",
          email: "",
          password: "",
          roles_id: [],
        },
        selected_roles: [],
      };
    },
    computed: {
      ...mapGetters(["users", "roles"]),
      filterCore() {
        if (this.search.length > 0) {
          return this.users.filter((user) => {
            return (
              user.name.toLowerCase().includes(this.search.toLowerCase()) ||
              user.email.toLowerCase().includes(this.search.toLowerCase())
            );
          });
        } else {
          return this.users;
        }
      },
    },
    mounted() {
      var that = this;
      if (!this.roles.length && !this.users.length) {
        this.LOAD_USERS();
        this.LOAD_ROLES();
      }
      setTimeout(() => {
        this.isLoaded = true;
      }, 1000);
      $("#userModal").on("hidden.bs.modal", function(e) {
        that.reset();
      });
    },
    methods: {
      ...mapActions([
        "LOAD_USERS",
        "SAVE_USER",
        "LOAD_ROLES",
        "DELETE_USER",
        "UPDATE_USER",
      ]),
      disableToView() {
        return !this.isEditing && !this.isCreating;
      },
      viewUser(user) {
        this.reset();
        this.user = user;
        this.selected_roles = user.roles;
        $("#userModal").modal("show");
      },
      createUser() {
        this.reset();
        this.isCreating = true;
        $("#userModal").modal("show");
      },
      editUser(user) {
        this.reset();
        this.isEditing = true;
        this.user = user;
        this.selected_roles = this.user.roles;
        $("#userModal").modal("show");
      },
      reset() {
        this.isEditing = false;
        this.isCreating = false;
        this.user = { name: "", email: "", password: "", roles_id: [] };
        this.selected_roles = [];
      },
      saveRole() {
        if (this.isEditing && !this.isCreating) {
          //update
          this.UPDATE_USER(this.user);
          this.reset();
          this.$toast.success(" ", "Usuário atualizado", {
            icon: "icon-person",
            position: "topRight",
            timeout: 3000,
          });
        } else if (!this.isEditing && this.isCreating) {
          //create
          this.SAVE_USER(this.user);
          this.reset();
          this.$toast.success(" ", "Usuário criado", {
            icon: "icon-person",
            position: "topRight",
            timeout: 3000,
          });
        }
        $("#userModal").modal("hide");
      },
      deleteUser(user) {
        this.DELETE_USER(user);
        this.$toast.warning(" ", "Usuário removido", {
          icon: "icon-person",
          position: "topRight",
          timeout: 3000,
        });
      },
      filterId(value) {
        console.log("the val of roles", value);
        // let ids = value.map((role) => role.id);
        // console.log("ids:::", ids);
        this.user.roles_id = value.id;
      },
    },
  };
</script>

<style></style>
