<template>
  <!-- <div
    class="w-100"
    style="border: 1px solid rgba(0, 0, 0, 0.125);height: 80vh;flex-direction: column;display: flex;"
  > -->
  <div class="w-100 h-100" style="border: 1px solid rgba(0, 0, 0, 0.125);">
    <!--=======================action buttons=============================-->
    <div class="d-flex justify-content-between m-4">
      <button class="btn" @click="toggleInput()">
        <i class="fas fa-search"></i>
      </button>
      <button class="btn" @click="toggleContactBook()">
        <i
          class="fas fa-address-book"
          data-toggle="tooltip"
          title="Lista de contatos"
        ></i>
      </button>
      <button class="btn">
        <i class="fas fa-comment-slash"></i>
      </button>
      <button id="closeTicketBTN" class="btn" @click="closeChat()">
        <i class="far fa-times-circle"></i>
      </button>
    </div>
    <!--======================//action buttons=============================-->
    <!--=======================filters=============================-->

    <div class="d-flex flex-column">
      <!-------------------------status select------------------------->
      <div class="m-4" v-if="isSearchingStatus">
        <label for="">Status</label>
        <select class="form-control" name="" id="">
          <option>Em espera</option>
        </select>
      </div>
      <!-----------------------//status select------------------------->
      <!-------------------------search input-------------------------->
      <div class="w-100 p-4" v-if="isSearchingInput">
        <input
          v-model="searchFor"
          type="text"
          class="form-control"
          placeholder="nome, numero ou cpf"
        />
      </div>
      <!-----------------------//search input-------------------------->
    </div>
    <!--=====================//filters=============================-->
    <!--=======================contacts=============================-->
    <ul
      class="list-group  h-100 main_contact_list"
      style="overflow-y: auto;"
      v-if="!isSearchingContactBook"
    >
      <li
        v-for="contact in openTickets"
        :key="getTicket(contact.id).uuid"
        class="list-group-item pointer"
        data-toggle="tooltip"
        data-placement="top"
        :title="contact.number"
      >
        <div class="contact_item w-100 " @click="selectTicket(contact.id)">
          <div class="__avatar" :class="statusColor(contact)">
            <img :src="contact.avatar_url" alt="" />
          </div>

          <div class="__info">
            <div>
              <strong
                v-if="wasSeen(getTicket(contact.id).id)"
                class="text-success blink-2"
                ><i class="fas fa-circle fa-xs"></i
              ></strong>
              {{ contact.name }}
            </div>
            <div>
              <h5 class="mr-3" v-if="getTicket(contact.id).status == 2">
                <span class="badge badge-danger">Não atendido</span>
              </h5>
              <h5 class="mr-3" v-if="getTicket(contact.id).status == 6">
                <span class="badge badge-default">Pausado</span>
              </h5>
              <h5 class="mr-3" v-if="getTicket(contact.id).status == 5">
                <span class="badge badge-info">Aguardar retorno</span>
              </h5>
            </div>
          </div>
        </div>
        <div class="d-flex align-items-center">
          <div class="btn-group">
            <button
              type="button"
              class="btn dropdown-toggle"
              data-toggle="dropdown"
              aria-haspopup="true"
              aria-expanded="false"
            >
              <i class="fas fa-ellipsis-v"></i>
            </button>
            <div class="dropdown-menu" style="z-index:10!important">
              <a class="dropdown-item" href="#"><strong>Status:</strong></a>
              <div class="dropdown-divider"></div>
              <!--===================Status:Pause=====================-->
              <a
                v-show="getTicket(contact.id).status !== 6"
                class="dropdown-item"
                href="#"
                @click.prevent="changeStatus(contact, 6)"
              >
                <i
                  class="far fa-pause-circle mr-3"
                  style="font-size:25px!important"
                ></i>
                <span>Pausar</span></a
              >
              <!--=================//Status:Pause=====================-->
              <!--===================Status:Unpause=====================-->
              <a
                v-show="getTicket(contact.id).status == 6"
                class="dropdown-item"
                href="#"
                @click.prevent="changeStatus(contact, 3)"
              >
                <i
                  class="fas fa-play mr-3"
                  style="font-size:25px!important"
                ></i>
                <span>Retomar</span></a
              >
              <!--=================//Status:Unpause=====================-->
              <a
                v-show="getTicket(contact.id).status !== 5"
                class="dropdown-item"
                href="#"
                @click.prevent="changeStatus(contact, 5)"
                ><i
                  class="fas fa-user-clock mr-3"
                  style="font-size:25px!important"
                ></i>
                <span>Aguardar retorno</span></a
              >
              <a
                v-show="getTicket(contact.id).status == 5"
                class="dropdown-item"
                href="#"
                @click.prevent="changeStatus(contact, 3)"
                ><i
                  class="fas fa-clipboard-check mr-3"
                  style="font-size:25px!important"
                ></i>
                <span>Retorno concluido</span></a
              >
              <div class="dropdown-divider"></div>
              <a class="dropdown-item" href="#" @click="closeTicket(contact)"
                ><i
                  class="fas fa-sign-out-alt mr-3"
                  style="font-size:25px!important"
                ></i
                ><span>Encerrar</span></a
              >
            </div>
          </div>
        </div>
      </li>
    </ul>
    <!--=====================//contacts=============================-->
    <!--=======================contacts agenda=============================-->

    <ul
      style="overflow-x: unset;overflow-y: auto;"
      class="list-group w-100 main_contact_list"
      v-if="isSearchingContactBook"
    >
      <strong class="p-4">Contatos {{ contactsBook.length }}</strong>
      <li
        v-for="contact in contactsBook"
        :key="contact.id"
        class="list-group-item pointer"
        @click="
          SELECT_FROM_BOOK(contact);
          CLOSE_TICKET();
        "
      >
        <div class="contact_item">
          <div class="__avatar">
            <img :src="contact.avatar_url" alt="" />
          </div>

          <div class="__info">
            <div>{{ contact.name }}</div>
            <div>{{ contact.number }}</div>
          </div>
        </div>
        <!-- <div class="d-flex align-items-center">
          <h4 class="mr-3" v-if="getTicket(contact.id).status == 2">
            <span class="badge badge-primary">Em espera</span>
          </h4>
        </div> -->
      </li>
    </ul>
    <!--=====================//contacts agenda=============================-->

    <audio hidden id="notifySound">
      <source :src="NotificationSound" type="audio/mpeg" />
      Your browser does not support the audio element.
    </audio>
  </div>
</template>

<script>
  import axios from "../../../http";
  import { mapGetters, mapActions } from "vuex";
  export default {
    data() {
      return {
        isSearchingStatus: false,
        isSearchingInput: false,
        isSearchingContactBook: false,
        searchFor: "",
        SOCKET_UPDATE: 0,
        NotificationSound: require("../../../assets/sound/beyond-doubt-580.mp3"),
      };
    },
    computed: {
      ...mapGetters([
        "loggedUser",
        "contacts",
        "tickets",
        "messages",
        "ticket",
      ]),
      contactsBook() {
        let contacts = this.contacts;
        if (this.isSearchingInput && this.searchFor.length) {
          contacts = contacts.filter((contact) => {
            return (
              contact.name
                .toLowerCase()
                .includes(this.searchFor.toLowerCase()) ||
              contact.number.includes(this.searchFor) ||
              contact.cpf?.includes(this.searchFor)
            );
          });
        }
        return contacts;
      },
      openTickets() {
        this.SOCKET_UPDATE;
        //this.contacts: ALL LOADED CONTACTS
        let cons = this.contacts.filter((c) => {
          //this.tickets: ALL LOADED TICKETS
          return this.tickets.some((openT) => {
            return (
              openT.patient_id === c.id && !openT.closed_at && openT.status > 1
            );
          });
        });
        if (this.isSearchingInput && this.searchFor.length) {
          cons = cons.filter((contact) => {
            return (
              contact.name
                .toLowerCase()
                .includes(this.searchFor.toLowerCase()) ||
              contact.number.includes(this.searchFor) ||
              contact.cpf?.includes(this.searchFor)
            );
          });
        }
        return cons.filter(
          (c) => c.operator_id === this.loggedUser.id || !c.operator_id
        );
      },
    },
    mounted() {
      setTimeout(() => {
        this.$socket.on(this.loggedUser.clientName + "::new-ticket", (data) => {
          console.log("new ticket in", data);
          this.SOCKET_NEW_TICKET(data);
          this.SOCKET_UPDATE++;
          this.playSound();
        });
        this.$socket.on(
          this.loggedUser.clientName + "::updated-ticket",
          (data) => {
            this.SOCKET_UPDATE_TICKET(data);
            this.SOCKET_UPDATE++;
          }
        );
        this.$socket.on(
          this.loggedUser.clientName + "::seen-message",
          (data) => {
            //atualizar o ticket
            console.log("foi respondido", data);
            this.SOCKET__UPDATE_MESSAGES_STATUS(data).then(() => {
              this.wasSeen(data.ticket_id);
            });
          }
        );
        this.$event.$on("contact-from-book", () => {
          this.resetFilters();
        });
      }, 2000);
    },
    methods: {
      ...mapActions([
        "N_SELECT_TICKET",
        "SOCKET_NEW_TICKET",
        "SOCKET_UPDATE_TICKET",
        "SOCKET__UPDATE_MESSAGES_STATUS",
        "CLOSE_TICKET",
        "CLOSE_MESSAGES",
        "CLOSE_THIS_TICKETS",
        "NEW_TICKET",
        "CHANGE_TICKET_STATUS",
        "SELECT_FROM_BOOK",
      ]),
      toggleStatus() {
        this.isSearchingStatus = !this.isSearchingStatus;
      },
      toggleInput() {
        this.isSearchingInput = !this.isSearchingInput;
      },
      toggleContactBook() {
        this.isSearchingContactBook = !this.isSearchingContactBook;
      },
      selectTicket(id) {
        this.N_SELECT_TICKET(id);
        this.$event.$emit("select-ticket");
      },
      playSound() {
        let sound = document.getElementById("notifySound");
        sound.play();
        setTimeout(() => {
          sound.pause();
        }, 400);
      },
      getTicket(id) {
        return this.tickets.find((ticket) => {
          return ticket.patient_id === id && !ticket.closed_at;
        });
      },
      closeChat() {
        this.CLOSE_TICKET();
        this.CLOSE_MESSAGES();
        this.SOCKET_UPDATE++;
        this.resetFilters();
      },
      closeTicket(contact) {
        // CLOSE_THIS_TICKETS
        let ticket = this.getTicket(contact.id);
        ticket.closed_at = new Date().toISOString();
        console.log("date here", ticket);
        this.SOCKET_UPDATE_TICKET(ticket);
        this.CLOSE_THIS_TICKETS(ticket);
        this.SOCKET_UPDATE++;
        this.$event.$emit("closing-ticket");
        this.closeChat();
      },
      openNewTicket(contact) {
        this.NEW_TICKET(contact);
        this.resetFilters();
      },

      resetFilters() {
        this.isSearchingStatus = false;
        this.isSearchingInput = false;
        this.isSearchingContactBook = false;
        this.searchFor = "";
      },
      changeStatus(contact, status) {
        let ticket = this.getTicket(contact.id);
        ticket.status = status;
        this.SOCKET_UPDATE_TICKET(ticket);
        this.CHANGE_TICKET_STATUS({ contact, status });
        this.SOCKET_UPDATE++;
      },
      wasSeen(id) {
        let unSeenMsg = this.messages.filter((msg) => {
          return (
            msg.ticket_id === id && msg.status == null && msg.msg_from == 1
          );
        });
        console.log("msgs unSeenMsg", unSeenMsg);
        return unSeenMsg.length > 0;
      },
      statusColor(contact) {
        return [
          { on_hold: this.getTicket(contact.id).status === 6 },
          { waiting: this.getTicket(contact.id).status === 2 },
          { waiting_return: this.getTicket(contact.id).status === 5 },
        ];
      },
    },
  };
</script>

<style lang="scss">
  .main_contact_list {
    .list-group-item {
      display: flex;
      justify-content: space-between;
      align-items: center;
      &.active {
        background-color: #eaeaea;
      }
    }
    .contact_item {
      display: flex;
      align-items: center;

      .__avatar {
        margin-right: 1.5em;
        border-radius: 15em !important;
        img {
          width: 60px;
          height: 60px;
          border-radius: 15em !important;
        }
        &.waiting {
          /* border: 0.3em solid rgb(255, 17, 0) !important; */
          -webkit-box-shadow: 0px 0px 17px -2px rgb(255, 17, 0);
          box-shadow: 0px 0px 17px -2px rgb(255, 17, 0);
        }
        &.on_hold {
          /* border: 0.3em solid rgb(92, 88, 84) !important; */
          -webkit-box-shadow: 0px 0px 17px -2px rgb(92, 88, 84);
          box-shadow: 0px 0px 17px -2px rgb(92, 88, 84);
        }
        &.waiting_return {
          /* border: 0.3em solid #5f76e8 !important; */
          -webkit-box-shadow: 0px 0px 17px -2px #5f76e8;
          box-shadow: 0px 0px 17px -2px #5f76e8;
        }
      }
    }
  }
</style>
