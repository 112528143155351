import Vue from "vue";
import Vuex from "vuex";
import axios from "../../http";
import router from "../../router";

Vue.use(Vuex);

export default {
  state: {
    vemom: {
      status: null,
      device: {},
    },
  },

  getters: {
    vemom: (state) => state.vemom,
    vemomStatus: (state) => state.vemom.status,
    vemomDevice: (state) => state.vemom.device,
  },

  mutations: {
    
    SET_VENOM_STATUS(state, status) {
      state.vemom.status = status;
    },
    
    SET_DEVICE_STATUS(state, status) {
      state.vemom.device = status;
    },

  },

  actions: {

    VENOM_SESSION_STATUS: async (context, payload) => {
      
      try {
        // axios().defaults.headers.common['Access-Control-Allow-Origin'] = '*';

        const response = await axios().get(
          process.env.VUE_APP_VENOM_SERVER +
            "/session-status/" +
            process.env.VUE_APP_CLIENT_NAME
        );

        console.log("SET_VENOM_STATUS loaded");
        console.log("response", response);
        
        context.commit("SET_VENOM_STATUS", response.data);

        return response.data;

      } catch (error) {
        console.log('error on VENOM_SESSIONS_STATUS ', error)
        return error;
      }
    },

    SET_VENOM_STATUS: (context, payload) => {
      
      context.commit("SET_VENOM_STATUS", payload);
      
      return true;

    },

    LOAD_VENOM_DEVICE: async (context, payload) => {
      //aqui retorna o status da conexão
      //:connected

      try {
        
        let response = await axios().get(
          process.env.VUE_APP_VENOM_SERVER +
            "/device-status/" +
            process.env.VUE_APP_CLIENT_NAME
        );

        console.log("LOAD_VENOM_DEVICE loaded");

        context.commit("SET_DEVICE_STATUS", response.data);

        return response.data;

      } catch (error) {
        console.log("error::LOAD_VENOM_DEVICE", error);
      }

    },

    RELOAD_VENOM: (context, payload) => {
      
      axios()
        
        .post(
          process.env.VUE_APP_VENOM_SERVER + "/run_client", 
          {
          
            clientName: process.env.VUE_APP_CLIENT_NAME,
          
            api_url: process.env.VUE_APP_BACK_BASE_URL + "/api",
          }
        )


        .then((response) => {
          
          context.commit("SET_DEVICE_STATUS", response.data);

          console.log("RELOAD_VENOM", response.data);

          return response.data;

        })

        .catch((error) => {
          console.log(error);
        });
    },

    KILL_TOKEN_VENOM: (context, payload) => {

      axios()
        .get(
          process.env.VUE_APP_VENOM_SERVER +
            "/disconnect/" +
            process.env.VUE_APP_CLIENT_NAME
        )
        .then((response) => {
          
          //   context.commit("SET_DEVICE_STATUS", response.data);

          console.log("KILL_TOKEN_VENOM", response.data);
        
          return response.data;

        })

        .catch((error) => {
          console.log(error);
        });
    },
  },
};
