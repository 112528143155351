<template>
  <div>
    <div>
      <h1>BOTS</h1>
      <span
        class="text-primary pointer"
        @click.prevent="toggleDefaultStatus()"
        v-if="default_bot !== null && !clicked"
        >Desativar bot padrão</span
      >
    </div>
    <div class="row mt-5">
      <div class="col-3">
        <div class="card bg-primary" @click="createBot()">
          <div class="card-body">
            <div class="d-flex justify-content-center text-white">
              Novo bot
            </div>
          </div>
        </div>
      </div>
      <div
        class="col-3"
        v-for="bot in bots"
        :key="bot.id"
        @click="editBot(bot.id)"
      >
        <div class="card" :class="{ isDefault: bot.default }">
          <div class="card-body">
            <div
              class="d-flex justify-content-center"
              v-if="!isDefaultGoingOff(bot)"
            >
              {{ bot.label }}
            </div>
            <div
              class="d-flex justify-content-center "
              v-if="isDefaultGoingOff(bot)"
            >
              <div
                class="spinner-border text-primary"
                role="status"
                v-if="loadingStatus && !statusDone"
              >
                <span class="sr-only">Loading...</span>
              </div>
              <strong v-if="statusDone">Desligado</strong>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import { mapGetters, mapActions } from "vuex";
  export default {
    name: "Bots",
    data() {
      return {
        isTurningOff: false,
        loadingStatus: false,
        statusDone: false,
        default_bot: null,
        clicked: false,
      };
    },
    computed: {
      ...mapGetters(["bots"]),
    },
    created() {
      // this.LOAD_BOTS();
    },
    mounted() {
      this.LOAD_BOTS();
    },
    methods: {
      ...mapActions(["LOAD_BOTS", "DEFAULT_OFF"]),
      createBot() {
        this.$router.push({ path: "new_bot" });
      },
      editBot(id) {
        this.$router.push({ name: "bot", params: { id: id } });
      },
      isDefaultGoingOff(bot) {
        if (bot.default == 1) {
          this.default_bot = bot;
          if (this.isTurningOff) {
            this.loadingStatus = true;
            return true;
          } else {
            return false;
          }
        }
      },
      toggleDefaultStatus() {
        this.clicked = true;
        this.isTurningOff = true;
        this.DEFAULT_OFF(this.default_bot).then(() => {
          this.statusDone = true;
          this.default_bot = null;
          setTimeout(() => {
            this.statusDone = false;
            this.isTurningOff = false;
          }, 2000);
        });
      },
    },
  };
</script>

<style>
  .isDefault {
    border: 1px solid #5f76e8 !important;
  }
</style>
