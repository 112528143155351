<template>
  <div>
    <input
      v-model="text"
      type="text"
      class="form-control"
      @keypress.enter="send()"
    />
    <br />
    <p>{{ message }}</p>
  </div>
</template>

<script>
import io from "socket.io-client";
import { mapActions, mapGetters } from "vuex";
export default {
  data() {
    return {
      message: null,
      text: null,
    };
  },
  mounted() {
    // let socket = io("http://127.0.0.1:3660");
    // socket.on("chat-new-message", (data) => {
    //   this.message = data;
    //   console.log("Connected clients:", data.numClients);
    // });
  },
  methods: {
    send() {
      // let socket = io("http://127.0.0.1:3660");
      // socket.emit("chat-new-message", this.text);
      // this.text = null;
      //enviar pro backend
    },
  },
};
</script>

<style></style>
