import Vue from "vue";
import Vuex from "vuex";
import axios from "../../http";
import contacts from "./contacts";
import event from "../../helpers/eventBus";
Vue.use(Vuex);

export default {
  state: {
    tickets: [],
    ticket: {},
    ticketPage: {},
  },
  getters: {
    tickets: (state) => state.tickets,
    ticket: (state) => state.ticket,
    ticketPage: (state) => state.ticketPage,
  },
  mutations: {
    SET_TICKETS(state, tickets) {
      state.tickets = tickets;
    },
    UPDATE_TICKET(state, ticket) {
      console.log("in ticket::", ticket);
      let index = state.tickets.findIndex((t) => {
        return t.id === ticket.id;
      });
      state.tickets[index] = ticket;
    },
    SOCKET_NEW_TICKETS(state, payload) {
      state.tickets.push(payload);
    },
    SET_TICKET(state, ticket) {
      state.ticket = ticket;
    },
    CLOSE_TICKET(state) {
      state.ticket = {};
    },
    SET_TICKET_PAGE(state, payload) {
      state.ticketPage = payload;
    },
    ADD_CONTACT_TICKETS(state, payload) {
      payload.forEach((ticket) => {
        let hasIt = state.tickets.some((inT) => {
          return inT.id === ticket.id;
        });
        if (!hasIt) {
          state.tickets.push(ticket);
        }
      });
    },
    LOAD_PAGE(state, payload) {
      payload.forEach((t) => {
        let hasIt = state.tickets.some((stateTickets) => {
          return stateTickets.id === t.id;
        });
        if (!hasIt) {
          state.tickets.push(t);
        }
      });
    },
    NEW_TICKETS(state, payload) {
      state.tickets.push(payload);
    },
  },

  actions: {
    CLOSE_TICKET: (context) => {
      context.commit("CLOSE_TICKET");
      return true;
    },
    LOAD_USER_TICKETS: async (context, payload) => {
      try {
        const response = await axios().get("only_open_tickets");
        context.commit("LOAD_PAGE", response.data);
      } catch (error) {
        console.log("error on LOAD_TICKETS()", error);
        return error;
      }
    },
    LOAD_TICKETS: async (context, payload) => {
      try {
        const response = await axios().get("tickets");
        context.commit("SET_TICKETS", response.data);
      } catch (error) {
        console.log("error on LOAD_TICKETS()", error);
        return error;
      }
    },
    LOAD_OPEN_TICKETS: async (context, payload) => {
      try {
        const response = await axios().get("only_open_tickets");
        context.commit("SET_TICKETS", response.data);
      } catch (error) {
        console.log("error on LOAD_TICKETS()", error);
        return error;
      }
    },
    GET_PAGE: async (context, payload) => {
      try {
        const response = await axios().get(`tickets/load_page/${payload}`);
        console.log("loaded", response.data);
        context.commit("SET_TICKETS", response.data.data);
      } catch (error) {
        console.log("error on LOAD_TICKETS()", error);
        return error;
      }
    },
    NEXT_PAGE: async (context, payload) => {
      try {
        const response = await axios().get(`tickets?page=${payload}`);
        console.log("loaded", response.data);
        context.commit("LOAD_PAGE", response.data.data);
        delete response.data.data;
        context.commit("SET_TICKET_PAGE", response.data);
      } catch (error) {
        console.log("error on LOAD_TICKETS()", error);
        return error;
      }
    },
    SELECT_TICKET: (context, payload) => {
      let ticket = context.getters.tickets.find((ticket) => {
        return ticket.id === payload;
      });
      console.log("foun d this ticket", ticket);
      context.commit("SET_TICKET", ticket);
      let patient = context.getters.contatos.find((contact) => {
        return contact.id === ticket.patient_id;
      });
      context.commit("SELECT_CONTATO", patient);
    },
    //Depricated
    N_SELECT_TICKET: (context, payload) => {
      // carregar o ticket selecionado
      let ticket = context.getters.tickets.find((ticket) => {
        return ticket.patient_id === payload && !ticket.closed_at;
      });
      console.log("setting ticket::", ticket);
      context.commit("SET_TICKET", ticket);
      let contact = context.getters.contatos.find((contact) => {
        return contact.id === ticket.patient_id;
      });
      context.commit("SELECT_CONTATO", contact);
    },
    SELECT_THIS_TICKET: (context, payload) => {
      // carregar o ticket selecionado
      let ticket = context.getters.tickets.find((ticket) => {
        return ticket.patient_id === payload;
      });
      console.log("setting ticket::", ticket);
      context.commit("SET_TICKET", ticket);
      let contact = context.getters.contatos.find((contact) => {
        return contact.id === ticket.patient_id;
      });
      context.commit("SELECT_CONTATO", contact);
    },
    NEW_TICKET: async ({ commit, dispatch, getters }, payload) => {
      try {
        const response = await axios().post("tickets/open", payload);
        let patient = getters.contacts.find((contact) => {
          return contact.id === response.data.patient_id;
        });
        if (!patient) {
          dispatch("FIND_CONTACT", response.data.patient_id);
        }
        commit("NEW_TICKETS", response.data);
        event.$emit("openingTicket", response.data);
      } catch (error) {
        console.log("error on NEW_TICKET()", error);
        return error;
      }
    },
    CONTACT_TICKETS: async (context, id) => {
      try {
        const response = await axios().get("load_contact_ticket/" + id);
        context.commit("ADD_CONTACT_TICKETS", response.data);
      } catch (error) {
        console.log("error on CONTACT_TICKETS()", error);
        return error;
      }
    },
    SOCKET_UPDATE_TICKET: ({ commit, getters }, payload) => {
      try {
        commit("UPDATE_TICKET", payload);
      } catch (error) {
        console.log("error on CONTACT_TICKETS()", error);
        return error;
      }
    },
    SOCKET_NEW_TICKET: ({ commit, getters }, payload) => {
      try {
        commit("SOCKET_NEW_TICKETS", payload);
      } catch (error) {
        console.log("error on CONTACT_TICKETS()", error);
        return error;
      }
    },
    CLOSE_ALL_TICKETS: async ({ commit, getters }, payload) => {
      try {
        const response = await axios().get("close_all_tickets");
        return response;
      } catch (error) {
        console.log("error on CLOSE_ALL_TICKETS()", error);
        return error;
      }
    },
    CLOSE_THIS_TICKETS: async ({ commit, getters }, payload) => {
      try {
        const response = await axios().put(
          "ticket/close/" + payload.id,
          payload
        );
        console.log("UPDATE_TICKETUPDATE_TICKET", response);
        commit("UPDATE_TICKET", response.data);
        return response;
      } catch (error) {
        console.log("error on CLOSE_ALL_TICKETS()", error);
        return error;
      }
    },
    UPDATE_TICKET: async ({ commit, dispatch, getters }, payload) => {
      try {
        const response = await axios().put("tickets/" + payload.id, payload);
        console.log("updated ticket", response);
        commit("UPDATE_TICKET", response.data);
        commit("SET_TICKET", response.data);

        // let contact = getters.contacts.find((c) => c.id === payload.patient_id);

        // dispatch.UPDATE_CONTACT_IN(contact);
        // if (payload.id === getters.ticket.id) {
        //   commit("SET_TICKET", response.data);
        // }
        return response;
      } catch (error) {
        console.log("error on CONTACT_TICKETS()", error);
        return error;
      }
    },
    CHANGE_TICKET_STATUS: async ({ commit }, { contact, status }) => {
      // console.log("contact", contact);
      // console.log("status", status);

      try {
        const response = await axios().post("change_ticket_status", {
          contact,
          status,
        });
        commit("UPDATE_TICKET", response.data);
        return response;
      } catch (error) {
        console.log("error on CHANGE_TICKET_STATUS()", error);
        return error;
      }
    },
  },
};
