<template>
  <div class="container h-100 ">
    <div class="row h-100 row-center justify-content-center align-items-center">
      <div class="col-4">
        <div class="form-signin ">
          <!-- <img
            class="mb-4"
            src="https://getbootstrap.com/docs/4.0/assets/brand/bootstrap-solid.svg"
            alt=""
            width="72"
            height="72"
          /> -->
          <h1 class="h3 mb-3 font-weight-normal">Digite seu acesso:</h1>
          <input
            type="email"
            id="inputEmail"
            class="form-control"
            placeholder="Email "
            v-model="user.email"
          />
          <input
            type="password"
            id="inputPassword"
            class="form-control"
            placeholder="Senha"
            v-model="user.password"
            @keypress.enter="login()"
          />
          <button
            class="btn btn-lg btn-primary btn-block mt-3"
            @click="login()"
          >
            Entrar
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import { mapActions, mapGetters } from "vuex";
  export default {
    data() {
      return {
        user: {
          email: "",
          password: "",
        },
      };
    },
    computed: {
      ...mapGetters(["token"]),
    },
    mounted() {
      if (this.token.includes("::401")) {
        this.$toast.error(" ", "Sessão expirada!", {
          icon: "icon-person",
          position: "topRight",
          timeout: 3000,
        });
      }
    },
    methods: {
      ...mapActions(["LOGIN"]),
      login() {
        if (this.user.email.length && this.user.password.length) {
          this.LOGIN(this.user).then((res) => {
            if (res.response.status === 401) {
              this.$toast.error(" ", "Credenciais inválidas", {
                icon: "icon-person",
                position: "topRight",
                timeout: 3000,
              });
              this.reset();
              localStorage.removeItem("token");
              localStorage.removeItem("fresh");
            }
          });
        } else {
          this.$toast.error(" ", "Um dos campos está vazio.", {
            icon: "icon-person",
            position: "topRight",
            timeout: 3000,
          });
        }
      },
      reset() {
        this.user = {
          email: "",
          password: "",
        };
      },
    },
  };
</script>

<style>
  .row-center {
    min-height: 100vh !important;
  }
</style>
