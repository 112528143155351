<template>
  <div class="p-3 message sent">
    <div v-if="data.src" class="mb-2">
      <img width="200px" :src="data.src" alt="" />
    </div>
    <div v-if="data.message">
      {{ data.message }}
    </div>
  </div>
</template>

<script>
  export default {
    name: "MessageBlock",
    props: {
      data: {
        type: Object,
      },
    },
  };
</script>

<style></style>
