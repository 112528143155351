<template>
  <div class="row">
    <div class="col">
      <div class="card">
        <div class="card-body">
          <input type="text" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style></style>
