<template>
  <div>
    <div class="card" v-if="loaded">
      <div class="card-body">
        <table class="table table-striped table-inverse table-responsive">
          <thead class="thead-inverse">
            <tr>
              <th>Identificação</th>
              <th>Criada em</th>
              <th>Conluido</th>
              <th>Criado por</th>
              <th>Status</th>
            </tr>
          </thead>
          <tbody>
            <tr
              v-for="(transmition, index) in transmitionList"
              :key="index"
              class="pointer"
              @click="selectTransmition(transmition)"
            >
              <td scope="row">{{ transmition.uuid }}</td>
              <td>{{ $date(transmition.started_at) }}</td>
              <td>{{ $date(transmition.finished_at) }}</td>
              <td>{{ getUser(transmition.created_by).name }}</td>
              <td>
                <h3>
                  <BadgeStatus
                    v-if="render"
                    :code="transmition.status"
                    selectData="transmition"
                  />
                </h3>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <portal to="modals">
        <CampaignStatusDisplay />
        <ContactModal :isEditing="true" :isCreating="false" />
      </portal>
    </div>
  </div>
</template>

<script>
  import { mapGetters, mapActions } from "vuex";
  import CampaignStatusDisplay from "@/components/modals/CampaignStatusDisplay";
  import BadgeStatus from "@/components/BadgeStatus";
  import ContactModal from "@/components/modals/ContactModal";
  import BreadCrumb from "@/components/navigation/BreadCrumb";

  export default {
    components: {
      CampaignStatusDisplay,
      BadgeStatus,
      ContactModal,
      BreadCrumb,
    },
    data() {
      return {
        loaded: false,
        SOCKET_UPDATE: 0,
        render: true,
      };
    },
    computed: {
      ...mapGetters(["transmitions", "users", "loggedUser"]),
      transmitionList() {
        this.SOCKET_UPDATE;
        return this.transmitions;
      },
    },
    mounted() {
      this.LOAD_REPORTS().then(() => {
        this.loaded = true;
      });
      this.$event.$on("updated-contact", () => {
        $("#campaignDisplay").modal("show");
      });
      this.$event.$on("close-contact-modal", () => {
        $("#campaignDisplay").modal("show");
      });
      setTimeout(() => {
        this.$socket.on(
          this.loggedUser.clientName + "::updated-transmition",
          (data) => {
            console.log("::updated-transmition", data);

            this.UPDATE_TRANSMITION(data);
            this.SET_TRANSMITION(data);
            this.reload();

            this.SOCKET_UPDATE++;
          }
        );
      }, 2000);
    },
    methods: {
      ...mapActions([
        "LOAD_REPORTS",
        "UPDATE_TRANSMITION",
        "SET_TRANSMITION",
        "SET_TRANSMITION_CONTACTS",
        "CLEAR_STATE",
      ]),
      getUser(id) {
        let user = this.users.find((user) => {
          return user.id === id;
        });
        if (!user) return id;
        return user;
      },
      async selectTransmition(transmition) {
        try {
          $("#campaignDisplay").modal("show");
          await this.SET_TRANSMITION({});
          await this.SET_TRANSMITION_CONTACTS(transmition.id);
          await this.SET_TRANSMITION(transmition);
        } catch (error) {
          console.log("selectTransmition::error", selectTransmition);
        }
      },
      reload() {
        this.render = false;
        this.$nextTick(() => {
          this.render = true;
        });
      },
    },
  };
</script>

<style></style>
