import Vue from "vue";
import Vuex from "vuex";
import axios from "../../http";
Vue.use(Vuex);

export default {
  state: {
    users: [],
  },
  getters: {
    users: (state) => state.users,
  },
  mutations: {
    SET_USERS(state, users) {
      state.users = users;
    },
    SAVE_USER(state, user) {
      state.users.push(user);
    },
    DELETE_USER(state, id) {
      let index = state.users.findIndex((user) => {
        return user.id === id;
      });
      state.users.splice(index, 1);
    },
  },

  actions: {
    LOAD_USERS: async (context) => {
      try {
        const response = await axios().get("users");
        context.commit("SET_USERS", response.data);
        console.log("response.data", response.data);
      } catch (error) {
        console.log(error);
      }
    },
    SAVE_USER: async (context, payload) => {
      try {
        const response = await axios().post("users", payload);
        context.commit("SAVE_USER", response.data);
        console.log("response.data", response.data);
      } catch (error) {
        console.log(error);
      }
    },
    UPDATE_USER: async (context, payload) => {
      try {
        const response = await axios().put("users/" + payload.id, payload);
        context.commit("DELETE_USER", payload.id);
        context.commit("SAVE_USER", response.data);
        console.log("response.data", response.data);
      } catch (error) {
        console.log(error);
      }
    },
    DELETE_USER: async (context, payload) => {
      let user = payload;
      context.commit("DELETE_USER", payload.id);
      try {
        const response = await axios().delete("users/" + payload.id);

        console.log("response.data", response.data);
      } catch (error) {
        context.commit("SAVE_USER", user);
        console.log(error);
      }
    },
  },
};
