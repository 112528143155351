import Vue from "vue";
import Vuex from "vuex";
import axios from "../../http";
import router from "../../router";
import event from "../../helpers/eventBus";
import { masterHelper } from "../../helpers/Master";
Vue.use(Vuex);

export default {
  state: {
    token: "",
    loggedUser: {},
    maintenance: null,
  },
  getters: {
    token: (state) => state.token,
    loggedUser: (state) => state.loggedUser,
    maintenance: (state) => state.maintenance,
  },
  mutations: {
    SET_TOKEN(state, token) {
      state.token = token;
    },
    SET_LOGGED(state, user) {
      state.loggedUser = user;
    },
    SET_FIX(state, maintenance) {
      state.maintenance = maintenance;
    },
  },

  actions: {
    LOGIN: async (context, payload) => {
      try {
        const response = await axios().post("login", payload);
        localStorage.setItem("token", response.data.access_token);
        // localStorage.setItem("ttl", response.data.expires_in);

        // let min = response.data.expires_in / 60;
        // masterHelper.setCookie("TL_UDDO", response.data.access_token, min);
        context.commit("SET_TOKEN", response.data.access_token);
        axios().post(process.env.VUE_APP_VENOM_SERVER + "/venom_auth", {
          token: response.data.access_token,
        });

        router.push({ path: "/" });
        setTimeout(() => {
          localStorage.setItem("fresh", new Date());
          location.reload();
        }, 2000);
      } catch (error) {
        console.log("error on login", error);
        return error;
      }
    },
    CHECK_USER: async (context) => {
      try {
        const response = await axios().post("check_user");
        context.commit("SET_LOGGED", response.data);
        return response;
      } catch (error) {
        localStorage.removeItem("token");

        router.push({ name: "login" });
        // console.log("Error on Login");
        context.commit("SET_TOKEN", "::401");
        return error;
      }
    },
    LOGOUT: (context) => {
      try {
        axios().get("logout");
        localStorage.removeItem("token");
        context.commit("SET_TOKEN", "");
        axios().post(process.env.VUE_APP_VENOM_SERVER + "/venom_auth", {
          reset: true,
        });
        router.push({ name: "login" });
      } catch (err) {
        console.log("error on Logout ::", err);
      }
    },
    NOTICE: async (context) => {
      try {
        let res = await axios().get(
          process.env.VUE_APP_VENOM_SERVER + "/maintenance"
        );
        context.commit("SET_FIX", res.data);
        return res.data;
      } catch (err) {
        console.log("error on NOTICE ::", err);
      }
    },
    SOCKET__NOTICE: (context, payload) => {
      try {
        context.commit("SET_FIX", payload);
        return true;
      } catch (err) {
        console.log("error on SOCKET__NOTICE::", err);
      }
    },
  },
};
