import Vue from "vue";
import Vuex from "vuex";

// import axios from "axios";
import axios from "../../http";
Vue.use(Vuex);
// Vue.use(router);

export default {
  state: {
    messages: [],
    ticket_messages: [],
  },
  getters: {
    messages: (state) => state.messages,
    ticket_messages: (state) => state.ticket_messages,
  },
  mutations: {
    SET_MESSAGES(state, messages) {
      state.messages = messages;
    },
    OPEN_MESSAGES(state, messages) {
      state.ticket_messages = messages;
    },
    CLOSE_MESSAGES(state, payload) {
      state.ticket_messages = payload;
    },
    UPDATE_TICKET_MESSAGE(state, payload) {
      state.ticket_messages.push(payload);
    },
    UPDATE_MESSAGE(state, payload) {
      let hasIt = state.messages.some((msg) => {
        return msg.id === payload.id;
      });
      if (!hasIt) {
        state.messages.push(payload);
      }
    },
    SOCKET__UPDATE_MESSAGES_STATUS(state, payload) {
      console.log("going to update seen msg", payload);
      state.messages.filter((msg) => {
        if (msg.ticket_id === payload.ticket_id) {
          msg.status = payload.status;
        }
      });
    },
  },
  actions: {
    SOCKET__UPDATE_MESSAGES_STATUS: ({ commit, getters }, payload) => {
      console.log("ticket status atualizado", payload);
      commit("SOCKET__UPDATE_MESSAGES_STATUS", payload);
    },
    SOCKET__UPDATE_MESSAGES: ({ commit, getters }, payload) => {
      console.log("entrou SOCKET__UPDATE_MESSAGES e seu data");
      console.log(payload.data);

      let hasMsg = getters.ticket_messages.some((message) => {
        return message.id === payload.data.id;
      });
      if (!hasMsg) {
        console.log("its a new message");
        commit("UPDATE_TICKET_MESSAGE", payload.data);
      }
    },
    SOCKET__MESSAGE: ({ commit }, payload) => {
      console.log("saving message from  ticket", payload);
      commit("UPDATE_MESSAGE", payload);
    },
    CLOSE_MESSAGES: async (context) => {
      context.commit("CLOSE_MESSAGES", []);
    },
    LOAD_MESSAGES: async (context) => {
      try {
        let response = await axios().get("conversations");
        context.commit("SET_MESSAGES", response.data);
      } catch (err) {
        console.log("error on LOAD_MESSAGES", err);
      }
    },
    OPEN_MESSAGES: ({ commit, getters }, ticket_id) => {
      var ticket_msg = getters.messages.filter((message) => {
        return message.ticket_id === parseInt(ticket_id);
      });
      console.log("OPEN_MESSAGES::$ getters.messages", getters.messages);
      commit("OPEN_MESSAGES", ticket_msg);
    },
    SEND_MESSAGE: async ({ commit, getters }, payload) => {
      try {
        payload.apiChannel = "whatsapp";
        let response = await axios().post("send-message", payload);
        // commit("UPDATE_TICKET_MESSAGE", payload.data);
        // commit("UPDATE_MESSAGE", response.data);
      } catch (err) {
        console.log("error on LOAD_MESSAGES", err);
      }
    },
  },
};
