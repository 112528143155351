<template>
  <div class="message_container">
    <!--=========================header=========================-->
    <div class="__header">
      <div class="__contact">
        <div class="__avatar">
          <img :src="contact.avatar_url" alt="" />
        </div>
        <div class="__info">
          <div class="name d-flex">
            <span class="mr-4"
              ><strong>{{ contact.name }}</strong> - {{ contact.number }}</span
            >
            <h4 v-if="ticket.status == 2">
              <span class="badge badge-primary">Aguardando atendimetno</span>
            </h4>
          </div>
          <div class="__ticket_number">
            <strong>Ticket:</strong> {{ ticket.uuid }}
          </div>
        </div>
      </div>

      <div class="btn-group">
        <button
          type="button"
          class="btn dropdown-toggle"
          data-toggle="dropdown"
          aria-haspopup="true"
          aria-expanded="false"
        >
          <i class="fas fa-ellipsis-v"></i>
        </button>
        <div class="dropdown-menu">
          <a class="dropdown-item" href="#"><strong>Status:</strong></a>
          <div class="dropdown-divider"></div>
          <a class="dropdown-item" href="#">
            <i
              class="far fa-pause-circle mr-3"
              style="font-size:25px!important"
            ></i>
            <span> Em espera</span></a
          >
          <a class="dropdown-item" href="#"
            ><i
              class="fas fa-user-clock mr-3"
              style="font-size:25px!important"
            ></i>
            <span>Aguardando retorno</span></a
          >
          <div class="dropdown-divider"></div>
          <a class="dropdown-item" href="#"
            ><i
              class="fas fa-sign-out-alt mr-3"
              style="font-size:25px!important"
            ></i
            ><span>Encerrar</span></a
          >
        </div>
      </div>
    </div>
    <!--=======================//header=========================-->
    <div class="__messages">
      <MessageBox
        v-for="message in ticketMessages"
        :key="message.uuid"
        :data="message"
        :id="'msg_block__' + message.id"
      />
    </div>

    <div class="__compose_area">
      <div class="col">
        <div
          class="d-flex  align-items-center just"
          v-if="this.ticket.operator_id"
        >
          <div class="mt-0 mb-0">
            <textarea
              id="textarea1"
              placeholder="Digite a mensagem e aperte Enter"
              class="form-control border-0"
              cols="100"
              type="text"
              v-model="compose.message"
            ></textarea>
          </div>
          <button
            class="btn btn-circle  btn-cyan float-right text-white ml-3"
            @click="sendMessage()"
          >
            <i class="fas fa-paper-plane"></i>
          </button>
        </div>
        <div
          class="d-flex justify-content-center"
          v-if="!this.ticket.operator_id"
        >
          <div>
            <button class="btn btn-warning" @click="startTicket()">
              Iníciar atendimento
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import MessageBox from "@/components/chat/new/MessageItem";
  import { mapGetters, mapActions } from "vuex";
  export default {
    components: {
      MessageBox,
    },
    data() {
      return {
        compose: {
          message: null,
          link: null,
          image: null,
          audio: null,
        },
        SOCKET_UPDATE: 0,
      };
    },
    computed: {
      ...mapGetters([
        "ticket_messages",
        "contatos",
        "ticket",
        "tickets",
        "loggedUser",
        "messages",
        "contact",
      ]),
      ticketMessages() {
        this.SOCKET_UPDATE;
        return this.messages.filter((msg) => {
          return msg.ticket_id === this.ticket.id;
        });
      },
    },
    created() {
      this.$event.$on("select-ticket", () => {
        console.log("opening here...");
        this.SOCKET_UPDATE++;
        if (this.ticketMessages.length > 4) this.scrollLastMessage();
      });
    },
    mounted() {
      setTimeout(() => {
        this.$socket.on(this.loggedUser.clientName + "::message", (data) => {
          console.log("new message", data);
          this.SOCKET__MESSAGE(data);
          if (
            data.ticket_id === this.ticket.id &&
            this.ticketMessages.length > 4
          ) {
            this.scrollLastMessage();
          }
          this.SOCKET_UPDATE++;
        });
      }, 2000);
    },
    methods: {
      ...mapActions([
        "CLOSE_MESSAGES",
        "CLOSE_TICKET",
        "SOCKET__UPDATE_MESSAGES",
        "NEW_TICKET",
        "SEND_MESSAGE",
        "SOCKET__MESSAGE",
        "UPDATE_TICKET",
        "CLOSE_THIS_TICKETS",
        "SOCKET_NEW_TICKET",
        "SOCKET_UPDATE_TICKET",
      ]),
      scrollLastMessage() {
        let lastIndex = this.ticketMessages.length - 1;
        let lastMsg = this.ticketMessages[lastIndex];
        console.log("lastIndex", lastIndex);
        setTimeout(() => {
          let element = document.getElementById("msg_block__" + lastMsg.id);
          console.log("element is::", element);
          // element.scrollIntoView();
          element.scrollIntoView({
            block: "center",
            behavior: "smooth",
            inline: "end",
          });
        }, 300);
      },
      sendMessage() {
        let data = {
          ticket: this.ticket,
          contact: this.contact,
          message: this.compose.message,
        };
        this.SEND_MESSAGE(data);
        this.clearMessage();
      },
      clearMessage() {
        this.compose = {
          message: null,
          link: null,
          image: null,
          audio: null,
        };
      },
      startTicket() {
        let data = {
          operator_id: this.loggedUser.id,
          id: this.ticket.id,
          isGoingToStart: true,
          status: 3,
        };
        let ticket = this.ticket;
        ticket.operator_id = data.operator_id;
        ticket.status = data.status;

        this.UPDATE_TICKET(data).then(() => {
          this.$forceUpdate();
        });
        this.SOCKET_UPDATE_TICKET(ticket);
      },
    },
  };
</script>

<style lang="scss">
  .message_container {
    width: 100%;
    .__header {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 0.92em;
      border: 1px solid rgba(0, 0, 0, 0.125);
      .__contact {
        display: flex;
        align-items: center;
        .__avatar {
          margin-right: 1.5em;
          border-radius: 15em !important;
          img {
            width: 54px;
            height: 54px;
            border-radius: 15em !important;
          }
          &.waiting {
            border: 0.2em solid rgb(255, 17, 0) !important;
          }
          &.on_hold {
            border: 0.2em solid rgb(255, 126, 27) !important;
          }
        }
      }
    }
    .__messages {
      padding: 2em;
      /* height: 70vh; */
      position: relative;
      background: #efe7dd
        url("https://cloud.githubusercontent.com/assets/398893/15136779/4e765036-1639-11e6-9201-67e728e86f39.jpg")
        repeat;
      max-height: calc(100vh - 30vh);
      min-height: 70vh;
      overflow-x: hidden;
      overflow-y: auto;
      &::-webkit-scrollbar {
        width: 8px; /* width of the entire scrollbar */
      }
      &::-webkit-scrollbar-thumb {
        background-color: rgb(204, 204, 204); /* color of the scroll thumb */
        border-radius: 20px; /* roundness of the scroll thumb */
        /* // border: 1px solid rgb(235, 235, 235); creates padding around scroll thumb */
      }
      padding-bottom: 7em;
    }
    .__compose_area {
      background-color: #ffffff;
      padding: 1em;
      display: flex;
      align-items: center;
      position: absolute;
      bottom: 0;
      width: 100%;
      /* justify-content: space-between; */
      /* bg-white p-4 d-flex justify-content-between */
    }
  }
</style>
