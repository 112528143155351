<template>
  <div class="col-lg-3 col-xl-3 border-right">
    <div class="card-header d-flex justify-content-between p-3 m-1 bg-white">
      <button class="btn" @click="toggleSearch()">
        <i class="fas fa-search" data-toggle="tooltip" title="Pesquisar"></i>
      </button>

      <button class="btn" @click="toggleModal()">
        <i
          class="fas fa-share"
          data-toggle="tooltip"
          title="Enviar mensagem a todos"
        ></i>
      </button>
      <button class="btn" @click="contactList()">
        <i
          class="fas fa-address-book"
          data-toggle="tooltip"
          title="Lista de contatos"
        ></i>
      </button>
      <i
        class="fas fa-window-close"
        data-toggle="tooltip"
        title="Fechar chat"
      ></i>
    </div>
    <div class="card-body border-bottom" v-if="search">
      <form>
        <input
          v-model="searchFor"
          class="form-control"
          type="text"
          placeholder="Pesquisar"
        />
      </form>
    </div>

    <div
      class="scrollable position-relative ps-container ps-theme-default"
      style="height: calc(100vh - 295px);"
      data-ps-id="2b034f9d-94bd-cb60-6c63-370695a5bde0"
    >
      <ul
        class="mailbox list-style-none"
        v-if="tickets.length && listContacts.length"
      >
        <li>
          <div
            class="message-center ps-container ps-theme-default tickets-container-list"
            data-ps-id="ec4aef7e-3889-02a2-66f6-a3e0e73e95a8"
          >
            <!-- Message -->
            <a
              v-for="contact in listContacts"
              :key="contact.id + 1"
              href="javascript:void(0)"
              :ref="'contact_ticket_id:' + contact.ticket_id"
              :class="[
                {
                  waitingToStart: !getTicketData({
                    ticket_id: contact.ticket_id,
                    item: 'operator_id',
                  }),
                },
              ]"
              class="message-item d-flex align-items-center border-bottom px-3 py-2"
              @click.prevent="openTicketConversation(contact.ticket_id)"
            >
              <div class="user-img">
                <img
                  :src="contact.avatar_url"
                  alt="user"
                  class="img-fluid rounded-circle border-danger"
                  width="70px"
                />
                <span class="profile-status online float-right"></span>
              </div>
              <div
                class=" w-100 d-flex  ml-3 align-items-end justify-content-between"
              >
                <div>
                  <h5 class="message-title mb-0 mt-1">{{ contact.name }}</h5>
                  <span
                    class="font-12 text-nowrap d-block text-muted text-truncate"
                    >Última mensagem do Paciente!</span
                  >
                </div>
                <div class="d-flex flex-column align-items-end">
                  <strong
                    v-if="wasSeen(contact.ticket_id)"
                    class="text-success blink-2"
                    ><i class="fas fa-circle fa-xs"></i
                  ></strong>
                  <strong class="font-12 text-nowrap d-block text-muted"
                    >9:30 AM</strong
                  >
                </div>
              </div>
            </a>
          </div>
        </li>
      </ul>
    </div>
    <!-- :class="[{ 'blink-2':  }]" -->
    <portal to="modals">
      <!-- <BulkMessageModal :display="modalState" /> -->
    </portal>
  </div>
</template>

<script>
  // import { bus } from "../../main";
  //   import BulkMessageModal from "@/components/chat/BulkMessageModal";
  import { mapGetters, mapActions } from "vuex";
  import io from "socket.io-client";
  export default {
    name: "ContactList",
    components: {
      //   BulkMessageModal,
    },
    data() {
      return {
        search: false,
        modalState: false,
        searchFor: null,
        ticketStatus: null,
        socket: null,
        UPDATESOCKET: 0,
      };
    },
    computed: {
      ...mapGetters(["contatos", "tickets", "messages", "loggedUser"]),
      // listOpenTickets() {
      //   let contatos = this.contatos.filter((contact) => {
      //     return this.tickets.some((ticket) => {
      //       if (
      //         ticket.patient_id === contact.id &&
      //         this.openTicket(ticket) &&
      //         this.belongsToMe(ticket)
      //       ) {
      //         contact.ticket_id = ticket.id;
      //         return true;
      //       } else {
      //         return false;
      //       }
      //     });
      //   });
      //   if (this.search) {
      //     return contatos.filter((con) => {
      //       return con;
      //     });
      //   }
      //   return contatos;
      // },

      listContacts() {
        this.UPDATESOCKET;
        let contacts = this.contatos.filter((contact) =>
          this.tickets.some((openT) => {
            if (
              openT.patient_id === contact.id &&
              !openT.closed_at &&
              openT.status > 1
            ) {
              contact.ticket_id = openT.id;
              contact.operator_id = openT.operator_id;

              return true;
            } else {
              return false;
            }
          })
        );
        if (this.search) {
          contacts.filter((contact) => {
            return (
              contact.name.toLowerCase() === this.searchFor.toLowerCase() ||
              contact.number === this.searchFor ||
              contact.cpf === this.searchFor
            );
          });
        }
        return contacts.filter(
          (c) => c.operator_id === this.loggedUser.id || !c.operator_id
        );
      },
      getTicketData() {
        // let vm = this;
        return (obj) => {
          console.log("the obj", obj);

          let ticket = this.tickets.find((ticket) => {
            return ticket.id === obj.ticket_id;
          });

          console.log("getTicketData", ticket[obj.item]);
          return ticket[obj.item];
        };
      },
      getMessageData() {
        // return this.messages.find((msg) => {
        //   return msg.msg_from === from;
        // })[item];
        return (obj) => {
          console.log("the obj", obj);

          let message = this.messages.find((msg) => {
            return msg.msg_from === obj.from && obj.msg_from == obj.from;
          });

          console.log("the message", message);

          console.log("getMessageData", message ? message[obj.item] : "aaaa");
          return message ? message[obj.item] : true;
        };
      },
    },
    mounted() {
      this.$event.$on("openingTicket", (data) => {
        console.log("that in event patient_id", data.patient_id);
        this.openTicketConversation(data.id);
      });
      this.LOAD_USER_TICKETS();

      setTimeout(() => {
        this.socket = io(process.env.VUE_APP_SOCKET_SERVER);
        this.socket.on(
          this.loggedUser.clientName + "::seen-message",
          (data) => {
            //atualizar o ticket
            console.log("foi respondido", data);
            this.SOCKET__UPDATE_MESSAGES_STATUS(data).then(() => {
              this.wasSeen(data.data.ticket_id);
            });
          }
        );

        this.socket.on(
          this.loggedUser.clientName + "::updated-ticket",
          (data) => {
            if (data.data.status > 1) {
              //atualizar o ticket
              console.log("ticket atualizando", data);
              this.SOCKET_UPDATE_TICKET(data.data);
              let element = this.$refs["contact_ticket_id:" + data.data.id];
              if (data.data.operator_id) {
                if (
                  this.getTicketData({
                    ticket_id: data.data.id,
                    item: "operator_id",
                  }) !== this.loggedUser.id
                ) {
                  element[0].remove();
                  console.log("not yours");
                } else {
                  console.log("its yours");
                }
              }

              this.listContacts;
              this.UPDATESOCKET = this.UPDATESOCKET + 1;
              this.$forceUpdate();
            }
          }
        );
        this.socket.on(this.loggedUser.clientName + "::new-ticket", (data) => {
          //atualizar o ticket
          console.log("ticket adicioinado", data);
          if (data.data.status > 1) {
            console.log("ticket adicioinado", data);
            this.SOCKET_NEW_TICKET(data.data);
          }
        });
      }, 1000);
    },
    methods: {
      ...mapActions([
        "OPEN_MESSAGES",
        "SELECT_TICKET",
        "LOAD_USER_TICKETS",
        "SOCKET__UPDATE_MESSAGES_STATUS",
        "UPDATE_TICKET",
        "NEW_TICKET",
        "SOCKET_NEW_TICKET",
        "SOCKET_UPDATE_TICKET",
      ]),

      wasSeen(id) {
        let unSeenMsg = this.messages.filter((msg) => {
          return msg.ticket_id === id && msg.status == null;
        });
        console.log("msgs unSeenMsg", unSeenMsg);
        return unSeenMsg.length;
      },

      toggleSearch() {
        console.log("fuck works");
        this.search = !this.search;
      },
      toggleModal() {
        // this.$event.$emit("bulkMessage", true);
      },
      openTicketConversation(ticket_id) {
        console.log("open this id", ticket_id);
        this.SELECT_TICKET(ticket_id);
        this.OPEN_MESSAGES(ticket_id);
        this.$event.$emit("start_loading_msgs_in_chat");
      },
      belongsToMe(ticket) {
        return ticket.operator_id == this.loggedUser.id;
      },
      openTicket(ticket) {
        return ticket.closed_at == null;
      },
    },
  };
</script>

<style lang="scss">
  .tickets-container-list {
    height: 75vh;
    overflow-x: unset;
    overflow-y: scroll;
    &::-webkit-scrollbar {
      width: 8px; /* width of the entire scrollbar */
    }
    &::-webkit-scrollbar-thumb {
      background-color: rgb(204, 204, 204); /* color of the scroll thumb */
      border-radius: 20px; /* roundness of the scroll thumb */
      /* // border: 1px solid rgb(235, 235, 235); creates padding around scroll thumb */
    }
  }

  .waitingToStart {
    padding: 10px;
    display: inline-block;
    border-radius: 5px;
    animation: blinkingBackground 2s infinite;
  }
  @keyframes blinkingBackground {
    0% {
      background-color: #ffd31071;
    }
    /* 25% {
      background-color: #fff;
    } */
    50% {
      background-color: #fff;
    }
    /* 75% {
      background-color: #fff;
    }  */
    100% {
      background-color: #ffd31071;
    }
  }
</style>
