import Vue from "vue";
import Vuex from "vuex";
import axios from "../../http";
Vue.use(Vuex);

export default {
  state: {
    action: {
      // label: "",
      // bot_id: 0,
      // endpoint: null,
      // endpoint_request: null,
      // start_on_action_id: null,
      // value: {
      //   message: "",
      //   options: [],
      // },
    },
  },
  getters: {
    action: (state) => state.action,
  },
  mutations: {
    LOAD_ACTION(state, action) {
      state.action = action;
    },
    ADD_OPTION(state, option) {
      state.action.value.options.push(option);
    },
    REMOVE_OPTION(state, option) {
      var index = state.action.value.options.findIndex((opt) => {
        return (
          opt.label === option.label &&
          parseInt(opt.option) === parseInt(opt.option)
        );
      });
      state.action.value.options.splice(index, 1);
    },
  },

  actions: {
    ADD_OPTION: (context, payload) => {
      context.commit("ADD_OPTION", payload);
      //carregar as conversas
    },
    LOAD_APPOINTMENT: async (context) => {
      try {
        const response = await axios().get("appointment_index");
        context.commit("LOAD_ACTION", response.data);
        return response.data;
      } catch (error) {
        console.log(error);
      }
    },
    REMOVE_OPTION: (context, option) => {
      context.commit("REMOVE_OPTION", option);
      //carregar as conversas
    },
    SAVE_INTERACTION: async (context, payload) => {
      try {
        const response = await axios().post("bot_interaction", payload);
        console.log("SAVE_INTERACTION :: response.data::", response.data);
        context.commit("LOAD_ACTION", response.data);
        return response.data;
      } catch (error) {
        console.log(error);
      }
    },
    UPDATE_INTERACTION: async (context, payload) => {
      try {
        const response = await axios().put(
          "bot_interaction/" + payload.bot_id,
          payload
        );
        console.log("UPDATE_INTERACTION :: response.data::", response.data);
        context.commit("LOAD_ACTION", response.data);
        return response.data;
      } catch (error) {
        console.log(error);
      }
    },
  },
};

// {"message": "Olá {nome} Você tem o exame {exame} agendado para {data} local: {endereco} horario: {horario}.\nAcesse www.exame.com.br para visualizar o preparo do seu exame", "options": "1 - Não vou,2 - Eu vou"}
